import { Dispatch, SetStateAction, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Avatar, Button, DatePicker, Dropdown, Flex, List, MenuProps, Select, Space, Spin, Steps, Tooltip } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { MenuOutlined, SettingOutlined } from "@ant-design/icons";
import webSocketService from "../../../utils/websocketService";
import fireActiveIcon from "../../../assets/fireactive.svg";
import { FaCheck } from "react-icons/fa6";
import Icon from "../CommonComponent/Icon";
import { formateDate, getInitials, statusClassName } from "../../../utils/commonFunctions";
import { INQUIRY_TYPE, INQUIRY_TYPE_STATUS, TASK_STATUS } from "../../../utils/const";
import { UserInvite } from "../../../components/UserInviteModel";
import { AppDispatch, RootState } from "../../../store";
import { acceptTask, changeTaskStatus, ITask, ITaskList, IUser, taskDeleteRequest } from "../../../slices/taskSlice";
import { openItem } from "../../../slices/minimizeSlice";
import Taskcss from "./../style.module.scss";

interface TaskPropsInterface {
  taskList: {
    Today: ITaskList[];
    Upcoming: ITaskList[];
  };
  setTaskList: Dispatch<SetStateAction<any>>;
  pendingTasks: ITask[];
}

const colors = ["#76a3da"];

const Task = ({ setTaskList, taskList, pendingTasks }: TaskPropsInterface) => {
  const { user } = useSelector((state: RootState) => state.user);
  const { assignList } = useSelector((state: RootState) => state.commonData);
  const dispatch = useDispatch<AppDispatch>();

  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);
  const [loader, setLoader] = useState(false);

  const parseDate = (dateStr: string) => {
    const date: Dayjs = dayjs(dateStr, "DD-MM-YYYY HH:mm");
    return new Date(date.toDate());
  };

  const onDragEnd = (result: any) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    type TaskListKey = "Today" | "Upcoming";
    const sourceColumn = taskList[source.droppableId as TaskListKey];
    const destColumn = taskList[destination.droppableId as TaskListKey];
    const [removed] = sourceColumn.splice(source.index, 1);
    destColumn.splice(destination.index, 0, removed);

    if (source.droppableId !== destination.droppableId) {
      const taskData = destColumn.find((tsk: ITaskList) => tsk.task_id === draggableId);

      if (taskData) {
        if (destination.droppableId === "Today") {
          taskData.task.due_date = moment()
            .set({
              hour: moment(taskData.task.due_date, "DD-MM-YYYY HH:mm").hour(),
              minute: moment(taskData.task.due_date, "DD-MM-YYYY HH:mm").minute(),
            })
            .format("DD-MM-YYYY HH:mm");
          destColumn.sort(
            (a: ITaskList, b: ITaskList) => parseDate(a.task.due_date).getTime() - parseDate(b.task.due_date).getTime()
          );
        } else if (destination.droppableId === "Upcoming") {
          taskData.task.due_date = moment(taskData.task.due_date, "DD-MM-YYYY HH:mm")
            .add(1, "day")
            .format("DD-MM-YYYY HH:mm");
        }
      } else {
        console.warn(`Task with ID ${draggableId} not found in destination column.`);
      }
    }

    setTaskList({
      ...taskList,
      [source.droppableId]: sourceColumn,
      [destination.droppableId]: destColumn,
    });

    const taskWithOrder = sourceColumn.map((dt: ITaskList, i: number) => {
      return { ...dt, task: { ...dt.task, order: i } };
    });
    if (destination.droppableId !== source.droppableId) {
      const destinationId: string = destination.droppableId === "Today" ? TASK_STATUS.inprogress : TASK_STATUS.pending;

      webSocketService.sendMessage("taskPositionChange", {
        tntId: user?.tenant_id,
        taskId: draggableId,
        destination: destinationId,
        userId: user?._id,
      });
    }
    webSocketService.sendMessage("changeTaskOrder", { tntId: user?.tenant_id, taskList: taskWithOrder });
  };

  const items: MenuProps["items"] = [
    {
      key: TASK_STATUS.completedbyUser,
      label: "Move to Watchlist",
    },
    {
      key: TASK_STATUS.complete,
      label: "Move to Completed",
    },
    {
      key: "DELETE",
      label: <span style={{ color: "#ff0000b0" }}>Delete Request</span>,
      children: [
        { key: "Prise not Matched", label: "Prise not Matched" },
        { key: "Delay Reply", label: "Delay Reply" },
        { key: "Out of Scope", label: "Out of Scope" },
        { key: "Plan Canceled", label: "Plan Canceled" },
        { key: "Miss Behaviour", label: "Miss Behaviour" },
      ],
    },
  ];

  const handleTaskMove = (status: any, id: string) => {
    if (status.keyPath.includes("DELETE")) {
      dispatch(
        taskDeleteRequest({
          reason: status.key,
          taskId: id,
        })
      );
    } else {
      dispatch(changeTaskStatus({ status: status.key, taskId: id }));
    }
  };

  const handleTaskClick = (item: ITaskList) => {
    dispatch(
      openItem({
        name: item.task.title,
        _id: item.task._id,
        type: "TASK",
      })
    );
    if (!item.read_status) {
      webSocketService.sendMessage("taskRead", {
        tntId: user?.tenant_id,
        taskId: item.task._id,
        userId: user?._id,
      });
    }
  };

  const handleTaskSelect = (taskId: string) => {
    setSelectedTaskId(taskId === selectedTaskId ? null : taskId);
  };

  const handleDueDateChange = (date: any, id: string) => {
    if (date) {
      webSocketService.sendMessage("taskDueDateChange", {
        tntId: user?.tenant_id,
        taskId: id,
        userId: user?._id,
        date: date ? date.format("DD-MM-YYYY HH:mm") : null,
      });
    }
  };

  const handleChange = (val: string, id: string) => {
    webSocketService.sendMessage("taskStatusUpdate", {
      tntId: user?.tenant_id,
      taskId: id,
      userId: user?._id,
      status: val,
    });
  };

  const acceptTaskHandle = async (id: string) => {
    setLoader(true);
    await dispatch(acceptTask(id));
    setLoader(false);
  };

  const updateAssigneeList = (val: string[], id: string) => {
    webSocketService.sendMessage("taskAssigneeUpdate", {
      tntId: user?.tenant_id,
      taskId: id,
      assignee: val,
      userId: user?._id,
    });
  };

  return (
    <>
      <Spin spinning={loader}>
        {pendingTasks?.length > 0 && (
          <div className={Taskcss.taskCollaps}>
            <div className="task-header">
              <span className="length-count">{String(pendingTasks?.length)?.padStart(2, "0")}</span>
              <span>Pending request</span>
            </div>
            <List size="small" bordered>
              <List.Item>
                <div
                  className="task-grid-wrapper header"
                  style={{ gridTemplateColumns: "1fr minmax(160px, 160px) minmax(169px, 160px)" }}
                >
                  <div>
                    <span style={{ marginLeft: "41px" }}>NAME</span>
                  </div>
                  <div>
                    <span>DUE DATE</span>
                  </div>
                  <div>
                    <span></span>
                  </div>
                </div>
              </List.Item>
              {pendingTasks?.map((dt) => (
                <List.Item key={dt._id}>
                  <div
                    className="task-grid-wrapper"
                    style={{ gridTemplateColumns: "1fr minmax(160px, 160px) minmax(169px, 160px)" }}
                  >
                    <Space size={16} align="center">
                      {dt.task_type.length > 1 ? <Icon name={"MULTI"} /> : <Icon name={dt.task_type[0]} />}
                      <span className="list-item-title">
                        {dt?.title} {dt?.is_high_prior && <img src={fireActiveIcon} alt="fireactive" />}
                      </span>
                    </Space>
                    <div>
                      <span>{formateDate(dt.due_date)}</span>
                    </div>
                    <div>
                      <Button onClick={() => acceptTaskHandle(dt._id)} type="primary" block className="accept-btn">
                        Accept
                      </Button>
                    </div>
                  </div>
                </List.Item>
              ))}
            </List>
          </div>
        )}

        <DragDropContext onDragEnd={onDragEnd}>
          {taskList &&
            Object.entries(taskList).map(([colId, colTasks]) => (
              <Droppable droppableId={colId} key={colId}>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} className={Taskcss.taskCollaps}>
                    <div className="task-header">
                      <span className="length-count">{String(colTasks?.length)?.padStart(2, "0")}</span>
                      <span>{colId}</span>
                    </div>
                    <List size="small" bordered>
                      <List.Item>
                        <div
                          className="task-grid-wrapper header"
                          style={{ gridTemplateColumns: "1fr  minmax(160px, 160px) minmax(169px, 160px)" }}
                        >
                          <Space>
                            <SettingOutlined
                              style={{ lineHeight: "1", marginLeft: "35px", color: "#6f7994a3", marginRight: "1px" }}
                            />
                            <span>NAME</span>
                          </Space>
                          <div>
                            <span>DUE DATE</span>
                          </div>
                          <div className="assigneebg">
                            <span>ASSIGNEE</span>
                          </div>
                        </div>
                      </List.Item>
                      {colTasks &&
                        colTasks?.map((item, index) => {
                          return (
                            <Draggable
                              key={item.task._id}
                              draggableId={item.task._id}
                              index={index}
                              isDragDisabled={dayjs(item?.task.due_date, "DD-MM-YYYY HH:mm").isBefore()}
                            >
                              {(prov) => (
                                <List.Item ref={prov.innerRef} {...prov.draggableProps} {...prov.dragHandleProps}>
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleTaskSelect(item.task._id);
                                    }}
                                    className="task-grid-wrapper"
                                    style={{
                                      gridTemplateColumns: "1fr minmax(160px, 160px) minmax(169px, 160px)",
                                      backgroundColor: selectedTaskId === item.task._id ? "#f4fcde" : "",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Flex gap={10} align="center" justify="space-between">
                                      <Space>
                                        {item.task.task_type?.length > 1 ? (
                                          <Tooltip title="Multiple Inquiries">
                                            <Icon name="MULTI" style={{ cursor: "pointer" }} />
                                          </Tooltip>
                                        ) : (
                                          <Tooltip title={INQUIRY_TYPE[item?.task?.task_type[0]]?.title}>
                                            <Icon name={item?.task?.task_type?.[0]} />
                                          </Tooltip>
                                        )}
                                        <div
                                          onClick={(e) => {
                                            e.stopPropagation();
                                          }}
                                        >
                                          <Dropdown
                                            menu={{ items, onClick: (val) => handleTaskMove(val, item.task._id) }}
                                            trigger={["click"]}
                                            overlayClassName="menuDropdown"
                                          >
                                            <Button type="link" style={{ padding: "0px", height: "auto" }}>
                                              <MenuOutlined style={{ lineHeight: "1" }} />
                                            </Button>
                                          </Dropdown>
                                        </div>
                                        <span
                                          className={`list-item-title name ${!item.read_status ? "unread" : ""}`}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleTaskClick(item);
                                          }}
                                        >
                                          {item?.task?.title}{" "}
                                          {item?.task?.is_high_prior && <img src={fireActiveIcon} alt="fireactive" />}
                                        </span>
                                      </Space>
                                      {item?.task?.task_type.includes("PERSONAL") ? (
                                        <div
                                          className="status"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                          }}
                                        >
                                          <Select
                                            size="small"
                                            onChange={(e) => handleChange(e, item?.task._id)}
                                            value={item?.task?.task_status}
                                            options={Object.keys(INQUIRY_TYPE_STATUS)?.map((key) => ({
                                              value: key,
                                              label: INQUIRY_TYPE_STATUS[key]?.title?.toUpperCase(),
                                            }))}
                                            variant="borderless"
                                            popupClassName="statusSelect"
                                            suffixIcon={null}
                                            className={` ${statusClassName(item.task.task_status)}`}
                                          />
                                        </div>
                                      ) : (
                                        <div>
                                          <Steps
                                            className="stepper"
                                            current={item?.master?.step - 1 || 0}
                                            labelPlacement="vertical"
                                            items={[
                                              {
                                                icon:
                                                  item?.master?.step >= 1 ? <FaCheck fill="white" size={10} /> : null,
                                              },
                                              {
                                                icon:
                                                  item?.master?.step >= 2 ? <FaCheck fill="white" size={10} /> : null,
                                              },
                                              {
                                                icon:
                                                  item?.master?.step >= 3 ? <FaCheck fill="white" size={10} /> : null,
                                              },
                                              {
                                                icon:
                                                  item?.master?.step === 4 ? (
                                                    <FaCheck fill="white" size={10} />
                                                  ) : undefined,
                                              },
                                            ]}
                                          />
                                        </div>
                                      )}
                                    </Flex>

                                    <div
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <Flex align="center">
                                        <label htmlFor={item.task._id}>
                                          {dayjs(item?.task.due_date, "DD-MM-YYYY HH:mm").isBefore(
                                            dayjs(),
                                            "second"
                                          ) ? (
                                            <Icon name="OVERDUECALENDER" />
                                          ) : dayjs(item?.task.due_date, "DD-MM-YYYY HH:mm").isSame(dayjs(), "day") ? (
                                            <Icon name="TODAYCALENDER" />
                                          ) : (
                                            <Icon name="CALENDER" />
                                          )}
                                        </label>
                                        <DatePicker
                                          id={item.task._id}
                                          onChange={(val) => handleDueDateChange(val, item.task._id)}
                                          showTime={{ format: "HH:mm" }}
                                          className={`${Taskcss.taskDatePicker} ${
                                            dayjs(item?.task.due_date, "DD-MM-YYYY HH:mm").isBefore(dayjs(), "second")
                                              ? Taskcss.overDue
                                              : dayjs(item?.task.due_date, "DD-MM-YYYY HH:mm").isSame(dayjs(), "day")
                                              ? Taskcss.toDay
                                              : ""
                                          }`}
                                          value={dayjs(item?.task.due_date, "DD-MM-YYYY HH:mm")}
                                          variant="borderless"
                                          format={"MMM D, YYYY"}
                                          suffixIcon={null}
                                        />
                                      </Flex>
                                    </div>
                                    <div
                                      className="assigneebg"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <Flex
                                        align="center"
                                        justify="space-between"
                                        className="assignee-cell"
                                        style={{ width: "100%" }}
                                      >
                                        <Avatar.Group
                                          max={{
                                            count: 3,
                                            style: { color: "#ffffffd6", backgroundColor: "#76a3da" },
                                            popover: { rootClassName: "AsssignnePopover" },
                                          }}
                                          size={28}
                                        >
                                          {item.task.assign_to.map((asn: IUser, idx: number) => {
                                            return (
                                              <Tooltip title={asn.full_name} key={idx}>
                                                <Avatar
                                                  style={{
                                                    backgroundColor: colors[idx % colors.length],
                                                    marginLeft: "-6px",
                                                  }}
                                                >
                                                  {getInitials(asn.full_name)}
                                                </Avatar>
                                              </Tooltip>
                                            );
                                          })}
                                        </Avatar.Group>
                                        <UserInvite
                                          onSubmit={(val: string[]) => updateAssigneeList(val, item.task._id)}
                                          assignList={assignList}
                                          assigned={item.task.assign_to}
                                        />
                                      </Flex>
                                    </div>
                                  </div>
                                </List.Item>
                              )}
                            </Draggable>
                          );
                        })}
                      {provided.placeholder}
                    </List>
                  </div>
                )}
              </Droppable>
            ))}
        </DragDropContext>
      </Spin>
    </>
  );
};

export default Task;
