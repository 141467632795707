import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  message,
  Modal,
  Row,
  Space,
  Tabs,
  TabsProps,
  Typography,
} from "antd";
import { AppDispatch, RootState } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import { setVendorDrawerOpen } from "../../slices/layoutSlice";
import { DRAWER_TYPE } from "../../utils/const";
import DrawerCustom from "../../components/Drawer";
import VendorForm from "./VendorForm";
import VendorStyleCss from "./style.module.scss";
import FormStyleCss from "./VendorForm/form.module.scss";
import {
  addVendor,
  VendorDataInterface,
  fetchVendorList,
  getVendorDetails,
  resetAllFormState,
  resetContactPerson,
  updateVendorList,
  setContactPerson,
  DocumentInterface,
  setInitDocHistory,
} from "../../slices/vendorSlice";
import _ from "lodash";
import { getDocumentURL } from "../../utils/AWSService";
import { addItemInList, closeItem } from "../../slices/minimizeSlice";
import { FaAngleRight } from "react-icons/fa6";
import { InputBox } from "../../components/FormInput";
import styleCss from "./style.module.scss";

const { Text } = Typography;

interface ICreateContact {
  vendorId?: string;
}

interface User {
  name: string;
  contactNumber: string;
}

interface OtherDocumentItem {
  id: string;
  fileName: string;
  uploadDate: string;
  name: string;
  docType: string;
  file?: any;
}

function CreateVendor({ vendorId }: ICreateContact) {
  const dispatch = useDispatch<AppDispatch>();
  const { vendorDrawerOpen } = useSelector((state: RootState) => state.layout);
  const { otherDocHistory, contactPerson, vendorData, loading } = useSelector((state: RootState) => state.vendor);
  const { user } = useSelector((state: RootState) => state.user);
  const { openItemList } = useSelector((state: RootState) => state.minimize);
  const currentOpen = openItemList[openItemList.length - 1];
  const [existingList, setExistingList] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (_.isEqual(vendorId, _.get(currentOpen, "_id")) && vendorId) {
      dispatch(getVendorDetails(vendorId));
    } else {
      form.resetFields();
    }
  }, [vendorId, currentOpen?._id]);

  useEffect(() => {
    if (_.isEqual(vendorDrawerOpen, DRAWER_TYPE.VENDOR_DRAWER) || vendorId) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [vendorDrawerOpen, vendorId]);

  useEffect(() => {
    if (vendorData) {
      setFieldValues(vendorData);
    }
  }, [vendorData]);

  const drawerClose = () => {
    setIsModalOpen(false);
    dispatch(resetContactPerson());
    if (vendorId) {
      setTimeout(() => {
        dispatch(closeItem(vendorId));
        setIsSaved(false);
        dispatch(resetAllFormState());
      }, 300);
    } else {
      dispatch(resetAllFormState());
      form.resetFields();
      setExistingList([]);
      dispatch(setVendorDrawerOpen(null));
    }
  };

  const dataSaved = () => {
    setIsSaved(true);
    setTimeout(() => setIsSaved(false), 2000);
  };

  const handleMinimize = () => {
    if (vendorId) {
      dispatch(
        addItemInList({
          name: form.getFieldValue("contact_name"),
          _id: vendorId,
          type: "VENDOR",
        })
      );
      drawerClose();
    }
  };

  const onTabChange = (val: string | number) => {
    console.log(val);
  };

  const handleFinish = async (val: any) => {
    console.log(val);

    const formData = new FormData();

    const appendFile = (formData: FormData, fileKey: string, file: any) => {
      if (file && file.originFileObj) {
        formData.append(fileKey, file.originFileObj);
      }
    };

    const vendorData: any = {
      account_name: val?.account_name || "",
      gst_treatment: val?.gst_treatment || "",
      gst_number: val?.gst_number || "",
      pan_number: val?.pan_number || "",
      source_of_supply: val?.source_of_supply || "",
      tds: val?.tds || "",
      address: {
        office_address: val?.office_address || "",
        city: val?.city || "",
        // state: val?.state || "",
        // country: val?.country || "",
        pincode: val?.pincode || "",
      },
      opening_balance: {
        currency: val?.currency || "",
        balance: val?.balance || "",
        balance_Type: val?.balance_type || "",
      },
      payment_terms: val?.payment_terms || "",
      bank_details: {
        acc_name: val?.acc_name || "",
        acc_number: val?.acc_number || "",
        ifce: val?.ifsc || "",
      },
      contact_members: contactPerson?.map((contact: any) => ({
        id: contact?.id,
        full_name: contact?.name,
        contact_number: contact?.mobile,
        email: contact?.email,
      })),
    };

    // if (val?.gst_number) vendorData.gst_number = val.gst_number;

    if (_.isEmpty(vendorData.address)) delete vendorData.address;
    if (_.isEmpty(vendorData.documents)) delete vendorData.documents;

    if (otherDocHistory?.length > 0) {
      otherDocHistory.forEach((data: OtherDocumentItem) => {
        if (data.file) {
          const fileName = data?.name || data.file.name.split(".").pop();
          const fileKey = `doc_${fileName.replace(/\s+/g, "_").toLowerCase()}`;
          appendFile(formData, fileKey, data.file);
        }
      });
    }

    if (Object.keys(vendorData).length > 0) {
      formData.append("vendorData", JSON.stringify(vendorData));
    }

    if (vendorId && vendorData) {
      const response: any = await dispatch(updateVendorList({ id: vendorId, updatedData: formData }));
      if (response) {
        dataSaved();
        dispatch(fetchVendorList());
      }
    } else {
      const response: any = await dispatch(addVendor(formData));
      if (!response.payload) {
        drawerClose();
        dispatch(fetchVendorList());
      } else {
        message.error(response.payload?.error?.message);
        setExistingList(response.payload?.error?.data);
      }
    }
  };

  const handleFinishFailed = (errorInfo: any) => {
    if (errorInfo && errorInfo?.errorFields?.length > 0) {
      let activeKey = errorInfo?.errorFields[0]?.name[0];
      message.error("Please enter data in all required fields.");
    }
  };

  const setFieldValues = (vendor: any) => {
    const { _id, address, documents, opening_balance, bank_details } = vendor;

    if (_id) {
      form.setFieldValue("account_name", _.get(vendor, "account_name", ""));
      form.setFieldValue("gst_number", _.get(vendor, "gst_number", ""));
      form.setFieldValue("gst_treatment", _.get(vendor, "gst_treatment", ""));
      form.setFieldValue("pan_number", _.get(vendor, "pan_number", ""));
      form.setFieldValue("source_of_supply", _.get(vendor, "source_of_supply", ""));
      form.setFieldValue("tds", _.get(vendor, "tds", ""));
      form.setFieldValue("payment_terms", _.get(vendor, "payment_terms", ""));

      if (address) {
        form.setFieldValue("office_address", _.get(address, "office_address", ""));
        form.setFieldValue("city", _.get(address, "city")?.trim() || null);
        form.setFieldValue("pincode", _.get(address, "pincode", ""));
        form.setFieldValue("state", _.get(address, "state", "")?.trim() || null);
        form.setFieldValue("country", _.get(address, "country", ""));
      }
      if (opening_balance) {
        form.setFieldValue("currency", _.get(opening_balance, "currency", ""));
        form.setFieldValue("balance", _.get(opening_balance, "balance") || null);
        form.setFieldValue("balance_type", _.get(opening_balance, "balance_type", ""));
      }
      if (bank_details) {
        form.setFieldValue("acc_name", _.get(address, "acc_name", ""));
        form.setFieldValue("acc_number", _.get(address, "acc_number")?.trim() || null);
        form.setFieldValue("ifce", _.get(address, "ifce", ""));
      }

      if (vendorData) {
        const memberList: any = vendor?.contact_members?.map((mbr: any) => ({
          name: mbr?.full_name || "",
          id: mbr?._id || "",
          email: mbr?.email || "",
          mobile: mbr?.contact_number || "",
        }));
        dispatch(setContactPerson(memberList));
      }
      if (documents && documents?.length > 0) {
        let docList: any = _.map(documents, (doc: DocumentInterface) => ({
          id: doc?._id,
          name: doc?.doc_name || "",
          fileName: doc?.file_name,
          uploadDate: doc?.upload_date,
          url: getDocumentURL(`vendor_doc/${user?.tenant_id}/${_id}/${doc?.file_name}`),
          docType: doc?.doc_type,
        }));
        dispatch(setInitDocHistory({ data: docList }));
      }
    }
  };

  const [GSTModalOpen, setGSTModalOpen] = useState(false);
  const handleOk = () => {
    setGSTModalOpen(false);
  };

  const handleCancel = () => {
    setGSTModalOpen(false);
  };

  return (
    <>
      <DrawerCustom
        handleMinimize={handleMinimize}
        drawerCloseHandle={drawerClose}
        open={isModalOpen}
        width={"40%"}
        closeText="VENDOR"
        showSavedStatus={vendorId ? true : false}
        isLoading={loading}
        isDataUpdated={isSaved}
        showMinimize={vendorId ? true : false}
        zIndex={1000}
      >
        <div style={{ padding: "23px 0px" }}>
          <div>
            <Text className="drawer-title">{vendorId ? "Vendor Detail" : "New Vendor"}</Text>
          </div>
          <Space align="center" style={{ cursor: "pointer" }} size={0}>
            <Typography style={{ color: "#30bfee" }} onClick={() => setGSTModalOpen(true)}>
              Fetch Vendor Details From GSTIN
            </Typography>
            <FaAngleRight color="#30bfee" />
          </Space>
        </div>
        <Modal open={GSTModalOpen} closeIcon={false} width={700} footer={null} wrapClassName="gstModel">
          <Flex>
            <Typography style={{ color: "#30bfee" }} onClick={() => setGSTModalOpen(true)}>
              Fetch From GSTN
            </Typography>
          </Flex>
          <Row align={"middle"}>
            <Col span={5}>
              <Typography style={{ color: "#d6141d" }}>GSTIN/UIN*</Typography>
            </Col>
            <Col span={11}>
              <InputBox
                type="number"
                style={{ width: "100%" }}
                className="input-field"
                name={"gst_number"}
                placeholder="Enter GST number"
              />
            </Col>
            <Col span={8}></Col>
          </Row>
        </Modal>
        {existingList?.length > 0 && (
          <Alert
            message={`This User ${existingList?.map((usr: User) => ` ${usr.name}`)} Vendor Number Alreasdy Exist`}
            type="error"
            style={{ marginBottom: 12 }}
          />
        )}
        <Form
          form={form}
          name="dynamic_form"
          autoComplete="off"
          layout="vertical"
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
          scrollToFirstError
          // onValuesChange={(val: any) => console.log(val)}
        >
          <Card className={VendorStyleCss.vendorForm}>
            <VendorForm form={form} />
            <Divider
              style={{
                borderBlockStart: "1px solid rgba(219, 223, 233, 1)",
                margin: 0,
              }}
            />
            <div className="contact-form-footer p-5">
              <Flex gap={15} justify="end">
                <Button className={FormStyleCss.cancelButton} onClick={drawerClose}>
                  Cancel
                </Button>
                <Button className={FormStyleCss.formButton} htmlType="submit" loading={loading}>
                  Save
                </Button>
              </Flex>
            </div>
          </Card>
        </Form>
      </DrawerCustom>
    </>
  );
}

export default CreateVendor;
