import { useEffect, useState } from "react";
import { Button, Card, Drawer, Flex } from "antd";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { CheckOutlined, CloseOutlined, SyncOutlined } from "@ant-design/icons";
import webSocketService from "../../../utils/websocketService";
import AxiosService from "../../../utils/APIService";
import { addItemInList, closeItem } from "../../../slices/minimizeSlice";
import { ITask, ITaskDetails } from "../../../slices/taskSlice";
import Stepper from "../../../components/FormInput/Stepper";
import EditDrawerHeader from "../CommonComponent/EditDrawerHeader";
import CommonStep from "./CommonStep";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import FourthStep from "./FourthStep";
import TaskStyleCss from "../createDrawer.module.scss";

dayjs.extend(duration);
interface TaskDetailsInterface {
  id: string;
  layer?: number;
}

const minimizeArrow = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fill="#525C69"
      d="M7.383 4.32a.686.686 0 0 0-.971 0l-.768.767a.686.686 0 0 0 0 .97l6.633 6.634H7.21a.686.686 0 0 0-.686.687v.882c0 .38.307.687.686.687h8.43a.689.689 0 0 0 .686-.687V5.831a.687.687 0 0 0-.686-.687h-1.132a.686.686 0 0 0-.687.687v4.926L7.383 4.319ZM5.987 17.715a.687.687 0 0 0-.687.687v.883c0 .379.308.686.687.686h12.327c.379 0 .686-.307.686-.686v-.883a.687.687 0 0 0-.686-.687H5.987Z"
    />
  </svg>
);

function TaskDetails({ id, layer }: TaskDetailsInterface) {
  const { user } = useSelector((state: RootState) => state.user);
  const { secondeStepPassengerList } = useSelector((state: RootState) => state.task);
  const dispatch = useDispatch<AppDispatch>();

  const apiService = new AxiosService({
    baseURL: process.env.REACT_APP_TASK_SERVICE,
  });
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [previousSelectedOptions, setPreviousSelectedOptions] = useState<string[]>([]);
  const [taskData, setTaskData] = useState<ITask | null>(null);
  const [syncing, setSyncing] = useState(false);
  const [saved, setSaved] = useState(false);
  const [modelShow, setModelShow] = useState(true);
  const { openItemList } = useSelector((state: RootState) => state.minimize);
  const currentOpen = openItemList[openItemList.length - 1];
  const [currentStep, setCurrentStep] = useState<number>(taskData?.master?.step || 0);

  useEffect(() => {
    if (id) {
      fetchTaskData(id);
    }
    return () => {
      setTaskData(null);
      setPreviousSelectedOptions([]);
    };
  }, [id]);

  useEffect(() => {
    const handleTaskUpdate = (val: ITaskDetails) => {
      if (val.tenantId === user?.tenant_id && val?.task?._id === id) {
        setTaskData((prev) => {
          return { ...prev, ...val.task };
        });
        setSyncing(true);
        setSaved(false);
        setTimeout(() => {
          setSyncing(false);
          setSaved(true);
          setTimeout(() => setSaved(false), 2000);
        }, 1000);
      }
    };
    webSocketService.onMessage("taskUpdate", handleTaskUpdate);
  }, [user, id]);

  useEffect(() => {
    if (taskData) {
      setSelectedTypes(taskData?.task_type?.map((type: string) => type));
    }
  }, [taskData]);

  const handleStepChange = (step: number) => {
    setCurrentStep(step);
  };

  const onNext = () => {
    setCurrentStep(currentStep + 1);

    webSocketService.sendMessage("taskStepUpdate", {
      tntId: user?.tenant_id,
      taskId: taskData?._id,
      step: currentStep + 1,
    });
    if (taskData?.master?.step !== undefined && taskData.master.step <= currentStep) {
      setTaskData((prevTaskData: ITask | null) => ({
        ...prevTaskData!,
        master: {
          ...prevTaskData!.master,
          step: currentStep + 1,
        },
      }));
    }
  };

  const fetchTaskData = (taskId: string) => {
    apiService
      .get(`/get/${taskId}`, {}, false)
      .then((res: any) => {
        const data = res?.data;
        setTaskData(data);
        setCurrentStep(data?.master?.step);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleTypeSubmit = () => {
    webSocketService.sendMessage("taskTypeChange", {
      tntId: user?.tenant_id,
      taskId: taskData?._id,
      type: selectedTypes,
      userId: user?._id,
    });
  };

  const drawerCloseHandle = () => {
    setModelShow(false);
    setTimeout(() => {
      dispatch(closeItem(id));
    }, 300);
  };

  const handleMinimize = () => {
    if (taskData) {
      dispatch(
        addItemInList({
          name: taskData.title,
          _id: taskData._id,
          type: "TASK",
        })
      );
      drawerCloseHandle();
    }
  };

  const renderDrawerWidth = () => {
    switch (layer) {
      case 0:
        return "calc(100% - 233px)";
      case 1:
        return "calc(97% - 233px)";
      case 2:
        return "calc(94% - 233px)";
      case 3:
        return "calc(91% - 233px)";
      default:
        return "calc(91% - 233px)";
    }
  };

  const colsebarPosition = () => {
    switch (layer) {
      case 0:
        return openItemList.length * 18;
      default:
        return (openItemList.length - (layer || 0)) * 18;
    }
  };

  const handleListClick = (key: string) => {
    if (key === "PERSONAL") {
      const isPersonalSelected = selectedTypes.includes("PERSONAL");

      setPreviousSelectedOptions(isPersonalSelected ? [] : selectedTypes);
      setSelectedTypes(isPersonalSelected ? previousSelectedOptions : ["PERSONAL"]);
    } else {
      const isPersonalSelected = selectedTypes.includes("PERSONAL");
      if (selectedTypes.length === 1 && selectedTypes.includes(key)) {
        return;
      }

      if (isPersonalSelected) {
        setSelectedTypes(previousSelectedOptions);
        setPreviousSelectedOptions([]);
      } else {
        setSelectedTypes((prevSelectedTypes) =>
          prevSelectedTypes.includes(key)
            ? prevSelectedTypes.filter((name) => name !== key)
            : [...prevSelectedTypes, key]
        );
      }
    }
  };

  const getStepperStatus = (index: number) => {
    const step = taskData?.master?.step;
    if (step === undefined) return "wait";

    if (index === step) {
      return "process";
    } else if (index > step) {
      return "wait";
    } else {
      return "finish";
    }
  };

  return (
    <>
      <Drawer
        onClose={drawerCloseHandle}
        open={modelShow}
        width={renderDrawerWidth()}
        className={TaskStyleCss.taskDrawer}
        classNames={{
          mask: currentOpen._id === id ? "show-mask" : "hide-mask",
        }}
        styles={{
          header: {
            display: "none",
          },
        }}
        style={{ boxShadow: "0px 0px 5px white" }}
        footer={[
          !taskData?.task_type?.includes("PERSONAL") && (
            <div className="CreateTaskFooter">
              <Flex gap={10}>
                {currentStep !== 1 && (
                  <Button className="addTaskbtn" onClick={() => setCurrentStep(currentStep - 1)}>
                    PREVIOUS
                  </Button>
                )}

                {currentStep !== 4 && (
                  <Button
                    className="createAnotherTaskbtn"
                    onClick={onNext}
                    disabled={!secondeStepPassengerList?.length && currentStep > 1}
                  >
                    NEXT
                  </Button>
                )}

                <Button className="cancalbtn" onClick={drawerCloseHandle}>
                  CANCEL
                </Button>
              </Flex>
            </div>
          ),
        ]}
      >
        <div className="close-bar" style={{ top: colsebarPosition() }}>
          <Flex onClick={drawerCloseHandle} className="close-label" align="center">
            {currentOpen._id === id && <CloseOutlined style={{ fontSize: "12px" }} />}
            <span style={{ lineHeight: "22px" }}>TASK</span>
          </Flex>
          {currentOpen._id === id && (
            <>
              <span className="minimize-label" onClick={handleMinimize}>
                {minimizeArrow}
              </span>
              <div className={`sync-label ${saved ? "saved" : ""}`}>
                {syncing ? (
                  <Flex align="center">
                    <SyncOutlined spin className="mr-2" style={{ color: "#525C69" }} />
                    Saving...
                  </Flex>
                ) : saved ? (
                  <Flex>
                    <CheckOutlined style={{ color: "white" }} className="mr-2" />
                    <span style={{ color: "white" }}>Saved</span>
                  </Flex>
                ) : (
                  <CheckOutlined style={{ color: "#525C69" }} />
                )}
              </div>
            </>
          )}
        </div>
        <EditDrawerHeader
          taskData={taskData}
          selectedTypes={selectedTypes}
          handleListClick={handleListClick}
          handleTypeSubmit={handleTypeSubmit}
        />
        <Card className="createTaskWrapper">
          {taskData?.task_type?.includes("PERSONAL") ? (
            <FirstStep taskData={taskData} />
          ) : (
            <>
              <div className="stepper">
                <Stepper
                  direction="horizontal"
                  step={currentStep}
                  finishedStep={taskData?.master?.step ?? 0}
                  setStep={handleStepChange}
                  items={[
                    { title: "CONVERSATION", status: getStepperStatus(1) },
                    { title: "DOCUMENTS", status: getStepperStatus(2) },
                    { title: "IN PROGRESS", status: getStepperStatus(3) },
                    { title: "INVOICES", status: getStepperStatus(4) },
                  ]}
                />
              </div>

              {currentStep === 1 && taskData && <FirstStep taskData={taskData} />}
              {currentStep === 2 && taskData && <SecondStep taskData={taskData} />}
              {currentStep === 3 && <ThirdStep />}
              {currentStep === 4 && <FourthStep />}
            </>
          )}
        </Card>
        <CommonStep id={id} setSaved={setSaved} setSyncing={setSyncing} taskData={taskData} setTaskData={setTaskData} />
      </Drawer>
    </>
  );
}

export default TaskDetails;
