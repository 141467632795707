import { Steps, Popover } from "antd";
import commonCss from "../../css/commonStyle.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface IStepItem {
    title: string;
    status: "wait" | "process" | "finish" | "error" | undefined;
}

interface IStepper {
    direction: "vertical" | "horizontal" | undefined;
    step: number;
    setStep: (step: number) => void;
    items?: IStepItem[];
    finishedStep: number;
}

const Stepper = ({ direction, step, setStep, items, finishedStep }: IStepper) => {
    const { secondeStepPassengerList } = useSelector((state: RootState) => state.task);

    const onChange = (value: number) => {
        if (value === 3 && !secondeStepPassengerList.length) {
            console.warn("Cannot proceed to step 2 because passenger list is empty.");
            return;
        }
        setStep(value + 1);
    };

    return (
        <Steps
            current={step - 1}
            onChange={onChange}
            labelPlacement="vertical"
            direction={direction}
            items={items?.map((item, index) => {
                const isDisabled = index > finishedStep - 1;
                const titleContent = isDisabled ? (
                    <Popover content="Please complete previous step to enable this option." trigger="hover">
                        <span style={{ cursor: "not-allowed" }}>{item.title}</span>
                    </Popover>
                ) : (
                    <span>{item.title}</span>
                );

                return {
                    title: titleContent,
                    disabled: isDisabled,
                    status: item.status,
                };
            })}
            className={commonCss.commonStepper}
        />
    );
};

export default Stepper;
