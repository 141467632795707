import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../utils/axiosInstance";
import MainBg from "../assets/main-bg.png";

export const createTheme = createAsyncThunk("theme/create", async (themeData: any, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(`${process.env.REACT_APP_USER_SERVICE}/create-theme`, themeData);
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const updateTheme = createAsyncThunk("theme/update", async (themeData: any, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.put(`${process.env.REACT_APP_USER_SERVICE}/update-theme`, themeData);
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const deleteTheme = createAsyncThunk("theme/delete", async (themeId: string, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.put(`${process.env.REACT_APP_USER_SERVICE}/delete-theme`, { themeId });
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message || "Failed to delete theme");
  }
});

export interface UserInterface {
  _id: string;
  full_name: string;
  email: string;
  contact_no: string;
  role: "ADMIN" | "AGENT_USER" | "AGENT_ADMIN";
  tenant_id: string;
}

export interface DepartmentInterface {
  name: string;
  _id: string;
}

export interface UserProfileInterface {
  departments: DepartmentInterface[];
}

export interface IMyUploadsTheme {
  fileName: string;
  removable: boolean;
  _id?: string;
}

export interface UserConfig {
  theme: { themeUrl: string; fileName?: string; id?: string };
  myUploads?: IMyUploadsTheme[];
}

export interface IUserState {
  isUserLogin: boolean;
  user?: UserInterface | null;
  profile?: UserProfileInterface | null;
  userConfig?: UserConfig | null;
}

const initialState: IUserState = {
  isUserLogin: false,
  user: null,
  profile: null,
  userConfig: {
    theme: {
      themeUrl: MainBg,
    },
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    isLogin(state, action: PayloadAction<boolean>) {
      state.isUserLogin = action.payload;
    },
    setUserData(state, action: PayloadAction<UserInterface | null>) {
      state.user = action.payload;
    },
    setProfileData(state, action: PayloadAction<UserProfileInterface | null>) {
      state.profile = action.payload;
    },
    setUserConfig(state, action: PayloadAction<UserConfig | null>) {
      state.userConfig = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createTheme.fulfilled, (state, action) => {
      const { currentTheme, uploadedTheme } = action.payload;
      if (!state.userConfig) {
        state.userConfig = { theme: currentTheme, myUploads: uploadedTheme };
      }
      state.userConfig.theme = currentTheme;
      state.userConfig.myUploads = uploadedTheme;
    });
    builder.addCase(updateTheme.fulfilled, (state, action) => {
      const { currentTheme, uploadedTheme } = action.payload;
      if (!state.userConfig) {
        state.userConfig = { theme: currentTheme, myUploads: uploadedTheme };
      }
      state.userConfig.theme = currentTheme;
      state.userConfig.myUploads = uploadedTheme;
    });
    builder.addCase(deleteTheme.fulfilled, (state, action) => {
      const { currentTheme, uploadedTheme } = action.payload;
      if (!state.userConfig) {
        state.userConfig = { theme: currentTheme || { themeUrl: MainBg }, myUploads: uploadedTheme };
      }
      state.userConfig.theme = currentTheme || { themeUrl: MainBg };
      state.userConfig.myUploads = uploadedTheme;
    });
  },
});

export const { isLogin, setUserData, setProfileData, setUserConfig } = userSlice.actions;

export default userSlice.reducer;
