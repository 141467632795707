import { useEffect, useState } from "react";
import { CaretDownOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  GetProp,
  Image,
  Row,
  Select,
  Space,
  Tabs,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { calculateDaysRemaining, getBase64, getInitials } from "../../../utils/commonFunctions";
import webSocketService from "../../../utils/websocketService";
import AxiosService from "../../../utils/APIService";
import { INQUIRY_TYPE_STATUS } from "../../../utils/const";
import { downloadDocument } from "../../../utils/AWSService";
import FileListItem from "../../../components/FileListItem";
import CheckList from "../CommonComponent/CheckList";
import { UserInvite } from "../../../components/UserInviteModel";
import CommentsBlock from "../CommonComponent/commentSection/CommentsBlock";
import HistoryBlock from "../CommonComponent/History";
import { ITask, ITaskDetails, IUser, subTaskDataListInterface } from "../../../slices/taskSlice";
import clipBlack from "../../../assets/clipBlack.svg";
import clipSvg from "../../../assets/clip.svg";
import TaskStyleCss from "../createDrawer.module.scss";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];
const colors = ["#76a3da"];

interface IFirstStepProps {
  id: string;
  setSaved: (value: boolean) => void;
  setSyncing: (value: boolean) => void;
  taskData: ITask | null;
  setTaskData: (data: any) => void;
}

const CommonStep = ({ id, setSaved, setSyncing, taskData, setTaskData }: IFirstStepProps) => {
  const [taskForm] = Form.useForm();
  const { user } = useSelector((state: RootState) => state.user);
  const { assignList } = useSelector((state: RootState) => state.commonData);
  const apiService = new AxiosService({
    baseURL: process.env.REACT_APP_TASK_SERVICE,
  });
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [subTaskData, setSubTaskData] = useState<subTaskDataListInterface[]>([]);
  const [showAll, setShowAll] = useState(false);
  const [showUpload, setShowUpload] = useState<string[]>(["CHECKLIST", "FILE"]);

  useEffect(() => {
    let docList = taskData?.documents?.map((dc: any, i: number) => ({
      uid: `${0 - i}`,
      name: dc.name,
      status: "done",
      url: dc.url,
    }));
    setFileList(docList);
    if (taskData && taskData?.subtask?.length > 0) {
      setSubTaskData(taskData.subtask);
    }
    taskForm.setFieldValue("title", taskData?.title);
    taskForm.setFieldValue("is_prior", taskData?.is_high_prior);
    taskForm.setFieldValue("notes", taskData?.note);
  }, [taskData]);

  useEffect(() => {
    const handleTaskUpdate = (val: ITaskDetails) => {
      if (val.tenantId === user?.tenant_id && val?.task?._id === id) {
        setTaskData((prev: any) => {
          return { ...prev, ...val.task };
        });
        setSyncing(true);
        setSaved(false);
        setSubTaskData(val.task.subtask);
        setTimeout(() => {
          setSyncing(false);
          setSaved(true);
          setTimeout(() => setSaved(false), 2000);
        }, 1000);
      }
    };
    webSocketService.onMessage("taskUpdate", handleTaskUpdate);
  }, [user, id]);

  const handleSubtaskChange = (val: subTaskDataListInterface, index: number) => {
    const taskList = [...subTaskData];
    taskList[index] = val;
    setSubTaskData(taskList);
    setTimeout(() => {
      webSocketService.sendMessage("taskSubtaskUpdate", {
        tntId: user?.tenant_id,
        taskId: taskData?._id,
        userId: user?._id,
        subTask: taskList,
      });
    }, 500);
  };

  const removeSubTaskList = (index: number) => {
    const updatedSubTaskData = subTaskData.filter((_, i) => i !== index);
    setSubTaskData(updatedSubTaskData);
    webSocketService.sendMessage("taskSubtaskDelete", {
      tntId: user?.tenant_id,
      taskId: taskData?._id,
      userId: user?._id,
      index: index,
    });
  };

  const handleDateChange = (val: any) => {
    if (val) {
      webSocketService.sendMessage("taskDueDateChange", {
        tntId: user?.tenant_id,
        taskId: taskData?._id,
        userId: user?._id,
        date: val ? val.format("DD-MM-YYYY HH:mm") : null,
      });
    }
  };

  const handleStatusChange = (val: string) => {
    webSocketService.sendMessage("taskStatusUpdate", {
      tntId: user?.tenant_id,
      taskId: id,
      userId: user?._id,
      status: val,
    });
  };

  const updateAssigneeList = (val: string[]) => {
    webSocketService.sendMessage("taskAssigneeUpdate", {
      tntId: user?.tenant_id,
      taskId: taskData?._id,
      assignee: val,
      userId: user?._id,
    });
  };

  const daysRemaining = taskData?.due_date ? calculateDaysRemaining(taskData.due_date) : null;

  const historyTabItem = [
    {
      key: "1",
      label: "Comments",
      children: <CommentsBlock taskData={taskData} />,
    },
    {
      key: "2",
      label: "History",
      children: <HistoryBlock id={id} />,
    },
  ];

  const handleShowMore = () => {
    setShowAll(!showAll);
  };
  const addSubTaskList = () => {
    setSubTaskData([
      ...subTaskData,
      {
        name: "SubTask List",
        task: [],
      },
    ]);
  };

  const fileBeforUploadHandle = async () => {
    return false;
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList, file }: any) => {
    if (!file.hasOwnProperty("status")) {
      setSyncing(true);
      setSaved(false);
      const formData = new FormData();
      formData.append("file", file);
      apiService
        .put(`/add-doc/${taskData?._id}`, formData)
        .then((res: any) => {
          let docList = [...fileList];
          docList.push({
            uid: `-${fileList.length + 1}`,
            name: res?.data?.fileName,
            status: "done",
            url: res.data.url,
          });
          setFileList(docList);
          setSyncing(false);
          setSaved(true);
          setTimeout(() => setSaved(false), 2000);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const removeDocHandle = (val: any) => {
    apiService
      .put(`/delete-task-doc/${taskData?._id}`, {
        docName: val.name,
      })
      .then((res) => {
        setFileList((prev) => prev.filter((fl) => fl.name !== val.name));
      })
      .catch((e) => console.log(e));
  };

  const handlePreview = async (file: UploadFile) => {
    let fileType = ["jpeg", "png", "jpg"];
    if (fileType?.includes(file?.name?.split(".")[1])) {
      if (!file?.url && !file?.preview) {
        file.preview = await getBase64(file?.originFileObj as FileType);
      }

      setPreviewImage(file?.url || (file?.preview as string));
      setPreviewOpen(true);
    } else {
      window.open(file?.url, "_blank")?.focus();
    }
  };

  const fileDownloadHandle = async (fileName: string, type: string) => {
    downloadDocument(`task_doc/${user?.tenant_id}/${taskData?._id}/${fileName}`, type);
  };

  const handleClick = (type: string) => {
    const isTypeInclude = showUpload.includes(type);
    if (isTypeInclude) {
      setShowUpload(showUpload.filter((item) => item !== type));
    } else {
      setShowUpload([...showUpload, type]);
    }
  };

  return (
    <div>
      <Form name="task" layout="vertical" form={taskForm}>
        <div className={TaskStyleCss.taskDetailDrawer}>
          <Flex gap={20}>
            <div style={{ width: "100%" }}>
              <Card style={{ marginBottom: 20 }}>
                <Space size={30} align="center" style={{ padding: "11px" }}>
                  <Flex align="center" onClick={() => handleClick("FILE")}>
                    <img src={showUpload.includes("FILE") ? clipBlack : clipSvg} alt="clip" />
                    <Typography.Link
                      style={{ color: showUpload.includes("FILE") ? "#515e68" : "#AFB5B9", fontSize: 13 }}
                      className="ml-1"
                    >
                      File
                    </Typography.Link>
                  </Flex>
                  <Flex onClick={() => handleClick("CHECKLIST")}>
                    <Typography.Link
                      style={{
                        color: showUpload.includes("CHECKLIST") ? "#515e68" : "#AFB5B9",
                        fontSize: 13,
                        wordSpacing: "-2px",
                      }}
                    >
                      Sub Tasks
                    </Typography.Link>
                  </Flex>
                </Space>

                {showUpload.includes("FILE") && (
                  <div className={TaskStyleCss.imageView}>
                    <Upload
                      listType="picture-card"
                      fileList={fileList}
                      beforeUpload={fileBeforUploadHandle}
                      onChange={handleChange}
                      onRemove={removeDocHandle}
                      onPreview={handlePreview}
                      itemRender={(ele, file: any) => {
                        let ext = file?.name?.split(".").pop();
                        let imgExt = ["jpeg", "jpg", "png"];
                        return (
                          <FileListItem
                            file={file}
                            imgExt={imgExt}
                            ext={ext}
                            removeDocHandle={removeDocHandle}
                            fileDownloadHandle={fileDownloadHandle}
                          />
                        );
                      }}
                    >
                      <button style={{ border: 0, background: "none" }} type="button">
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </button>
                    </Upload>
                  </div>
                )}
                {["CHECKLIST", "FILE"].every((type) => showUpload?.includes(type)) && (
                  <Divider className="divider" style={{ margin: "16px 0" }} />
                )}
                {showUpload?.includes("CHECKLIST") && (
                  <div className={TaskStyleCss.checkList}>
                    {subTaskData?.map((st: subTaskDataListInterface, index: number) => (
                      <CheckList
                        data={st}
                        taskListChange={(val: subTaskDataListInterface) => handleSubtaskChange(val, index)}
                        removeSubTaskList={() => removeSubTaskList(index)}
                      />
                    ))}
                    <Button
                      style={{ color: "#80868e", fontSize: "14px", paddingLeft: 0 }}
                      size="large"
                      type="link"
                      onClick={addSubTaskList}
                      icon={<PlusOutlined style={{ fontSize: "11px", lineHeight: "1", fill: "#80868e" }} />}
                    >
                      Add SubTask List
                    </Button>
                  </div>
                )}
              </Card>
              <Tabs
                className={TaskStyleCss.taskActivityTab}
                defaultActiveKey="1"
                type="card"
                size={"middle"}
                items={historyTabItem}
              />
            </div>
            <div className={TaskStyleCss.taskDetailSideBar}>
              <Card>
                <div className="detailStatus">
                  <Typography
                    style={{
                      color: "white",
                    }}
                  >
                    {daysRemaining === null
                      ? "No date available"
                      : daysRemaining > 0
                      ? `${daysRemaining} ${daysRemaining === 1 ? "day" : "days"} Remaining`
                      : `Overdue ${Math.abs(daysRemaining)} ${Math.abs(daysRemaining) === 1 ? "day" : "days"}`}
                  </Typography>
                </div>
                <div style={{ padding: "20px" }}>
                  <div className="details taskDetails">
                    <Row>
                      <Col span={9}>
                        <Typography.Text className="title" style={{ width: "70px" }}>
                          Due Date:
                        </Typography.Text>
                      </Col>
                      <Col span={15}>
                        {taskData?.status === "INPROGRESS" || taskData?.status === "PENDING" ? (
                          <DatePicker
                            showTime={true}
                            placeholder="DD-MM-YYYY HH:mm"
                            format={{
                              format: "DD-MM-YYYY HH:mm",
                              type: "mask",
                            }}
                            variant="borderless"
                            className="label-title"
                            size="large"
                            style={{
                              padding: 0,
                            }}
                            value={dayjs(taskData?.due_date || null, "DD-MM-YYYY HH:mm")}
                            onChange={handleDateChange}
                            required
                            name={"dueDate"}
                          />
                        ) : (
                          <Typography.Text className="data">
                            {taskData?.due_date ? dayjs(taskData?.due_date).format("DD-MM-YYYY") : ""}
                          </Typography.Text>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <Divider style={{ margin: "5px 0" }} />
                  <div className="details">
                    <Row>
                      <Col span={9}>
                        <Typography.Text className="title" style={{ width: "70px" }}>
                          Created On:{" "}
                        </Typography.Text>
                      </Col>
                      <Col span={15}>
                        <Typography.Text className="data">
                          {taskData?.created_on ? dayjs(taskData?.created_on).format("DD-MM-YYYY HH:mm") : ""}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </div>
                  <Divider style={{ margin: "5px 0" }} />
                  <div className="details status">
                    <Select
                      style={{ width: "100%" }}
                      suffixIcon={<CaretDownOutlined style={{ color: "#525c69" }} />}
                      onChange={(e) => handleStatusChange(e)}
                      value={taskData?.task_status}
                      options={Object.keys(INQUIRY_TYPE_STATUS)?.map((key) => ({
                        value: key,
                        label: INQUIRY_TYPE_STATUS[key]?.title?.toUpperCase(),
                      }))}
                    />
                  </div>
                  <div className="details">
                    <Typography.Text className="title">Created By:</Typography.Text>
                    <Divider style={{ margin: "5px 0" }} />
                    <Avatar
                      size={25}
                      icon={<UserOutlined />}
                      style={{ marginRight: 8, background: "rgb(83, 92, 106)" }}
                    />
                    <Typography.Text className="data">{taskData?.created_by?.full_name}</Typography.Text>
                  </div>
                  <div className="details taskDetails">
                    <Flex justify="space-between" align="center">
                      <Typography.Text className="title">Assignee</Typography.Text>
                      <UserInvite
                        onSubmit={updateAssigneeList}
                        assignList={assignList}
                        assigned={taskData?.assign_to}
                      />
                    </Flex>
                    <Divider style={{ margin: "5px 0" }} />
                    <>
                      {taskData && Array.isArray(taskData?.assign_to) && (
                        <>
                          {taskData?.assign_to
                            ?.slice(0, showAll ? taskData?.assign_to?.length : 4)
                            .map((assignee: IUser, index: number) => (
                              <div style={{ paddingBottom: "5px" }} key={index}>
                                <Avatar
                                  size={25}
                                  style={{
                                    marginRight: 7,
                                    backgroundColor: colors[index % colors.length],
                                    fontSize: "12px",
                                    padding: "1px",
                                    color: "#ffffffd6",
                                    fontWeight: "500",
                                    lineHeight: "1",
                                  }}
                                >
                                  {getInitials(assignee?.full_name)}
                                </Avatar>
                                <Typography.Text className="data">{assignee?.full_name}</Typography.Text>
                              </div>
                            ))}
                          {taskData?.assign_to.length > 4 && (
                            <Button type="link" onClick={handleShowMore} className="showMore">
                              {showAll ? "Show Less" : "Show More..."}
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  </div>
                </div>
              </Card>
            </div>
          </Flex>
        </div>
      </Form>
      {previewImage && (
        <Image
          wrapperStyle={{ display: "none" }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}
    </div>
  );
};

export default CommonStep;
