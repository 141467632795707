import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Flex,
  Form,
  FormInstance,
  Image,
  Input,
  InputRef,
  Row,
  Select,
  Space,
  Typography,
  UploadFile,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import { InputBox } from "../../../components/FormInput";
import CheckList from "./CheckList";
import FileListItem from "../../../components/FileListItem";
import QuickPassengerModel from "../Task/QuickPassengerModel";
import fireActiveIcon from "../../../assets/fireactive.svg";
import fireIcon from "../../../assets/fire.svg";
import clipBlack from "../../../assets/clipBlack.svg";
import clipSvg from "../../../assets/clip.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { subTaskDataListInterface } from "../../../slices/taskSlice";
import { capitalizeFirstLetter, capitalizeFirstLetterOfWord } from "../../../utils/commonFunctions";
import { QUICK_MODEL_TYPE } from "../../../utils/const";
import TaskStyleCss from "../createDrawer.module.scss";
import commonCss from "../../../css/commonStyle.module.scss";

interface ICommonFormProps {
  form: FormInstance<any>;
  setIsTitleEmpty?: Dispatch<SetStateAction<boolean>>;
  setIsCustomerName?: Dispatch<SetStateAction<boolean>>;
  setIsPrior: Dispatch<SetStateAction<boolean>>;
  isPrior: boolean;
  fileList: UploadFile[];
  handleChange: (val: any) => void;
  handlePreview: (val: UploadFile) => void;
  previewImage: string;
  previewOpen: boolean;
  setPreviewOpen: Dispatch<SetStateAction<boolean>>;
  setPreviewImage: Dispatch<SetStateAction<string>>;
  setShowUpload: (val: any) => void;
  showUpload: string[];
  type: "TASK" | "INQUIRY";
  setFileList: Dispatch<SetStateAction<any[]>>;
  setSubTaskData: Dispatch<SetStateAction<subTaskDataListInterface[]>>;
  subTaskData: subTaskDataListInterface[];
  setSelectedContactNumber?: Dispatch<SetStateAction<string | null>>;
  selectedContactNumber?: string | null;
}

function CommonForm({
  form,
  type,
  setIsTitleEmpty,
  setIsPrior,
  isPrior,
  fileList,
  handleChange,
  handlePreview,
  previewImage,
  previewOpen,
  setPreviewOpen,
  setPreviewImage,
  setShowUpload,
  showUpload,
  setIsCustomerName,
  setFileList,
  setSubTaskData,
  subTaskData,
  setSelectedContactNumber,
}: ICommonFormProps) {
  const InputfieldRef = useRef<InputRef>(null);
  const dispatch = useDispatch<AppDispatch>();
  const { referenceList } = useSelector((state: RootState) => state.contact);
  const [isAddNewPassenger, setIsAddNewPassenger] = useState(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      InputfieldRef.current?.focus();
    }
  };

  const handleClick = (type: string) => {
    const isTypeInclude = showUpload.includes(type);
    if (isTypeInclude) {
      setShowUpload(showUpload.filter((item) => item !== type));
    } else {
      setShowUpload([...showUpload, type]);
    }
  };

  const handleSubtaskChange = (val: subTaskDataListInterface, index: number) => {
    const taskList = [...subTaskData];
    taskList[index] = val;
    setSubTaskData(taskList);
  };

  const removeSubTaskList = (index: number) => {
    const updatedSubTaskData = subTaskData.filter((_, index) => index !== index);
    setSubTaskData(updatedSubTaskData);
  };

  const addSubTaskList = () => {
    setSubTaskData([
      ...subTaskData,
      {
        name: "SubTask List",
        task: [],
      },
    ]);
  };

  const uploadButton = (
    <>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
    </>
  );

  const removeDocHandle = (val: any) => {
    setFileList((prev) => prev.filter((file) => file.uid !== val.uid));
  };

  const handleCustomerChange = (value: string) => {
    const selectedContact = referenceList.find((refDt) => refDt._id === value);
    setSelectedContactNumber?.(selectedContact ? selectedContact.contact_number : null);
    setIsCustomerName?.(false);
    form.setFieldValue("clientId", value);
  };

  const onQuickModalClose = () => {
    setIsAddNewPassenger(false);
  };

  const handleQuickSave = async (createdPassId: string) => {
    handleCustomerChange(createdPassId);
  };

  return (
    <>
      <Row className="title-wrapper" justify={"space-between"}>
        <Col span={13}>
          {type == "TASK" ? (
            <Form.Item name="title" className="mb-0">
              <Input
                placeholder="Enter task name"
                variant="borderless"
                autoComplete="off"
                tabIndex={0}
                onChange={() => setIsTitleEmpty && setIsTitleEmpty(false)}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                  form.setFieldValue("title", capitalizeFirstLetterOfWord(e.target.value))
                }
                onKeyDown={handleKeyDown}
              />
            </Form.Item>
          ) : type == "INQUIRY" ? (
            <div>inquiry</div>
          ) : (
            <div>none</div>
          )}
        </Col>
        {/* <Col>
          {selectedContactNumber && (
            <Col>
              <Typography.Text className="clientContact">{selectedContactNumber}</Typography.Text>
            </Col>
          )}
        </Col> */}
        <Col span={7}>
          <Row align={"middle"}>
            <Col span={16}>
              <Form.Item name="clientId">
                <Select
                  placeholder="+ Add Client"
                  style={{ width: "100%" }}
                  options={referenceList.map((refDt) => ({
                    label: refDt.contact_name || refDt.full_name,
                    value: refDt._id,
                    title: refDt.contact_number,
                  }))}
                  onChange={handleCustomerChange}
                  variant="borderless"
                  allowClear
                  suffixIcon={null}
                  rootClassName="clientSelectBox"
                  labelRender={(val: any) => {
                    return (
                      <div>
                        {val.label}
                        <span>({val.title})</span>
                      </div>
                    );
                  }}
                  dropdownRender={(menu) => (
                    <>
                      <Button
                        className={commonCss.addNew}
                        type="text"
                        icon={<PlusOutlined />}
                        block
                        onClick={() => setIsAddNewPassenger(true)}
                      >
                        Add New Passenger
                      </Button>
                      <Divider style={{ margin: "8px 8px", borderBlockStartColor: "#00000026" }} />
                      {menu}
                    </>
                  )}
                />
              </Form.Item>
            </Col>
            <QuickPassengerModel
              isOpen={isAddNewPassenger}
              handleModelClose={onQuickModalClose}
              onSave={handleQuickSave}
              type={QUICK_MODEL_TYPE.firstStepClient}
            />

            <Col span={8} className="flex justify-end">
              <Form.Item className="mb-0" name={"is_prior"} valuePropName="checked">
                <Checkbox onChange={() => setIsPrior(!isPrior)} tabIndex={3}>
                  <Flex gap={8} align="center">
                    High Priority <img src={isPrior ? fireActiveIcon : fireIcon} alt="fire" />
                  </Flex>
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Form.Item className={TaskStyleCss.notes}>
        <InputBox
          ref={InputfieldRef}
          type="textarea"
          name={"notes"}
          placeholder="Add notes here"
          style={{ marginBottom: "10px" }}
          autoSize={{ minRows: "7", maxRows: "22" }}
          tabIndex={1}
          onBlur={(e: React.FocusEvent<HTMLTextAreaElement>) =>
            form.setFieldValue("notes", capitalizeFirstLetter(e.target.value))
          }
        />
      </Form.Item>
      <Space size={30} align="center" style={{ padding: "11px" }}>
        <Flex align="center" onClick={() => handleClick("FILE")}>
          <img src={showUpload.includes("FILE") ? clipBlack : clipSvg} alt="clip" />
          <Typography.Link
            style={{ color: showUpload.includes("FILE") ? "#515e68" : "#AFB5B9", fontSize: 13 }}
            className="ml-1"
          >
            File
          </Typography.Link>
        </Flex>
        <Flex onClick={() => handleClick("CHECKLIST")}>
          <Typography.Link
            style={{
              color: showUpload.includes("CHECKLIST") ? "#515e68" : "#AFB5B9",
              fontSize: 13,
              wordSpacing: "-2px",
            }}
          >
            Sub Tasks
          </Typography.Link>
        </Flex>
      </Space>
      {showUpload.includes("FILE") && (
        <div>
          <div className={`${TaskStyleCss.uploadContainer} ${fileList.length > 0 ? TaskStyleCss.showPreview : ""}`}>
            <Dragger
              listType="picture-card"
              fileList={fileList}
              onChange={handleChange}
              beforeUpload={() => false}
              style={{ width: "100%" }}
              onPreview={handlePreview}
              multiple
              itemRender={(ele, file: UploadFile) => {
                let ext = file.name.split(".").pop() || "";
                let imgExt = ["jpeg", "jpg", "png"];
                return <FileListItem file={file} imgExt={imgExt} ext={ext} removeDocHandle={removeDocHandle} />;
              }}
            >
              {uploadButton}
            </Dragger>
            {previewImage && (
              <Image
                wrapperStyle={{ display: "none" }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(""),
                }}
                src={previewImage}
              />
            )}
          </div>
        </div>
      )}
      {showUpload.includes("CHECKLIST") && (
        <div className={TaskStyleCss.checkList}>
          {subTaskData.map((st: subTaskDataListInterface, index: number) => (
            <CheckList
              data={st}
              taskListChange={(val: subTaskDataListInterface) => handleSubtaskChange(val, index)}
              removeSubTaskList={() => removeSubTaskList(index)}
            />
          ))}
          <Button
            style={{ color: "#80868e", fontSize: "14px", paddingLeft: 0, marginBottom: "13px" }}
            size="large"
            type="link"
            onClick={addSubTaskList}
            icon={<PlusOutlined style={{ fontSize: "11px", lineHeight: "1", fill: "#80868e" }} />}
          >
            Add SubTask List
          </Button>
        </div>
      )}
    </>
  );
}

export default CommonForm;
