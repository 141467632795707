import React, { useState, useEffect } from "react";
import { Image, Skeleton } from "antd";
import Slider from "react-slick";
import _ from "lodash";
import { IImage } from "../../slices/notesSlice";
import "./ImageGrid.scss";

interface ImageGridProps {
  images: any[];
  noteId?: string;
}

const ImageGrid: React.FC<ImageGridProps> = ({ images }) => {
  const [imageUrls, setImageUrls] = useState<IImage[]>([]);
  const [loading, setLoading] = useState(true);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotPosition: "bottom",
    arrows: true,
  };

  const imageTypes = ["jpg", "jpeg", "png"];
  const validImages = _.filter(images, (image) => _.includes(imageTypes, image?.fileType));

  useEffect(() => {
    if (!_.isEmpty(validImages)) {
      setTimeout(() => {
        setImageUrls(validImages);
        setLoading(false);
      }, 1000);
    } else {
      setLoading(false);
    }
  }, [images]);

  return (
    <div className="image-gallery" onClick={(event) => event.stopPropagation()}>
      {loading ? (
        <Skeleton.Image active={true} className="image-skeleton" />
      ) : (
        <>
          {imageUrls && imageUrls?.length === 1 ? (
            <Image
              src={imageUrls[0]?.url}
              width={"100%"}
              alt={imageUrls[0]?.fileName || "Image"}
              style={{ borderRadius: 8, marginBottom: "8px" }}
              preview={false}
              loading="lazy"
            />
          ) : (
            imageUrls?.length >= 1 && (
              <Slider {...settings}>
                {_.sortBy(imageUrls, "order")?.map((image: IImage) => (
                  <div key={image?.url} className="image-slide">
                    <Image src={image?.url} width={"100%"} alt={image?.fileName} loading="lazy" preview={false} />
                  </div>
                ))}
              </Slider>
            )
          )}
        </>
      )}
    </div>
  );
};

export default ImageGrid;
