import React from "react";
import { Tabs, TabsProps } from "antd";
import tabCss from "./header.module.scss";

interface TabLayoutProps {
  items: TabsProps["items"];
  onChange: (key: string) => void;
  currentValue: string;
}

const TabLayout: React.FC<TabLayoutProps> = ({ items, onChange, currentValue }) => {
  return (
    <>
      <div className={tabCss.tabsLayout}>
        <Tabs
          activeKey={currentValue}
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          style={{ fontWeight: "500" }}
          className="custom-tab"
        />
      </div>
    </>
  );
};

export default TabLayout;
