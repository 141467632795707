// // Archive Note List
import { Typography } from "antd";
import { MdOutlineArchive } from "react-icons/md";
import { Masonry } from "@mui/lab";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import NoteCard from "./NoteCard";
import styleCss from "./style.module.scss";

const ArchiveNoteList = () => {
  const { archiveNoteList } = useSelector((state: RootState) => state.notes);

  return (
    <div className={styleCss.noteGirdViewContainer}>
      {!!archiveNoteList.length && (
        <div className={styleCss.filterTitle}>
          <Masonry className={styleCss.myMasonryGrid}>
            {archiveNoteList?.map((note: any) => (
              <NoteCard {...note} key={note?.id} />
            ))}
          </Masonry>
        </div>
      )}
      {!archiveNoteList.length && (
        <div className="no-data-notification">
          <MdOutlineArchive size={90} className="note-no-data-icon" />
          <Typography className="no-data-text">Your archived notes appear here</Typography>
        </div>
      )}
    </div>
  );
};

export default ArchiveNoteList;
