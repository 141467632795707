import { useEffect, useState } from "react";
import { Avatar, Flex, Mentions, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { MentionsOptionProps } from "antd/es/mentions";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import webSocketService from "../../../../utils/websocketService";
import { fetchComments, ICommentAction, ITask, IUser, removeComment } from "../../../../slices/taskSlice";
import Comment from "./Comment";
import StyleCss from "../../style.module.scss";

interface ICommentProps {
  taskData: ITask | null;
}

const CommentsBlock = ({ taskData }: ICommentProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.user);
  const { comments } = useSelector((state: RootState) => state.task);

  const [commentText, setCommentText] = useState("");
  const [isCollapse, setIsCollapse] = useState(1);
  const containerHeight = isCollapse === 1 ? 40 : isCollapse * 28;
  const [mentions, setMentions] = useState<{ _id: string; full_name: string }[]>([]);
  const [editingCommentId, setEditingCommentId] = useState<string | null>(null);

  const resetState = () => {
    setCommentText("");
    setMentions([]);
    setIsCollapse(1);
  };

  const onTextChange = (text: string) => {
    setCommentText(text);
    const filteredMentions = mentions.filter((mention) => commentText.includes(mention?.full_name));
    const uniqueMentions = _.uniqBy(filteredMentions, "_id");
    setMentions(uniqueMentions);
  };

  useEffect(() => {
    if (taskData?._id) {
      dispatch(fetchComments(taskData?._id));
    }
  }, [taskData]);

  useEffect(() => {
    const handleData = async (val: ICommentAction) => {
      if (taskData?._id === val?.comment?.task) {
        if ((val.action === "ADD" || val.action === "UPDATE") && taskData?._id) {
          await dispatch(fetchComments(taskData?._id));
        }
      }
      if (val.action === "DELETE" && val?.commentId) {
        dispatch(removeComment(val?.commentId));
      }
    };
    webSocketService.onMessage("taskComment", handleData);
  }, [user, taskData?._id]);

  const handleSend = () => {
    const messageObject = {
      message: commentText,
      mentions: mentions?.map((item) => item?._id),
    };

    webSocketService.sendMessage("task/comment/post", {
      tntId: user?.tenant_id,
      taskId: taskData?._id,
      userId: user?._id,
      reqComment: messageObject,
    });

    resetState();
  };

  const onSelect = (option: MentionsOptionProps) => {
    const selectedUser = taskData?.assign_to?.find((user: IUser) => user._id === option.key);
    if (selectedUser) {
      setMentions((prev) => [...prev, { _id: selectedUser._id, full_name: selectedUser.full_name }]);
    }
  };

  const handleReply = (user: IUser) => {
    setIsCollapse(4);
    setCommentText(`@${user?.full_name} `);

    const mentionsInput = document.querySelector(".rc-textarea") as HTMLTextAreaElement;
    if (mentionsInput) {
      mentionsInput.focus();
    }

    setMentions((prev) => [...prev, { _id: user?._id, full_name: user?.full_name }]);
  };

  const handleEdit = (commentId: string, newText: string, mentions: IUser[]) => {
    const messageObject = {
      message: newText,
      mentions: mentions?.map((item) => item?._id),
    };
    webSocketService.sendMessage("task/comment/update", {
      tntId: user?.tenant_id,
      commentId: commentId,
      userId: user?._id,
      content: messageObject,
    });
    setEditingCommentId(null);
  };

  return (
    <div className={StyleCss.commentWrapper}>
      {comments &&
        comments?.map((comment) => {
          return (
            <Comment
              comment={comment}
              taskData={taskData}
              onReply={handleReply}
              onEdit={handleEdit}
              isEditing={editingCommentId === comment?._id}
              setEditingCommentId={setEditingCommentId}
            />
          );
        })}
      <Flex className="send-message-wrapper">
        <div className="avatar">
          <Avatar size={36} icon={<UserOutlined />} style={{ marginRight: 8 }} />
        </div>
        <div className="text-area">
          <div className="mentions-container" style={{ height: `${containerHeight}px` }}>
            <Mentions
              placeholder="Input @ to mention people"
              rows={isCollapse}
              value={commentText}
              onChange={onTextChange}
              onClick={() => setIsCollapse(4)}
              onFocus={() => setIsCollapse(4)}
              onSelect={onSelect}
              options={taskData?.assign_to?.map((value: IUser) => ({
                key: value?._id,
                value: value.full_name,
                label: value.full_name,
              }))}
              disabled={!!editingCommentId}
            />
          </div>
          {isCollapse > 1 && !editingCommentId && (
            <div className="action-buttons" style={{ marginTop: 8 }}>
              <Button className="send" onClick={handleSend} style={{ marginRight: 8 }}>
                SEND
              </Button>
              <Button className="cancel" onClick={resetState}>
                CANCEL
              </Button>
            </div>
          )}
        </div>
      </Flex>
    </div>
  );
};

export default CommentsBlock;
