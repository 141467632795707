// NotesFooterIcon
import React, { useEffect, useState, Dispatch, SetStateAction, useCallback, useMemo } from "react";
import {
  Button,
  Popover,
  Input,
  Checkbox,
  Menu,
  List,
  Typography,
  Upload,
  UploadProps,
  DatePicker,
  TimePicker,
  UploadFile,
  Divider,
  Flex,
  Tooltip,
  message,
} from "antd";
import { LuBellRing } from "react-icons/lu";
import { FiUserPlus } from "react-icons/fi";
import { MdOutlineColorLens, MdMoreVert, MdDeleteForever, MdRestoreFromTrash } from "react-icons/md";
import { BiUndo, BiRedo } from "react-icons/bi";
import { GoClock } from "react-icons/go";
import { IoArrowBackOutline } from "react-icons/io5";
import { PiCheckSquareBold } from "react-icons/pi";
import { RiInboxArchiveLine, RiPushpin2Fill, RiPushpin2Line, RiInboxUnarchiveLine } from "react-icons/ri";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { getNoteIds, getUniqIdsOnly } from "../../utils/commonFunctions";
import { NOTES_BACKGROUND_COLOR, NOTES_FILTER_TYPE, NOTES_FOOTER_TYPE, NOTES_STATUS } from "../../utils/const";
import ConfirmationModal from "./ConfirmationModel";
import { UserInvite } from "../../components/UserInviteModel";
import {
  addLabelByNoteId,
  changeNoteStatus,
  createLabel,
  deleteNote,
  fetchArchiveNoteList,
  copyNote,
  uploadDocByNoteId,
  bulkLabelChange,
  bulkDelete,
  bulkStatusChange,
  bulkPinNotes,
  bulkMakeCopy,
  fetchAllNotes,
  ISelectedLabels,
  IMyNote,
} from "../../slices/notesSlice";
import Frame from "../../assets/Frame";
import styleCss from "./style.module.scss";

dayjs.extend(utc);

interface IBellIcon {
  isRemainderOptionOpen?: boolean;
  setIsRemainderOptionOpen?: (visible: boolean) => void;
  reminder: string;
  setReminder: (data: string) => void;
  footerType: string;
}
interface ColorPickerIconProps {
  onColorChange: (color: string) => void;
  handleVisibleChange?: (visible: boolean) => void;
  isColorPopoverOpen?: boolean;
  footerType: string;
}

interface MoreOptionsIconProps {
  isMoreOptionOpen: boolean;
  setIsMoreOptionOpen?: (moreOptionsOpen: boolean) => void;
  onCheckboxToggle?: () => void;
  showCheckboxes?: boolean;
  id?: string;
  selectedLabels: any[];
  setSelectedLabels: (val: any[]) => void;
  footerType: string;
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IUploadDocumentProps {
  uploadFileList?: UploadFile[];
  setUploadFileList?: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  id: string;
  footerType: string;
}

interface ICheckBoxInCrateNote {
  onCheckboxToggle: () => void;
}

interface IFooterType {
  id: string;
  footerType?: string;
  onConfirmButtonClick?: () => void;
}

export const BellIcon: React.FC<IBellIcon> = ({
  isRemainderOptionOpen,
  setIsRemainderOptionOpen,
  reminder,
  setReminder,
  footerType,
}) => {
  const [dateTime, setDateTime] = useState<{ date: dayjs.Dayjs | null; time: dayjs.Dayjs | null }>({
    date: null,
    time: null,
  });
  const [showDateTimePicker, setShowDateTimePicker] = useState<null | boolean>(false);

  useEffect(() => {
    if (footerType === NOTES_FOOTER_TYPE.bulkAction) setShowDateTimePicker(true);
  }, [footerType]);

  useEffect(() => {
    if (reminder) {
      const reminderDate = dayjs(reminder);
      setDateTime({
        date: reminderDate.startOf("day"),
        time: reminderDate,
      });
      setShowDateTimePicker(true);
    } else if (footerType !== NOTES_FOOTER_TYPE.bulkAction) {
      setShowDateTimePicker(false);
    }
  }, [reminder]);

  const handleOptionClick = useCallback(
    (option: string) => {
      const now = dayjs();
      let dateTime = dayjs.utc();
      if (option === "Today") {
        dateTime = now.set("hour", 20).set("minute", 0).set("second", 0);
      } else if (option === "Tomorrow") {
        dateTime = now.add(1, "day").set("hour", 8).set("minute", 0).set("second", 0);
      } else if (option === "Pick Date & Time") {
        setShowDateTimePicker(true);
        return;
      }
      setReminder(dateTime.toISOString());
      setIsRemainderOptionOpen && setIsRemainderOptionOpen(false);
    },
    [setReminder, setIsRemainderOptionOpen]
  );

  const handleSave = useCallback(() => {
    const { date, time } = dateTime;
    if (date && time) {
      const combinedDateTime = date.set("hour", time.hour()).set("minute", time.minute()).set("second", 0);
      setReminder(combinedDateTime.toISOString());
    }
    setShowDateTimePicker(false);
    setIsRemainderOptionOpen && setIsRemainderOptionOpen(false);
  }, [dateTime, setReminder, setIsRemainderOptionOpen]);

  const handleDateChange = useCallback((date: dayjs.Dayjs | null) => {
    setDateTime((prev) => ({ ...prev, date }));
  }, []);

  const handleTimeChange = useCallback((time: dayjs.Dayjs | null) => {
    setDateTime((prev) => ({ ...prev, time }));
  }, []);

  const handleBellClick = useCallback(() => {
    setIsRemainderOptionOpen && setIsRemainderOptionOpen(true);
  }, [setIsRemainderOptionOpen]);

  const handleVisibleChange = useCallback(() => {
    setIsRemainderOptionOpen && setIsRemainderOptionOpen(!isRemainderOptionOpen);
  }, [isRemainderOptionOpen, setIsRemainderOptionOpen]);

  const popoverContent = useMemo(() => {
    const now = dayjs();
    const isBeforeEightAM = now.hour() < 8;
    const todayTime = isBeforeEightAM ? "8:00 AM" : "8:00 PM";

    return showDateTimePicker ? (
      <div className="chooseDate">
        <Flex gap={8} align="center" className="title">
          <IoArrowBackOutline size={18} onClick={() => setShowDateTimePicker(false)} color="#555555" />
          <Typography>Pick Date & Time</Typography>
        </Flex>
        <Divider style={{ borderColor: "rgba(100, 100, 100, 0.2)", margin: "0px" }} />
        <div className="dateTimepicker">
          <DatePicker
            value={dateTime.date}
            inputReadOnly
            onChange={handleDateChange}
            format={"MMM DD, YYYY"}
            variant="borderless"
          />
          <TimePicker
            value={dateTime.time}
            inputReadOnly
            onChange={handleTimeChange}
            format={"h:mm A"}
            variant="borderless"
          />
          <Flex justify="end">
            <Button disabled={dateTime?.date === null || dateTime?.time === null} onClick={handleSave}>
              Save
            </Button>
          </Flex>
        </div>
      </div>
    ) : (
      <div className="selectDate">
        <Typography.Title>Reminder:</Typography.Title>
        <Button onClick={() => handleOptionClick("Today")}>
          <Typography>Later today</Typography>
          <Typography>{todayTime}</Typography>
        </Button>
        <Button onClick={() => handleOptionClick("Tomorrow")}>
          <Typography>Tomorrow</Typography>
          <Typography> 8:00 AM</Typography>
        </Button>
        <Button onClick={() => handleOptionClick("Pick Date & Time")}>
          <Flex justify="start" align="center" gap={10}>
            <GoClock />
            <Typography>Pick Date & Time</Typography>
          </Flex>
        </Button>
      </div>
    );
  }, [showDateTimePicker, handleOptionClick, handleDateChange, handleTimeChange, handleSave]);

  return (
    <Tooltip title="Remind me">
      <Popover
        trigger="click"
        placement="bottomLeft"
        visible={isRemainderOptionOpen}
        onVisibleChange={handleVisibleChange}
        content={popoverContent}
        overlayClassName={styleCss.reminderPopover}
      >
        <Button
          type="link"
          icon={<LuBellRing size={footerType === NOTES_FOOTER_TYPE.bulkAction ? 21 : 16} color="#444444" />}
          onClick={handleBellClick}
        />
      </Popover>
    </Tooltip>
  );
};

// User Plus Icon Component
interface UserPlusIconProps {
  userList: (val: any[]) => void;
  shareList?: any[];
  teamList: any[];
  shareOpen: boolean;
  setSherOpen: Dispatch<SetStateAction<boolean>>;
}
export const UserPlusIcon = ({ userList, teamList, shareList, shareOpen, setSherOpen }: UserPlusIconProps) => {
  return (
    <UserInvite
      visibility={shareOpen}
      setVisibility={setSherOpen}
      onSubmit={userList}
      assigned={shareList}
      assignList={teamList}
    >
      <Tooltip title="Collaborator">
        <Button type="link" icon={<FiUserPlus size={16} color="#444444" />} />
      </Tooltip>
    </UserInvite>
  );
};

// Color Picker Icon Component
export const ColorPickerIcon: React.FC<ColorPickerIconProps> = ({
  onColorChange,
  handleVisibleChange,
  isColorPopoverOpen,
  footerType,
}) => {
  const ColorPopoverContent = (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "8px",
        flexDirection: "row",
        width: "100%",
      }}
    >
      {NOTES_BACKGROUND_COLOR?.map((color) => (
        <div
          key={color}
          style={{
            backgroundColor: color,
            width: "24px",
            height: "24px",
            borderRadius: "4px",
            cursor: "pointer",
            border: "1px solid #ddd",
          }}
          onClick={() => onColorChange(color)}
        />
      ))}
    </div>
  );

  return (
    <Tooltip title="Background option">
      <Popover
        content={ColorPopoverContent}
        trigger="click"
        placement="bottom"
        visible={isColorPopoverOpen}
        onVisibleChange={handleVisibleChange}
      >
        <Button
          type="link"
          icon={<MdOutlineColorLens size={footerType === NOTES_FOOTER_TYPE.bulkAction ? 22 : 16} color="#444444" />}
          style={{ display: "inline-flex" }}
        />
      </Popover>
    </Tooltip>
  );
};

// Frame Icon Component
export const FrameIcon: React.FC<IUploadDocumentProps> = ({ setUploadFileList, uploadFileList, id, footerType }) => {
  const dispatch = useDispatch<AppDispatch>();
  const maxFileLimit = 10;
  const maxFileSizeMB = 1;

  const debouncedUpload = _.debounce(async (fileList: UploadFile[]) => {
    const filteredFileList = fileList.filter((file) => !!file.originFileObj);
    const limitedFileList = filteredFileList.slice(0, maxFileLimit);

    const formData = new FormData();
    limitedFileList?.forEach((file, index) => {
      if (file.originFileObj) {
        formData.append(`doc_${index + 1}`, file.originFileObj);
      }
    });

    if (filteredFileList?.length > maxFileLimit) {
      message.warning(`Maximum only ${maxFileLimit} files are allowed. Extra files have been removed.`);
    }

    if (footerType === NOTES_FOOTER_TYPE.editNoteModal || footerType === NOTES_FOOTER_TYPE.cardBottom) {
      await dispatch(uploadDocByNoteId({ noteId: id, doc: formData }));
    }
  }, 500);

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    const filteredFiles = newFileList.filter((file) => {
      const isValidSize = (file.size || 0) / 1024 / 1024 <= maxFileSizeMB;
      if (!isValidSize) {
        message.error(`${file.name} exceeds the size limit of ${maxFileSizeMB}MB`);
      }
      return isValidSize;
    });

    setUploadFileList && setUploadFileList(filteredFiles);
    debouncedUpload(filteredFiles);
  };

  const handleBeforeUpload: UploadProps["beforeUpload"] = (file) => {
    const isValidSize = file.size / 1024 / 1024 <= maxFileSizeMB;
    if (!isValidSize) {
      message.error(`${file.name} exceeds the size limit of ${maxFileSizeMB}MB`);
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  return (
    <Tooltip title="Add image">
      <Upload
        onChange={handleChange}
        showUploadList={false}
        fileList={uploadFileList || []}
        multiple={true}
        beforeUpload={handleBeforeUpload}
      >
        <Button
          type="link"
          icon={<Frame color="#444444" size={footerType === NOTES_FOOTER_TYPE.createNoteSuffix ? 24 : 16} />}
        />
      </Upload>
    </Tooltip>
  );
};

// More Options Icon Component
export const MoreOptionsIcon: React.FC<MoreOptionsIconProps> = ({
  isMoreOptionOpen,
  setIsMoreOptionOpen,
  onCheckboxToggle,
  showCheckboxes,
  id,
  selectedLabels,
  setSelectedLabels,
  footerType,
  setIsModalOpen,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { allLabelList, selectedNotes, activeFilterTab } = useSelector((state: RootState) => state.notes);
  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(isMoreOptionOpen || false);
  const [addLabelVisible, setAddLabelVisible] = useState<boolean>(false);
  const [searchLabel, setSearchLabel] = useState<string>("");

  useEffect(() => {
    if (!addLabelVisible) {
      setSearchLabel("");
    }
  }, [addLabelVisible]);

  const moveToBin = () => {
    if (id && footerType !== NOTES_FOOTER_TYPE.bulkAction) {
      dispatch(changeNoteStatus({ id, status: NOTES_STATUS.deleted }));
      if (setIsModalOpen) {
        setIsModalOpen(false);
      }
    }
    if (footerType === NOTES_FOOTER_TYPE.bulkAction)
      dispatch(bulkStatusChange({ ids: getNoteIds(selectedNotes), status: NOTES_STATUS.deleted }));
    if (activeFilterTab === NOTES_FILTER_TYPE.archive) {
      setTimeout(() => {
        dispatch(fetchArchiveNoteList());
      }, 100);
    }
  };
  const archiveNotes = () => {
    if (id && footerType !== NOTES_FOOTER_TYPE.bulkAction) {
      dispatch(
        changeNoteStatus({
          id,
          status: activeFilterTab === NOTES_FILTER_TYPE.archive ? NOTES_STATUS.saved : NOTES_STATUS.archive,
        })
      );
      if (setIsModalOpen) {
        setIsModalOpen(false);
      }
    }
    if (activeFilterTab === NOTES_FILTER_TYPE.archive) {
      setTimeout(() => {
        dispatch(fetchArchiveNoteList());
      }, 100);
    }
  };

  // Handle Menu click
  const handleMenuClick = (key: string) => {
    if (key === "addLabel") {
      setAddLabelVisible(true);
    } else if (key === "showCheckbox") {
      onCheckboxToggle && onCheckboxToggle();
      setIsPopoverVisible(false);
      setIsMoreOptionOpen && setIsMoreOptionOpen(false);
    } else if (key === "deleteNotes") {
      moveToBin();
      setIsPopoverVisible(false);
      // setDeleteModalVisible(true);
      setIsMoreOptionOpen && setIsMoreOptionOpen(false);
    } else if (key === "makeCopy") {
      const handleCopyNote = async () => {
        try {
          let response;
          if (id && footerType !== NOTES_FOOTER_TYPE.bulkAction) {
            response = await dispatch(copyNote(id));
          }
          if (footerType === NOTES_FOOTER_TYPE.bulkAction) {
            response = await dispatch(bulkMakeCopy(getNoteIds(selectedNotes)));
          }
          if (response?.meta.requestStatus === "fulfilled") {
            message.success("Note copied");
          } else {
            message.error("Failed to copy note");
          }
        } catch (error) {
          message.error("An error occurred while copying the note");
        }
      };
      handleCopyNote();
      setIsPopoverVisible(false);
      setIsMoreOptionOpen && setIsMoreOptionOpen(false);
    } else if (key === "archive") {
      archiveNotes();
      setIsPopoverVisible(false);
      setIsMoreOptionOpen && setIsMoreOptionOpen(false);
    }
  };

  // on search label
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchLabel(e.target.value);
  };

  // for add new label
  const handleAddLabel = (label: string) => {
    if (label?.trim() && !allLabelList?.find((item) => item?.title === label)) {
      dispatch(createLabel(label));
      setSearchLabel("");
    }
  };

  const handleLabelChange = async (checked: boolean, label: ISelectedLabels) => {
    const updatedLabels = checked
      ? [...selectedLabels, { title: label?.title, _id: label?._id }]
      : selectedLabels?.filter((l) => l?._id !== label?._id);
    setSelectedLabels(updatedLabels);
    if (id && footerType !== NOTES_FOOTER_TYPE.bulkAction) {
      dispatch(addLabelByNoteId({ noteId: id, labelIds: getUniqIdsOnly(updatedLabels) }));
    } else if (footerType === NOTES_FOOTER_TYPE.bulkAction) {
      await dispatch(bulkLabelChange({ ids: getNoteIds(selectedNotes), labels: getUniqIdsOnly(updatedLabels) }));
    }
    // setIsPopoverVisible(false);
    setIsMoreOptionOpen && setIsMoreOptionOpen(false);
  };

  const handleVisibleChange = (newVisible: boolean) => {
    setIsPopoverVisible(newVisible);
    setIsMoreOptionOpen && setIsMoreOptionOpen(newVisible);
    if (!newVisible) setAddLabelVisible(false);
  };

  const filteredLabels = allLabelList?.filter((label) =>
    label?.title?.toLowerCase()?.includes(searchLabel?.toLowerCase())
  );

  const menu = (
    <Menu onClick={(e) => handleMenuClick(e.key)} className={styleCss.FooterMenu}>
      <Menu.Item key="addLabel">Add Label</Menu.Item>
      {footerType !== NOTES_FOOTER_TYPE.bulkAction && (
        <Menu.Item key="showCheckbox">{showCheckboxes ? "Hide Checkbox" : "Show Checkbox"}</Menu.Item>
      )}
      {footerType !== NOTES_FOOTER_TYPE.createNote && <Menu.Item key="makeCopy">Make Copy</Menu.Item>}
      {footerType !== NOTES_FOOTER_TYPE.bulkAction && footerType !== NOTES_FOOTER_TYPE.createNote && (
        <Menu.Item key="archive">
          {activeFilterTab === NOTES_FILTER_TYPE.archive ? "Unachived Notes" : "Archive Notes"}
        </Menu.Item>
      )}
      {footerType !== NOTES_FOOTER_TYPE.createNote && (
        <Menu.Item key="deleteNotes" danger>
          <span style={{ color: "#ff4d4f" }}>Move to bin</span>
        </Menu.Item>
      )}
    </Menu>
  );

  const addLabelPopover = (
    <div>
      <Typography>Label note</Typography>
      <Input.Search
        placeholder="Search labels"
        value={searchLabel}
        onChange={handleSearchChange}
        onSearch={handleAddLabel}
        enterButton="Add label"
      />
      <List>
        {filteredLabels?.map((label: ISelectedLabels) => {
          return (
            <List.Item key={label?._id}>
              <Checkbox
                checked={!!selectedLabels?.find((item) => item?._id === label?._id)}
                onChange={(e) => handleLabelChange(e.target.checked, label)}
              >
                <label
                  style={{ cursor: "pointer" }}
                  onClick={() => handleLabelChange(!selectedLabels?.find((item) => item?._id === label?._id), label)}
                >
                  {label?.title}
                </label>
              </Checkbox>
            </List.Item>
          );
        })}
      </List>
    </div>
  );

  return (
    <>
      <Tooltip title="More">
        <Popover
          overlayClassName={styleCss.addLabel}
          content={addLabelVisible ? addLabelPopover : menu}
          trigger="click"
          visible={isPopoverVisible}
          placement="bottom"
          onVisibleChange={handleVisibleChange}
        >
          <Button
            type="link"
            icon={<MdMoreVert size={footerType === NOTES_FOOTER_TYPE.bulkAction ? 24 : 16} color="#444444" />}
          />
        </Popover>
      </Tooltip>
    </>
  );
};

interface UndoIconProps {
  handleUndo?: () => void;
  canUndo?: boolean;
}

// Undo Icon Component
export const UndoIcon: React.FC<UndoIconProps> = ({ handleUndo, canUndo }) => {
  return (
    <Tooltip title="Undo">
      <Button
        type="link"
        icon={<BiUndo size={16} style={{ fill: canUndo ? "#444444" : "#44444457" }} />}
        onClick={handleUndo}
        disabled={!canUndo}
      />
    </Tooltip>
  );
};

interface RedoIconProps {
  handleRedo?: () => void;
  canRedo?: boolean;
}

// Redo Icon Component
export const RedoIcon: React.FC<RedoIconProps> = ({ handleRedo, canRedo }) => {
  return (
    <Tooltip title="Redo">
      <Button
        type="link"
        icon={<BiRedo size={16} style={{ fill: canRedo ? "#444444" : "#44444457" }} />}
        onClick={handleRedo}
        disabled={!canRedo}
      />
    </Tooltip>
  );
};

//  checkbox
export const CheckBoxInCrateNote: React.FC<ICheckBoxInCrateNote> = ({ onCheckboxToggle }) => {
  const handleCheckBoxClick = () => {
    onCheckboxToggle();
  };

  return (
    <Tooltip title="New List">
      <Button type="link" icon={<PiCheckSquareBold size={24} color="#444444" />} onClick={handleCheckBoxClick} />
    </Tooltip>
  );
};

// Delete forever
export const DeleteForEver: React.FC<IFooterType> = ({ id, footerType, onConfirmButtonClick }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isDeleteForever, setIsDeleteForever] = useState(false);
  const { selectedNotes } = useSelector((state: RootState) => state.notes);

  const handleCheckBoxClick = () => {
    onConfirmButtonClick && onConfirmButtonClick();
    if (id && footerType !== NOTES_FOOTER_TYPE.bulkAction) {
      dispatch(deleteNote(id));
    }
    if (footerType === NOTES_FOOTER_TYPE.bulkAction) {
      dispatch(bulkDelete(getNoteIds(selectedNotes)));
    }
    setIsDeleteForever(false);
  };
  const confirmationModal = (e: any) => {
    e.stopPropagation();
    setIsDeleteForever(true);
  };

  return (
    <Tooltip title="Delete forever">
      <Button
        type="link"
        icon={<MdDeleteForever size={footerType === NOTES_FOOTER_TYPE.bulkAction ? 24 : 16} color="#444444" />}
        onClick={(e) => confirmationModal(e)}
      />
      <ConfirmationModal
        isVisible={isDeleteForever}
        onClose={() => setIsDeleteForever(false)}
        onConfirm={handleCheckBoxClick}
        message="Are you sure you want to delete this note?"
      />
    </Tooltip>
  );
};

// restore from bin
export const RestoreFromBin: React.FC<IFooterType> = ({ id, footerType }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { selectedNotes } = useSelector((state: RootState) => state.notes);
  const handleCheckBoxClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (id && footerType !== NOTES_FOOTER_TYPE.bulkAction) {
      dispatch(changeNoteStatus({ id, status: NOTES_STATUS.saved }));
    }
    if (footerType === NOTES_FOOTER_TYPE.bulkAction) {
      dispatch(bulkStatusChange({ ids: getNoteIds(selectedNotes), status: NOTES_STATUS.saved }));
    }
  };

  return (
    <Tooltip title="Restore notes">
      <Button
        type="link"
        icon={<MdRestoreFromTrash size={footerType === NOTES_FOOTER_TYPE.bulkAction ? 24 : 16} color="#444444" />}
        onClick={handleCheckBoxClick}
      />
    </Tooltip>
  );
};

export const ArchiveForBulk = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { selectedNotes } = useSelector((state: RootState) => state.notes);

  const handleArchiveClick = () => {
    dispatch(bulkStatusChange({ ids: getNoteIds(selectedNotes), status: NOTES_STATUS.archive }));
  };
  return (
    <Tooltip title="Archive Notes">
      <Button type="link" icon={<RiInboxArchiveLine size={24} color="#444444" />} onClick={handleArchiveClick} />
    </Tooltip>
  );
};

export const UnArchiveForBulk = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { selectedNotes } = useSelector((state: RootState) => state.notes);

  const handleArchiveClick = () => {
    dispatch(bulkStatusChange({ ids: getNoteIds(selectedNotes), status: NOTES_STATUS.saved }));
  };
  return (
    <Tooltip title="Archive Notes">
      <Button type="link" icon={<RiInboxUnarchiveLine size={24} color="#444444" />} onClick={handleArchiveClick} />
    </Tooltip>
  );
};

export const PinNotes = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { selectedNotes } = useSelector((state: RootState) => state.notes);

  const isPin = selectedNotes?.some((item: IMyNote) => item?.is_pinned === true);

  const handlePinClick = async () => {
    await dispatch(bulkPinNotes({ ids: getNoteIds(selectedNotes), value: !isPin }));
    await dispatch(fetchAllNotes());
  };

  return isPin ? (
    <Tooltip title="Unpin note">
      <Button type="link" icon={<RiPushpin2Fill size={24} color="#444444" />} onClick={handlePinClick} />
    </Tooltip>
  ) : (
    <Tooltip title="Pin note">
      <Button type="link" icon={<RiPushpin2Line size={24} color="#444444" />} onClick={handlePinClick} />
    </Tooltip>
  );
};
