import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LocalStorageService } from "./utils/LocalStorage";
import { AppDispatch } from "./store";
import { useDispatch } from "react-redux";
import { isLogin, setProfileData, setUserConfig, setUserData, UserInterface } from "./slices/userSlice";
import AxiosService from "./utils/APIService";
import AppRoutes from "./AppRoutes";
import { fetchTeamMemberList } from "./slices/commonDataSlice";
import { fetchReferenceList } from "./slices/contactSlice";
import axios from "axios";

const App = () => {
  const dispatch = useDispatch<AppDispatch>();
  const apiService = new AxiosService({
    baseURL: process.env.REACT_APP_AUTH_SERVICE,
  });
  const navigate = useNavigate();

  const token = LocalStorageService.getItem("token");
  const userData: UserInterface = LocalStorageService.getItem("user");

  useEffect(() => {

    if (token) {
      getLoggedinUserData();
    } else {
      clearUserData();
    }
  }, [token, userData]);

  const subscribeToPushNotifications = async () => {
    const publicVapidKey = "BABd0PEyfsOly2qP3PAFptZfgdxaT_gpzwx4_nzdQRFNKFW9fY2R1O9g6auBcT5DUNkUCS5pdd7D1GJnSDBN2I4";
    if (Notification.permission === "granted") {
      if ("serviceWorker" in navigator) {
        const register = await navigator.serviceWorker.register("/serviceWorker.js");

        const subscription = await register.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: publicVapidKey,
        });

        console.log("subscription ====>", subscription);
      }
    }
  };
  useEffect(() => {
    dispatch(fetchTeamMemberList());
    dispatch(fetchReferenceList());
  }, []);

  const getLoggedinUserData = () => {
    apiService
      .get("/me")
      .then((res: any) => {
        dispatch(isLogin(true));
        dispatch(setProfileData(res.profile));
        dispatch(setUserData(res.user));
        dispatch(setUserConfig(res.config));
      })
      .catch(() => handleLogout());
  };

  const clearUserData = () => {
    dispatch(isLogin(false));
    dispatch(setProfileData(null));
    dispatch(setUserData(null));
    dispatch(setUserConfig(null));
  };

  const handleLogout = () => {
    LocalStorageService.removeItem("token");
    LocalStorageService.removeItem("user");
    clearUserData();
    navigate("/login");
  };
  return <AppRoutes />;
};

export default App;
