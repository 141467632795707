import React, { useState } from "react";
import { Button, Flex, Input, Modal, Typography } from "antd";
import { MdAdd, MdCheck, MdClose, MdDelete, MdEdit, MdLabel } from "react-icons/md";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { createLabel, deleteLabel, updateLabel } from "../../slices/notesSlice";
import ConfirmationModal from "./ConfirmationModel";
import styleCss from "./style.module.scss";

interface EditLabelState {
  id: string | null;
  title: string;
}

interface INewLabel {
  isCreate: boolean;
  value?: string;
}

const EditLabel: React.FC<{ isVisible: boolean; onClose: () => void }> = ({ isVisible, onClose }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { allLabelList } = useSelector((state: RootState) => state.notes);
  const [newLabel, setNewLabel] = useState<INewLabel>({ isCreate: true, value: "" });
  const [editLabelState, setEditLabelState] = useState<EditLabelState>({ id: null, title: "" });
  const [isDelete, setIsDelete] = useState<{ isModelOpen: boolean; id: string | null }>({
    isModelOpen: false,
    id: null,
  });
  const [error, setError] = useState<string>("");

  const handleCreateLabel = () => {
    const trimmedValue = _.trim(newLabel.value);
    if (_.isEmpty(trimmedValue)) return;

    const isLabelExists = _.some(allLabelList, ({ title }) => _.isEqual(title, trimmedValue));
    if (isLabelExists) {
      setError("Label already exists.");
    } else {
      dispatch(createLabel(trimmedValue));
      setNewLabel({ isCreate: true, value: "" });
      setError("");
    }
  };

  const handleEditLabelChange = (id: string | null, title: string) => {
    setEditLabelState({ id, title: _.trim(title) });
  };

  const handleSaveEdit = () => {
    const { id, title } = editLabelState;
    if (!_.isNil(id) && !_.isEmpty(title)) {
      dispatch(updateLabel({ id, title }));
      setEditLabelState({ id: null, title: "" });
    }
  };

  return (
    <Modal
      open={isVisible}
      onCancel={onClose}
      footer={null}
      width={300}
      closable={false}
      className={styleCss.editLabel}
      centered
    >
      <div className="labelContent">
        <Typography>Edit labels</Typography>
        <Flex align="center" style={{ margin: "10px 0" }}>
          {newLabel.isCreate ? (
            <MdClose
              size={28}
              color="#4b4b4b"
              onClick={() => {
                setNewLabel({ isCreate: false, value: "" });
                setError("");
              }}
            />
          ) : (
            <MdAdd size={28} color="#4b4b4b" onClick={() => setNewLabel({ isCreate: true, value: "" })} />
          )}
          <div>
            <Input
              value={newLabel.value}
              placeholder="Create new label"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setNewLabel({ isCreate: true, value: e.target.value })
              }
              onPressEnter={handleCreateLabel}
              autoFocus
              variant="borderless"
            />
          </div>
          {newLabel.isCreate && (
            <MdCheck color="#4b4b4b" size={28} onClick={handleCreateLabel} style={{ marginLeft: "8px" }} />
          )}
        </Flex>
        {error && <Typography.Text className="error">{error}</Typography.Text>}
        {allLabelList.map((label) => (
          <Flex align="center" key={label._id} className="labelList">
            <MdLabel size={28} color="gray" />
            {editLabelState.id === label._id ? (
              <>
                <div>
                  <Input
                    value={editLabelState.title}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleEditLabelChange(label._id, e.target.value)
                    }
                    onPressEnter={handleSaveEdit}
                    autoFocus
                    variant="borderless"
                  />
                </div>
                <MdCheck size={28} onClick={handleSaveEdit} />
              </>
            ) : (
              <>
                <Typography.Text style={{ flex: 1 }}>{label.title}</Typography.Text>
                <MdEdit
                  color="gray"
                  size={28}
                  onClick={() => {
                    handleEditLabelChange(label._id, label.title);
                    setNewLabel({ isCreate: false, value: "" });
                  }}
                  style={{ marginRight: "2px" }}
                />
                <MdDelete
                  color="gray"
                  size={28}
                  onClick={() =>
                    setIsDelete({
                      isModelOpen: true,
                      id: label._id,
                    })
                  }
                />
              </>
            )}
          </Flex>
        ))}
      </div>
      <div className="labelFooter">
        <Flex justify="end">
          <Button onClick={onClose}>Done</Button>
        </Flex>
      </div>

      <ConfirmationModal
        isVisible={isDelete?.isModelOpen}
        onClose={() => setIsDelete({ isModelOpen: false, id: null })}
        onConfirm={() => {
          dispatch(deleteLabel(isDelete?.id));
          setIsDelete({ isModelOpen: false, id: null });
        }}
        message="We'll delete this label and remove it from all of your Keep notes. Your notes won’t be deleted."
      />
    </Modal>
  );
};

export default EditLabel;
