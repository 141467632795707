import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../utils/axiosInstance";
import { NOTES_FILTER_TYPE, NOTES_STATUS } from "../utils/const";
axiosInstance.defaults.baseURL = process.env.REACT_APP_NOTES_SERVICE;

export const fetchAllNotes = createAsyncThunk("notes/fetchAllNotes", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_NOTES_SERVICE}/notes/list`);
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const fetchBinNoteList = createAsyncThunk("notes/fetchBinNoteList", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_NOTES_SERVICE}/notes/list`, {
      params: { status: NOTES_STATUS.deleted },
    });
    return response.data.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message || "Failed to fetch bin note list");
  }
});

export const fetchArchiveNoteList = createAsyncThunk("notes/fetchArchiveNoteList", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_NOTES_SERVICE}/notes/list`, {
      params: { status: NOTES_STATUS.archive },
    });
    return response.data.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message || "Failed to fetch archive note list");
  }
});

export const addNote = createAsyncThunk("notes/addNote", async (newNote: any, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(`${process.env.REACT_APP_NOTES_SERVICE}/notes/create`, newNote);
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message || "Failed to add note");
  }
});

export const updateNote = createAsyncThunk("notes/updateNote", async ({ id, data }: any, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.put(`${process.env.REACT_APP_NOTES_SERVICE}/notes/update/${id}`, data);
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message || "Failed to update note");
  }
});

export const colorChange = createAsyncThunk("notes/colorChange", async ({ id, color }: any, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(`${process.env.REACT_APP_NOTES_SERVICE}/notes/color-change/${id}`, {
      color,
    });
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message || "Failed to update note");
  }
});

export const pinNote = createAsyncThunk("notes/pinNote", async ({ id, isPinned }: any, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(`${process.env.REACT_APP_NOTES_SERVICE}/notes/pinned/${id}`, {
      isPinned,
    });
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message || "Failed to update note");
  }
});

export const fetchAllLabel = createAsyncThunk("notes/fetchAllLabel", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_NOTES_SERVICE}/notes-label/list`);
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message || "Failed to update note");
  }
});

export const createLabel = createAsyncThunk("labels/createLabel", async (title: string, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(`${process.env.REACT_APP_NOTES_SERVICE}/notes-label/create`, { title });
    return response.data.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message || "Failed to create label");
  }
});

export const updateLabel = createAsyncThunk(
  "labels/updateLabel",
  async ({ id, title }: { id: string; title: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`${process.env.REACT_APP_NOTES_SERVICE}/notes-label/update/${id}`, {
        title,
      });
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message || "Failed to update label");
    }
  }
);

export const deleteLabel = createAsyncThunk("labels/deleteLabel", async (id: any, { rejectWithValue }) => {
  try {
    await axiosInstance.delete(`${process.env.REACT_APP_NOTES_SERVICE}/notes-label/delete/${id}`);
    return id;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message || "Failed to delete label");
  }
});

export const addLabelByNoteId = createAsyncThunk(
  "notes/addLabelByNoteId",
  async ({ noteId, labelIds }: { noteId: string; labelIds: string[] }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`${process.env.REACT_APP_NOTES_SERVICE}/notes/update-labels/${noteId}`, {
        labelIds,
      });
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message || "Failed to add labels to note");
    }
  }
);

export const updateReminderByNoteId = createAsyncThunk(
  "notes/updateReminderByNoteId",
  async ({ noteId, reminder }: { noteId: string; reminder: Date | null }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_NOTES_SERVICE}/notes/reminder-update/${noteId}`,
        { time: reminder }
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message || "Failed to update reminder");
    }
  }
);

export const uploadDocByNoteId = createAsyncThunk(
  "notes/uploadDocByNoteId",
  async ({ noteId, doc }: { noteId: string; doc: any }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `${process.env.REACT_APP_NOTES_SERVICE}/notes/file-upload/${noteId}`,
        doc
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message || "Failed to upload document");
    }
  }
);

export const updateShareList = createAsyncThunk(
  "notes/updateShareList",
  async ({ noteId, shareList }: { noteId: string; shareList: any }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_NOTES_SERVICE}/notes/share-list-update/${noteId}`,
        { list: shareList }
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message || "Failed to update share list");
    }
  }
);

export const deleteDocByNoteId = createAsyncThunk(
  "notes/deleteDocByNoteId",
  async ({ noteId, fileName }: { noteId: string; fileName: any }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`${process.env.REACT_APP_NOTES_SERVICE}/notes/file-remove/${noteId}`, {
        fileName,
      });
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message || "Failed to delete document");
    }
  }
);

export const changeNoteStatus = createAsyncThunk(
  "notes/changeNoteStatus",
  async ({ id, status }: { id: string; status: string }, { rejectWithValue }) => {
    try {
      await axiosInstance.put(`${process.env.REACT_APP_NOTES_SERVICE}/notes/status-change/${id}`, { status });
      return id;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message || "Failed to change note status");
    }
  }
);

export const deleteNote = createAsyncThunk("notes/deleteNote", async (id: string, { rejectWithValue }) => {
  try {
    await axiosInstance.delete(`${process.env.REACT_APP_NOTES_SERVICE}/notes/delete/${id}`);
    return id;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message || "Failed to delete note");
  }
});

export const copyNote = createAsyncThunk("notes/copyNote", async (id: string, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(`${process.env.REACT_APP_NOTES_SERVICE}/notes/clone-note/${id}`, {});
    return response.data.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message || "Failed to make a copy of the note");
  }
});

export const bulkLabelChange = createAsyncThunk(
  "notes/bulkLabelChange",
  async ({ ids, labels }: { ids: string[]; labels: any[] }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_NOTES_SERVICE}/notes/bulk-label`, {
        ids,
        labels,
      });
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message || "Failed to change labels in bulk");
    }
  }
);

export const bulkMakeCopy = createAsyncThunk("notes/bulkMakeCopy", async (ids: string[], { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(`${process.env.REACT_APP_NOTES_SERVICE}/notes/bulk-note-clone`, { ids });
    return response.data.clonedNotes;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message || "Failed to make copies in bulk");
  }
});

export const bulkStatusChange = createAsyncThunk(
  "notes/bulkStatusChange",
  async ({ ids, status }: { ids: any[]; status: string }, { rejectWithValue }) => {
    try {
      await axiosInstance.post(`${process.env.REACT_APP_NOTES_SERVICE}/notes/bulk-status-change`, { ids, status });
      return ids;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message || "Failed to change status in bulk");
    }
  }
);

export const bulkColorChange = createAsyncThunk(
  "notes/bulkColorChange",
  async ({ ids, color }: { ids: any[]; color: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_NOTES_SERVICE}/notes/bulk-color-change`, {
        ids,
        color,
      });
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message || "Failed to change color in bulk");
    }
  }
);

export const bulkReminder = createAsyncThunk(
  "notes/bulkReminder",
  async ({ ids, reminder }: { ids: any[]; reminder: Date | null }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_NOTES_SERVICE}/notes/bulk-reminder`, {
        ids,
        time: reminder,
      });
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message || "Failed to set reminders in bulk");
    }
  }
);

export const bulkPinNotes = createAsyncThunk(
  "notes/bulkPinNotes",
  async ({ ids, value }: { ids: any[]; value: boolean }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_NOTES_SERVICE}/notes/bulk-pin`, {
        ids,
        isPinned: value,
      });
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message || "Failed to pin notes in bulk");
    }
  }
);

export const bulkDelete = createAsyncThunk("notes/bulkDelete", async (ids: any[], { rejectWithValue }) => {
  try {
    await axiosInstance.post(`${process.env.REACT_APP_NOTES_SERVICE}/notes/all-delete`, { ids });
    return ids;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message || "Failed to empty the bin");
  }
});

export interface ILabels {
  created_on: any;
  title: string;
  user: string;
  __v: any;
  _id: string;
}

export interface IFilterLabel {
  _id: string;
  title: string;
  user: string;
  created_on: string;
  __v: number;
}

export interface IShareList {
  _id: string;
  full_name: string;
  email?: string;
}

export interface INoteContent {
  title?: string;
  checked: boolean;
  _id?: string;
}

export interface IEditedNotes {
  descriptionType: "checkbox" | "text";
  title?: string;
  checkList?: INoteContent[];
  description?: string | null;
}

export interface IFile {
  uid: string;
  name: string;
  status: string;
  url: string;
}
export interface ISelectedLabels {
  title: string;
  _id: string;
}

export interface INote {
  title?: string;
  reminder?: string;
  description?: string;
  bg_color: string;
  created_by: string;
  created_on: string;
  descriptionType: "text" | "checkbox";
  status: string;
  __v: number;
  _id: string;
  updated_on?: string;
  checkList?: INoteContent[];
  docs?: IImage[];
  share_to?: IShareList[];
}

export interface IMyNote {
  created_on: string;
  is_pinned: boolean;
  updated_on?: string;
  user: string;
  _id: string;
  note: INote;
  tag: ISelectedLabels[];
}

export interface IImage {
  fileName: string;
  fileType: string;
  isCover: boolean;
  localName: string;
  url: string;
  order?: number;
}

export interface IHistory {
  noteContent?: string | INoteContent[] | null;
  showCheckboxes?: boolean;
  noteTitle?: string;
  uploadFileList?: any;
}

interface NotesState {
  allLabelList: ILabels[];
  allNotesList: any[];
  binNoteList: any[];
  archiveNoteList: any[];
  selectedNotes: any[];
  activeFilterTab: string;
  loading: Boolean;
  noteLoading: any;
  error: string | null;
}

const initialState: NotesState = {
  allLabelList: [],
  allNotesList: [],
  binNoteList: [],
  archiveNoteList: [],
  selectedNotes: [],
  activeFilterTab: NOTES_FILTER_TYPE.notes,
  loading: false,
  noteLoading: {},
  error: null,
};

// Then, handle actions in your reducers:
const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    setSelectedNotes: (state, action) => {
      const clickedNote = action.payload;
      const isAlreadySelected = state.selectedNotes.some((note) => note._id === clickedNote._id);
      if (isAlreadySelected) {
        state.selectedNotes = state.selectedNotes.filter((note) => note._id !== clickedNote._id);
      } else {
        state.selectedNotes.push(clickedNote);
      }
    },
    resetSelectedNotes: (state) => {
      state.selectedNotes = [];
    },
    setActiveFilterTab: (state, action) => {
      state.activeFilterTab = action.payload;
    },
    removeBulkBinNoteList: (state) => {
      state.binNoteList = [];
    },
  },
  extraReducers: (builder) => {
    // Fetch all notes
    builder.addCase(fetchAllNotes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllNotes.fulfilled, (state, action) => {
      state.loading = false;
      state.allNotesList = action.payload;
    });
    builder.addCase(fetchAllNotes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    // Fetch Bin notes
    builder.addCase(fetchBinNoteList.fulfilled, (state, action) => {
      state.loading = false;
      state.binNoteList = action.payload;
    });
    // Fetch all notes
    builder.addCase(fetchArchiveNoteList.fulfilled, (state, action) => {
      state.loading = false;
      state.archiveNoteList = action.payload;
    });
    // Add note
    builder.addCase(addNote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addNote.fulfilled, (state, action) => {
      state.loading = false;
      state.allNotesList.push(action.payload);
    });
    builder.addCase(addNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    // Update note
    builder.addCase(updateNote.pending, (state, action) => {
      // state.noteLoading[action.meta.arg.id] = true;
    });
    builder.addCase(updateNote.fulfilled, (state, action) => {
      // state.noteLoading[action.meta.arg.id] = false;
      const index = state.allNotesList.findIndex((note) => {
        return note._id === action.payload._id;
      });
      if (index !== -1) {
        state.allNotesList[index] = action.payload;
      }
    });
    builder.addCase(updateNote.rejected, (state, action) => {
      // state.noteLoading[action.meta.arg.id] = false;
      state.error = action.payload as string;
    });

    // color changes
    builder.addCase(colorChange.fulfilled, (state, action) => {
      state.loading = false;
      const index = state.allNotesList.findIndex((note) => note._id === action.payload._id);
      if (index !== -1) {
        state.allNotesList[index] = action.payload;
      }
    });

    // Pin
    builder.addCase(pinNote.fulfilled, (state, action) => {
      state.loading = false;
      const index = state.allNotesList.findIndex((note) => {
        return note._id === action.payload._id;
      });
      if (index !== -1) {
        state.allNotesList[index] = action.payload;
      }
    });

    // Fetch all label
    builder.addCase(fetchAllLabel.fulfilled, (state, action) => {
      state.loading = false;
      state.allLabelList = action.payload;
    });

    //  create label
    builder.addCase(createLabel.fulfilled, (state, action) => {
      state.loading = false;
      state.allLabelList.push(action.payload);
    });

    //  update label
    builder.addCase(updateLabel.fulfilled, (state, action) => {
      state.loading = false;
      const index = state.allLabelList.findIndex((label) => label._id === action.payload._id);
      if (index !== -1) {
        state.allLabelList[index] = action.payload;
      }
    });

    // Delete label
    builder.addCase(deleteLabel.fulfilled, (state, action) => {
      state.loading = false;
      state.allLabelList = state.allLabelList.filter((label) => label._id !== action?.payload);
    });

    // add labels in note
    builder.addCase(addLabelByNoteId.fulfilled, (state, action) => {
      state.loading = false;
      const index = state.allNotesList.findIndex((note) => {
        return note._id === action.payload._id;
      });
      if (index !== -1) {
        state.allNotesList[index] = action.payload;
      }
    });

    // reminder update
    builder.addCase(updateReminderByNoteId.fulfilled, (state, action) => {
      state.loading = false;
      const index = state.allNotesList.findIndex((note) => {
        return note._id === action.payload._id;
      });
      if (index !== -1) {
        state.allNotesList[index] = action.payload;
      }
    });

    // uploadDoc By Note Id
    builder.addCase(uploadDocByNoteId.pending, (state, action) => {
      state.noteLoading[action.meta.arg.noteId] = true;
    });
    builder.addCase(uploadDocByNoteId.fulfilled, (state, action) => {
      state.noteLoading[action.meta.arg.noteId] = false;
      const index = state.allNotesList.findIndex((note) => {
        return note._id === action.payload._id;
      });
      if (index !== -1) {
        state.allNotesList[index] = action.payload;
      }
    });
    builder.addCase(uploadDocByNoteId.rejected, (state, action) => {
      state.noteLoading[action.meta.arg.noteId] = false;
    });

    // update Share List
    builder.addCase(updateShareList.pending, (state, action) => {
      state.noteLoading[action.meta.arg.noteId] = true;
    });
    builder.addCase(updateShareList.fulfilled, (state, action) => {
      state.noteLoading[action.meta.arg.noteId] = false;
      const index = state.allNotesList.findIndex((note) => {
        return note._id === action.payload._id;
      });
      if (index !== -1) {
        state.allNotesList[index] = action.payload;
      }
    });
    builder.addCase(updateShareList.rejected, (state, action) => {
      state.noteLoading[action.meta.arg.noteId] = false;
    });

    // delete doc
    builder.addCase(deleteDocByNoteId.pending, (state, action) => {
      // state.noteLoading[action.meta.arg.noteId] = true;
    });
    builder.addCase(deleteDocByNoteId.fulfilled, (state, action) => {
      // state.noteLoading[action.meta.arg.noteId] = false;
      const index = state.allNotesList.findIndex((note) => {
        return note._id === action.payload._id;
      });
      if (index !== -1) {
        state.allNotesList[index] = action.payload;
      }
    });
    builder.addCase(deleteDocByNoteId.rejected, (state, action) => {
      // state.noteLoading[action.meta.arg.noteId] = false;
    });

    //  change note status
    builder.addCase(changeNoteStatus.fulfilled, (state, action) => {
      state.loading = false;
      if (state.activeFilterTab === NOTES_FILTER_TYPE.notes || state.activeFilterTab === NOTES_FILTER_TYPE.reminders) {
        state.allNotesList = state.allNotesList.filter((item) => item.note._id !== action?.payload);
      }
      if (state.activeFilterTab === NOTES_FILTER_TYPE.bin) {
        state.binNoteList = state.binNoteList.filter((item) => item.note._id !== action?.payload);
      }
      if (state.activeFilterTab === NOTES_FILTER_TYPE.archive) {
        state.archiveNoteList = state.archiveNoteList.filter((item) => item.note._id !== action?.payload);
      }
    });

    // Delete note
    builder.addCase(deleteNote.fulfilled, (state, action) => {
      state.loading = false;
      state.binNoteList = state.binNoteList.filter((item) => item.note._id !== action?.payload);
    });

    // make copy note
    builder.addCase(copyNote.fulfilled, (state, action) => {
      state.loading = false;
      state.allNotesList.push(action.payload);
    });

    // bulk label change
    builder.addCase(bulkLabelChange.fulfilled, (state, action) => {
      state.loading = false;
      const apiData = action.payload;
      if (state.activeFilterTab === NOTES_FILTER_TYPE.notes || state.activeFilterTab === NOTES_FILTER_TYPE.reminders) {
        state.allNotesList = state.allNotesList.map((note) => {
          const matchedNote = apiData?.find((apiNote: any) => apiNote._id === note._id);
          return matchedNote ? matchedNote : note;
        });
      }
      if (state.activeFilterTab === NOTES_FILTER_TYPE.archive) {
        state.archiveNoteList = state.archiveNoteList.map((note) => {
          const matchedNote = apiData?.find((apiNote: any) => apiNote._id === note._id);
          return matchedNote ? matchedNote : note;
        });
      }
      // if (apiData) {
      //     state.selectedNotes = [];
      // }
    });

    // bulk make copy
    builder.addCase(bulkMakeCopy.pending, (state, action) => {
      action?.meta?.arg?.forEach((id: string) => {
        state.noteLoading[id] = true;
      });
    });
    builder.addCase(bulkMakeCopy.fulfilled, (state, action) => {
      action?.meta?.arg?.forEach((id: string) => {
        state.noteLoading[id] = false;
      });
      const apiData = action.payload;
      state.allNotesList = [...state.allNotesList, ...apiData];
      if (apiData) {
        state.selectedNotes = [];
      }
    });
    builder.addCase(bulkMakeCopy.rejected, (state, action) => {
      action?.meta?.arg?.forEach((id: string) => {
        state.noteLoading[id] = false;
      });
    });

    //  bulk change note status
    builder.addCase(bulkStatusChange.fulfilled, (state, action) => {
      state.loading = false;
      const apiData = action.payload;
      if (state.activeFilterTab === NOTES_FILTER_TYPE.notes || state.activeFilterTab === NOTES_FILTER_TYPE.reminders) {
        state.allNotesList = state.allNotesList.filter((item) => !apiData.includes(item?.note?._id));
      }
      if (state.activeFilterTab === NOTES_FILTER_TYPE.bin) {
        state.binNoteList = state.binNoteList.filter((item) => !apiData.includes(item?.note?._id));
      }
      if (state.activeFilterTab === NOTES_FILTER_TYPE.archive) {
        state.archiveNoteList = state.archiveNoteList.filter((item) => !apiData.includes(item?.note?._id));
      }
      if (apiData) {
        state.selectedNotes = [];
      }
    });

    // bulk color changes
    builder.addCase(bulkColorChange.fulfilled, (state) => {
      state.loading = false;
    });

    // bulk reminder
    builder.addCase(bulkReminder.fulfilled, (state) => {
      state.loading = false;
    });

    // bulk pin
    builder.addCase(bulkPinNotes.fulfilled, (state, action) => {
      state.loading = false;
      const apiData = action.payload;
      if (apiData) {
        state.selectedNotes = [];
      }
    });

    // bulk delete for bin only
    builder.addCase(bulkDelete.fulfilled, (state, action) => {
      state.loading = false;
      const apiData = action.payload;
      state.binNoteList = state.binNoteList.filter((item) => !apiData.includes(item?.note?._id));
      if (apiData) {
        state.selectedNotes = [];
      }
    });
  },
});

export const { setSelectedNotes, resetSelectedNotes, setActiveFilterTab, removeBulkBinNoteList } = notesSlice.actions;
export default notesSlice.reducer;
