import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Card, Form, message } from "antd";
import { InputBox } from "../../components/FormInput";
import AxiosService from "../../utils/APIService";
import styleCss from "../Login/style.module.scss";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const apiService = new AxiosService({
    baseURL: process.env.REACT_APP_AUTH_SERVICE,
  });
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    const { password, confirmPassword } = values;

    if (password !== confirmPassword) {
      message.error("Passwords do not match!");
      return;
    }

    if (!token) {
      message.error("Invalid or missing token!");
      return;
    }

    setLoading(true);
    apiService
      .post("/reset-password", { password: values.password, confirmPassword: values.confirmPassword, token })
      .then((res: any) => {
        navigate("/login");
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      className={styleCss.loginPage}
      style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card style={{ maxWidth: 500, width: "100%" }}>
        <Form name="ResetPassword" onFinish={onFinish} layout="vertical">
          <InputBox
            type="password"
            name="password"
            label="Password"
            rules={[{ required: true, message: "Please enter your password!" }]}
          />
          <InputBox
            type="password"
            name="confirmPassword"
            label="Confirm Password"
            rules={[{ required: true, message: "Please confirm your password!" }]}
          />

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ResetPassword;
