import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../utils/axiosInstance";
axiosInstance.defaults.baseURL = process.env.REACT_APP_TASK_SERVICE;

export const createInquiry = createAsyncThunk("task/create ", async (inquiryData: any, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(`${process.env.REACT_APP_TASK_SERVICE}/create`, inquiryData);
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const getInquiryById = createAsyncThunk(
  "task/fetchInquiryById ",
  async (inquiryId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_TASK_SERVICE}/inquiry/get/${inquiryId}`);
      return response?.data?.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Define the interface for the state
export interface IInquiryState {
  inquiry: any | null;
}

// Initial state
const initialState: IInquiryState = {
  inquiry: null,
};

const inquirySlice = createSlice({
  name: "inquiry",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createInquiry.fulfilled, (state, action) => {
      state.inquiry = action.payload;
    });
    builder.addCase(getInquiryById.fulfilled, (state, action) => {
      state.inquiry = action.payload;
    });
  },
});

export const {} = inquirySlice.actions;

export default inquirySlice.reducer;
