import { useState } from "react";
import { Button, Checkbox, Flex, List, Popover, Tag } from "antd";
import { INQUIRY_TYPE } from "../../../utils/const";
import { ITask } from "../../../slices/taskSlice";
import { EditOutlined } from "@ant-design/icons";
import Search from "antd/es/transfer/search";

interface IEditDrawerProps {
  taskData: ITask | null;
  selectedTypes: string[];
  handleListClick: (key: string) => void;
  handleTypeSubmit: () => void;
}
const EditDrawerHeader = ({ taskData, selectedTypes, handleListClick, handleTypeSubmit }: IEditDrawerProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [typeEditVisible, setTypeEditVisible] = useState(false);

  const filteredInquiryTypes = Object.entries(INQUIRY_TYPE)
    .filter(([_, value]) => {
      const title = (value as { title: string }).title.toLowerCase();
      return title.includes(searchQuery);
    })
    .map(([key, value]) => {
      const title = (value as { title: string }).title;
      return [key, title];
    });

  const handleTypeSearch = (e: any) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleTypeVisibleChange = (visible: boolean) => {
    setTypeEditVisible(visible);
  };

  const handleSubmit = () => {
    handleTypeSubmit();
    setTypeEditVisible(false);
  };

  const inquiryTypeContent = (
    <List>
      {filteredInquiryTypes?.map(([key, title]) => (
        <List.Item
          key={key}
          style={{
            backgroundColor: selectedTypes.includes(key) ? "#e0f6fe" : "transparent",
            padding: "5px 10px",
            margin: "1px",
            borderRadius: "6px",
            cursor: "pointer",
          }}
          onClick={() => handleListClick(key)}
        >
          <Checkbox id={key} checked={selectedTypes.includes(key)} style={{ marginRight: "10px" }}>
            <label style={{ cursor: "pointer" }}>{title}</label>
          </Checkbox>
        </List.Item>
      ))}
    </List>
  );

  return (
    <div>
      <div className={`drawer-header`}>
        <Flex align="center" justify="space-between">
          <Flex align={"center"}>
            <p>
              {taskData?.task_type.map((inquiry: string, index: number) => {
                const inquiryData = INQUIRY_TYPE[inquiry];
                const colors = ["orange", "purple", "green", "geekblue"];

                return (
                  <Tag color={colors[index % colors?.length]} key={index}>
                    {inquiryData?.title}
                  </Tag>
                );
              })}
            </p>
            <Popover
              overlayClassName="assignTo"
              content={inquiryTypeContent}
              title={
                <Flex justify="space-between" gap={10}>
                  <Search placeholder="Search" onChange={handleTypeSearch} />
                  <Button type="primary" onClick={handleSubmit}>
                    Save
                  </Button>
                </Flex>
              }
              open={typeEditVisible}
              onOpenChange={handleTypeVisibleChange}
              trigger="click"
            >
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                <EditOutlined style={{ color: "#535c6957" }} />
              </button>
            </Popover>
          </Flex>
        </Flex>
      </div>
    </div>
  );
};

export default EditDrawerHeader;
