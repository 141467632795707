import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox, List, Button, Flex, Popover } from "antd";
import Search from "antd/es/input/Search";
import { EditOutlined } from "@ant-design/icons";
import { RootState } from "../../store";
import styleCss from "./style.module.scss";

interface UserType {
  _id: string;
  full_name: string;
}

interface EditAssignProps {
  assigned?: UserType[];
  assignList: UserType[];
  onSubmit: (val: any) => void;
  children?: any;
  buttonTitle?: string;
  visibility?: boolean;
  setVisibility?: (val: boolean) => void;
}

export const UserInvite = ({
  assigned,
  assignList,
  onSubmit,
  children,
  buttonTitle,
  visibility,
  setVisibility,
}: EditAssignProps) => {
  const { user } = useSelector((state: RootState) => state.user);
  const [visible, setVisible] = useState(visibility || false);
  const [selectedNames, setSelectedNames] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [dataList, setDataList] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (assigned) {
      setSelectedNames(assigned.map((asn) => asn._id));
      setSelectAll(() => assignList.length === assigned.length);
    }
  }, [assigned]);
  useEffect(() => {
    if (user?.role === "AGENT_ADMIN") {
      setDataList([...assignList]);
    } else {
      setDataList(assignList);
    }
  }, [user, assignList]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredAssignList = dataList?.filter((user) =>
    user?.full_name?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
    if (setVisibility) {
      setVisibility(visible);
    }
    if (!visible) handleSubmit();
  };

  const handleSubmit = () => {
    const selectedIds = dataList.filter((user) => selectedNames.includes(user._id)).map((user) => user._id);

    const assigneesToSend = selectedIds.length > 0 ? selectedIds : [];
    setVisible(false);
    setVisibility && setVisibility(false);
    onSubmit(assigneesToSend);
  };

  const handleCheckboxChange = (checked: boolean, usr: UserType) => {
    setSelectedNames(checked ? [...selectedNames, usr._id] : selectedNames.filter((name) => name !== usr._id));
  };

  const title = (
    <Flex justify="space-between" gap={10}>
      <Search placeholder="Search" value={searchQuery} onChange={handleSearchChange} />
      <Button
        type="primary"
        onClick={(e) => {
          e.stopPropagation();
          handleSubmit();
        }}
      >
        {buttonTitle || "send"}
      </Button>
    </Flex>
  );

  const handleListClick = (usr: UserType) => {
    const updatedSelectedNames = [...selectedNames];
    const index = updatedSelectedNames.indexOf(usr._id);
    if (index !== -1) {
      updatedSelectedNames.splice(index, 1);
    } else {
      updatedSelectedNames.push(usr._id);
    }
    setSelectAll(() => filteredAssignList.length === updatedSelectedNames.length);
    setSelectedNames(updatedSelectedNames);
  };

  const selectAllHandle = (e: any) => {
    if (e.target.checked) {
      const allUserIds = filteredAssignList.map((user) => user._id);
      setSelectedNames(allUserIds);
    } else {
      setSelectedNames([]);
    }
    setSelectAll(e.target.checked);
  };

  const selectAllListClick = () => {
    if (!selectAll) {
      const allUserIds = filteredAssignList.map((user) => user._id);
      setSelectedNames(allUserIds);
    } else {
      setSelectedNames([]);
    }
    setSelectAll(!selectAll);
  };

  const assigneeList = (
    <List>
      <List.Item
        style={{
          backgroundColor: selectAll ? "#e0f6fe" : "transparent",
          padding: "5px 10px",
          margin: "1px",
          borderRadius: "6px",
          cursor: "pointer",
        }}
        onClick={selectAllListClick}
      >
        <Checkbox id={"select-all"} checked={selectAll} onChange={selectAllHandle} style={{ marginRight: "10px" }}>
          <label style={{ cursor: "pointer" }}>Select All</label>
        </Checkbox>
      </List.Item>
      {filteredAssignList?.map((usr, index) => (
        <List.Item
          key={index}
          style={{
            backgroundColor: selectedNames.includes(usr._id) ? "#e0f6fe" : "transparent",
            padding: "5px 10px",
            margin: "1px",
            borderRadius: "6px",
            cursor: "pointer",
          }}
          onClick={() => handleListClick(usr)}
        >
          <Checkbox
            id={usr._id}
            checked={selectedNames.includes(usr._id)}
            onChange={(e) => handleCheckboxChange(e.target.checked, usr)}
            style={{ marginRight: "10px" }}
          >
            <label style={{ cursor: "pointer" }}>{usr.full_name}</label>
          </Checkbox>
        </List.Item>
      ))}
    </List>
  );

  return (
    <Popover
      overlayClassName="assignTo"
      content={assigneeList}
      title={title}
      open={visible}
      onOpenChange={handleVisibleChange}
      trigger="click"
    >
      {children ? (
        children
      ) : (
        <button style={{ backgroundColor: "transparent", border: "none" }}>
          <EditOutlined className={styleCss.editIcon} style={{ color: "#535c6957" }} />
        </button>
      )}
    </Popover>
  );
};
