import { useEffect, useState } from "react";
import { Col, Flex, Form, Row, Select, Typography } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import Icon from "./Icon";
import { INQUIRY_TYPE } from "../../../utils/const";
import TaskStyleCss from "../createDrawer.module.scss";
import { IoIosArrowDown } from "react-icons/io";

const { Text } = Typography;

interface IDrawerHeader {
  form: any;
}

function CreateDrawerHeader({ form }: IDrawerHeader) {
  const [isTaskTypeOpen, setIsTaskTypeOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [previousSelectedOptions, setPreviousSelectedOptions] = useState<string[]>([]);
  const { taskDrawerOpen } = useSelector((state: RootState) => state.layout);

  useEffect(() => {
    if (taskDrawerOpen) {
      setSelectedOptions([]);
      setIsTaskTypeOpen(true);
    } else {
      setIsTaskTypeOpen(false);
    }
  }, [taskDrawerOpen]);

  const handleSelectChange = (value: string[]) => {
    if (value.includes("PERSONAL")) {
      if (!selectedOptions.includes("PERSONAL")) {
        setPreviousSelectedOptions(selectedOptions);
      }
      setSelectedOptions(["PERSONAL"]);
      form.setFieldsValue({ type: ["PERSONAL"] });
    } else {
      if (selectedOptions.includes("PERSONAL")) {
        setSelectedOptions(previousSelectedOptions);
        form.setFieldsValue({ type: previousSelectedOptions });
      } else {
        setSelectedOptions(value);
        form.setFieldsValue({ type: value });
      }
    }
  };

  return (
    <Row className={`drawer-header ${TaskStyleCss.drawerSelect}`} justify={"space-between"}>
      <Col span={21}>
        <Row>
          <Col span={3}>
            <Text className="drawer-title">New Task</Text>
          </Col>
          <Col span={19}>
            <Form.Item name="type" className="mb-0" required={true}>
              <Select
                placeholder={`Task Type`}
                style={{ minWidth: 350, width: "auto" }}
                options={Object.keys(INQUIRY_TYPE)?.map((ky) => ({
                  label: (
                    <Flex align="center">
                      <Icon name={ky} />
                      <span className="pl-2">{INQUIRY_TYPE[ky].title}</span>
                    </Flex>
                  ),
                  value: ky,
                }))}
                className="template-dropdown"
                mode="multiple"
                rootClassName="taskType"
                open={isTaskTypeOpen}
                onDropdownVisibleChange={(open) => setIsTaskTypeOpen(open)}
                onChange={handleSelectChange}
                value={selectedOptions}
                suffixIcon={<IoIosArrowDown size={18}/>}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col span={3} className="flex justify-end">
        <Select
          className="template-dropdown"
          placeholder="TASK TEMPLATES"
          style={{ width: 153 }}
          options={[
            { value: "jack", label: "Jack" },
            { value: "lucy", label: "Lucy" },
            { value: "Yiminghe", label: "yiminghe" },
          ]}
          suffixIcon={<IoIosArrowDown size={18}/>}
        />  
      </Col>
    </Row>
  );
}

export default CreateDrawerHeader;
