import { useState } from "react";
import { Tabs } from "antd";
import PassportDoc from "./PassportDoc";
import PancardDoc from "./PancardDoc";
// import AdharcardDoc from "./AdharcardDoc";
import OtherDoc from "./OtherDoc";

interface FormDocumentPropsInterface {
  form: any;
  formId: any;
}

const FormDocument = ({ form, formId }: FormDocumentPropsInterface) => {
  const [activeKey, setActiveKey] = useState("1");

  const onChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey);
  };

  // Define your tab items using the Ant Design structure
  const items = [
    {
      label: "Passport",
      key: "1",
      children: <PassportDoc form={form} formId={formId} />,
    },
    {
      label: "PAN Card",
      key: "2",
      children: <PancardDoc form={form} formId={formId} />,
    },
    // {
    //   label: "Adhar Card",
    //   key: "3",
    //   children: <AdharcardDoc form={form} formId={formId} />,
    // },
    {
      label: "Other Documents",
      key: "4",
      children: <OtherDoc form={form} formId={formId} />,
    },
  ];

  return (
    <Tabs
      activeKey={activeKey}
      onChange={onChange}
      type="card"
      size="middle"
      items={items}
      className="pr-5 pl-5 pb-5"
    />
  );
};

export default FormDocument;
