// NoteList
import { useEffect, useState } from "react";
import { Button, Flex, Skeleton, Tag, Typography } from "antd";
import { Masonry } from "@mui/lab";
import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import NoteCard from "./NoteCard";
import CreateNote from "./CreateNote";
import { IMyNote } from "../../slices/notesSlice";
import styleCss from "./style.module.scss";

const { Title } = Typography;

const NoteList = () => {
  const { allNotesList, allLabelList, loading } = useSelector((state: RootState) => state.notes);
  const [filteredNotesList, setFilteredNotesList] = useState(allNotesList || []);
  const [selectedLabelId, setSelectedLabelId] = useState<string | null>(null);

  // const breakpointColumnsObj = {
  //     default: 5,
  //     1400: 4,
  //     1300: 3,
  //     1100: 2,
  //     764: 1,
  // };

  useEffect(() => {
    const filteredNotes = selectedLabelId
      ? _.filter(allNotesList, (note) => _.some(_.get(note, "tag", []), { _id: selectedLabelId }))
      : allNotesList;

    setFilteredNotesList(filteredNotes);
  }, [selectedLabelId, allNotesList]);

  const handleTagClick = (tagId: string) => {
    setSelectedLabelId(selectedLabelId === tagId ? null : tagId);
  };

  const PinnedNote = _.filter(filteredNotesList, (note: IMyNote) => _.get(note, "is_pinned", false));

  const OtherNote = _.filter(filteredNotesList, (note: IMyNote) => !_.get(note, "is_pinned", false));
  // const heights = [150, 30, 90, 70, 90, 100, 150, 30, 50, 80];
  // const cardStyle = {
  //     width: "240px",
  // };

  return (
    <>
      <CreateNote />
      <div className={styleCss.noteGirdViewContainer}>
        <div className={styleCss.labelContainer}>
          {allLabelList?.length > 0 && (
            <Flex align="flex-start" gap={10}>
              <Title level={4} className={styleCss.labelTitle}>
                Labels
              </Title>
              <Flex gap={8} wrap className={styleCss.tag}>
                {allLabelList?.map(({ _id, title }) => (
                  <Tag
                    color={selectedLabelId === _id ? "#00000096" : "#00000057"}
                    key={_id}
                    bordered={false}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleTagClick(_id)}
                  >
                    {title}
                  </Tag>
                ))}
              </Flex>
              <Button className={styleCss.removeFilter} onClick={() => setSelectedLabelId("")}>
                Remove Filter
              </Button>
            </Flex>
          )}
        </div>
        {loading ? (
          <Flex wrap justify="start" gap={9}>
            {Array(8)
              .fill(0)
              .map((_, index) => {
                return (
                  <Skeleton
                    key={`pinned-skeleton-${index}`}
                    active
                    title={false}
                    paragraph={{ rows: 5 }}
                    style={{
                      height: "300px",
                      width: 240,
                      padding: "16px",
                      backgroundColor: "white",
                      borderRadius: "16px",
                    }}
                  />
                );
              })}
          </Flex>
        ) : (
          <div className={styleCss.filterTitle}>
            {!!PinnedNote?.length && (
              <>
                <Typography className="title" style={{ paddingTop: "0px" }}>
                  PINNED
                </Typography>

                <Masonry className={`${styleCss.myMasonryGrid}`}>
                  {PinnedNote?.map((note: IMyNote) => (
                    <NoteCard {...note} key={note?._id} />
                  ))}
                </Masonry>
              </>
            )}

            {!!PinnedNote.length && !!OtherNote?.length && (
              <>
                <Typography className="title" style={{ paddingTop: "20px" }}>
                  OTHERS
                </Typography>
              </>
            )}

            {/* <Masonry breakpointCols={breakpointColumnsObj} className={styleCss.myMasonryGrid} columnClassName={styleCss.myMasonryGridColumn}>
                    {OtherNote?.map((note: any) => (
                        <NoteCard {...note} key={note?.id} />
                    ))}
                </Masonry> */}

            <Masonry className={`${styleCss.myMasonryGrid}`}>
              {OtherNote?.map((note: IMyNote) => (
                <NoteCard {...note} key={note?._id} />
              ))}
            </Masonry>
          </div>
        )}
      </div>
    </>
  );
};

export default NoteList;
