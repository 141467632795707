import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentURL } from "../../../utils/AWSService";
import { AppDispatch, RootState } from "../../../store";
import {
  addPassenger,
  fetchSecStepPassengerList,
  ITask,
  removeTaskPassenger,
  resetPassengerList,
} from "../../../slices/taskSlice";
import {
  ContactDataInterface,
  ContactDocumentInterface,
  IReferenceInterface,
  setIsUploadCollapse,
} from "../../../slices/contactSlice";
import { openItem } from "../../../slices/minimizeSlice";
import { QUICK_MODEL_TYPE } from "../../../utils/const";
import { PlusOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Divider, Flex, Image, Select, Space, Table, Typography } from "antd";
import docIcon from "../../../assets/docIcon.svg";
import DataTable from "../../../components/DataTable";
import QuickPassengerModel from "./QuickPassengerModel";
import commonCss from "../../../css/commonStyle.module.scss";
import { IoIosArrowDown } from "react-icons/io";

interface ISecondStepProps {
  taskData: ITask;
}

const SecondStep = ({ taskData }: ISecondStepProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.user);
  const { referenceList } = useSelector((state: RootState) => state.contact);
  const { secondeStepPassengerList } = useSelector((state: RootState) => state.task);
  const [selectedPassenger, setSelectedPassenger] = useState<string | null>(null);
  const [isAddNewPassenger, setIsAddNewPassenger] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const selectedIds = secondeStepPassengerList.map((item: ContactDataInterface) => item._id);
  const filteredRefList = referenceList.filter((item: IReferenceInterface) => !selectedIds.includes(item._id));

  const handleAddPassenger = async () => {
    if (selectedPassenger) {
      await dispatch(addPassenger({ taskId: taskData._id, passengerId: selectedPassenger }));
      setSelectedPassenger(null);
    }
  };

  useEffect(() => {
    dispatch(fetchSecStepPassengerList(taskData?._id));

    return () => {
      dispatch(resetPassengerList());
    };
  }, [taskData?._id]);

  const getImageUrl = (passengerId: string, fileName: string) => {
    return getDocumentURL(`passenger_doc/${user?.tenant_id}/${passengerId}/${fileName}`) ?? false;
  };

  const contactOpenHandle = (value: IReferenceInterface) => {
    dispatch(
      openItem({
        _id: value._id,
        name: value.contact_name,
        type: "CONTACT",
      })
    );
  };

  const column: any[] = [
    Table.SELECTION_COLUMN,
    Table.EXPAND_COLUMN,
    {
      title: "Name",
      dataIndex: "contact_name",
      key: "name",
      render: (val: string, record: ContactDataInterface) => {
        const url = record?.profile_image && record?._id && getImageUrl(record?._id, record?.profile_image);
        return (
          <Space size={10}>
            {record?.profile_image ? (
              <Image
                src={`${url}`}
                preview={false}
                height={28}
                width={28}
                style={{ borderRadius: "50%", objectFit: "cover" }}
              />
            ) : (
              <Avatar size={28} icon={<UserOutlined />} />
            )}
            <span>{val}</span>
          </Space>
        );
      },
      onCell: (record: IReferenceInterface) => {
        return {
          onClick: () => {
            contactOpenHandle(record);
          },
        };
      },
    },
    {
      title: "Contact Number",
      dataIndex: "contact_number",
      key: "contactNumber",
      width: "200px",
      onCell: (record: IReferenceInterface) => {
        return {
          onClick: () => {
            contactOpenHandle(record);
          },
        };
      },
    },
    {
      title: "Document",
      dataIndex: "documents",
      key: "doc",
      render: (item: ContactDocumentInterface, record: IReferenceInterface) => {
        const { other_documents, pan_card, passport } = item || {};
        const { _id: key } = record;

        const onUpload = () => {
          contactOpenHandle(record);
          dispatch(setIsUploadCollapse(true));
        };

        return (
          <>
            {!passport?.front_image && !pan_card?.image && !other_documents?.length ? (
              <Typography.Link onClick={onUpload}>+ upload</Typography.Link>
            ) : (
              <Flex gap={8}>
                {passport?.front_image && Object?.keys(passport?.front_image).length > 0 && (
                  <Image.PreviewGroup>
                    <Image
                      src={`${getImageUrl(key, passport?.front_image?.file_name)}`}
                      height={26}
                      width={42}
                      style={{ objectFit: "cover" }}
                    />
                    {passport?.back_image && (
                      <Image
                        src={`${getImageUrl(key, passport?.back_image?.file_name)}`}
                        height={26}
                        width={42}
                        style={{ objectFit: "cover", display: "none" }}
                        rootClassName="passport-back-list"
                      />
                    )}
                  </Image.PreviewGroup>
                )}
                {pan_card?.image && (
                  <Image
                    src={`${getImageUrl(key, pan_card?.image?.file_name)}`}
                    preview={false}
                    onClick={() => handelPreview(key, pan_card?.image?.file_name || "")}
                    height={26}
                    width={42}
                    style={{ objectFit: "cover" }}
                  />
                )}
                {other_documents && other_documents.length >= 1 && <Image src={docIcon} preview={false} />}
              </Flex>
            )}
          </>
        );
      },
      width: "180px",
    },
  ];

  const handelPreview = (id: string, fileName: string) => {
    const url = getImageUrl(id, fileName);
    if (url) {
      window.open(url, "_blank");
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const onQuickModalClose = () => {
    setIsAddNewPassenger(false);
  };

  const handleQuickSave = async (createdPassId: string) => {
    await dispatch(addPassenger({ taskId: taskData._id, passengerId: createdPassId }));
  };

  const handleRemovePassenger = async () => {
    await dispatch(removeTaskPassenger({ taskId: taskData?._id, ids: selectedRowKeys as string[] }));
    setSelectedRowKeys([]);
  };

  return (
    <div>
      {/* Dropdown */}
      <Flex gap={15} align="center">
        <Select
          placeholder="Add passenger"
          allowClear
          style={{ width: 400 }}
          options={filteredRefList?.map((refDt: IReferenceInterface) => ({
            label: refDt.contact_name || refDt.full_name,
            value: refDt._id,
          }))}
          className="template-dropdown"
          rootClassName="taskType"
          value={selectedPassenger}
          onChange={(value) => setSelectedPassenger(value)}
          suffixIcon={<IoIosArrowDown size={18}/>}
          dropdownRender={(menu) => (
            <>
              <Button
                className={commonCss.addNew}
                type="text"
                icon={<PlusOutlined />}
                block
                onClick={() => setIsAddNewPassenger(true)}
              >
                Add New Passenger
              </Button>
              <Divider style={{ margin: "8px 8px", borderBlockStartColor: "#00000026" }} />
              {menu}
            </>
          )}
        />

        <Button className={commonCss.addNew} onClick={handleAddPassenger} disabled={!!selectedRowKeys.length}>
          Add Passenger
        </Button>

        {!!selectedRowKeys.length && (
          <Button className={commonCss.addNew} onClick={handleRemovePassenger}>
            Remove Passenger
          </Button>
        )}
      </Flex>
      <QuickPassengerModel
        isOpen={isAddNewPassenger}
        handleModelClose={onQuickModalClose}
        onSave={handleQuickSave}
        type={QUICK_MODEL_TYPE.secondStepPassenger}
      />

      {/* Passenger table */}
      <div style={{ marginTop: 15, cursor: "pointer" }}>
        <DataTable
          column={column}
          data={secondeStepPassengerList?.map((item: IReferenceInterface) => ({ ...item, key: item?._id }))}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default SecondStep;
