import { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Divider,
  Flex,
  Form,
  Image,
  Input,
  message,
  Row,
  Select,
  Upload,
  UploadProps,
} from "antd";
import { cities, country, getStateByCity, stateList } from "../../../utils/data";
import { useDispatch, useSelector } from "react-redux";
// import { setMobileNumber, setReferenceBy } from "../../../slices/vendorSlice";
import { AppDispatch, RootState } from "../../../store";
import { InputBox, InputSelect } from "../../../components/FormInput";
import ContactInput from "../../../components/FormInput/contactInput";
import pencilSVG from "../../../assets/pencil.svg";
import personSVG from "../../../assets/personAvtar.svg";
import FormCss from "./form.module.scss";
import _ from "lodash";
import ContactPerson from "./ContactPerson";
import VisitingCard from "./VisitingCard";
import Typography from "antd/es/typography/Typography";
import ContactPersonTable from "./ContactPersonTable";
import { UploadOutlined } from "@ant-design/icons";
import { MdOutlineFileUpload } from "react-icons/md";
import { IoIosArrowDown, IoMdMore } from "react-icons/io";
import ContactPersonCard from "./ContactPersonCard";
import ContactPersonPopover from "./ContactPersonPopover";

const state = stateList();

interface ContactFormPropsInterface {
  form: any;
}

const VendorForm = ({ form }: ContactFormPropsInterface) => {
  const dispatch = useDispatch<AppDispatch>();
  const { allVendorList, isUploadCollapse } = useSelector((state: RootState) => state.vendor);
  const [previewImage, setPreviewImage] = useState<string | undefined>(personSVG);
  const [nameUpdated, setNameUpdated] = useState(false);
  const [gstTreatment, setGstTreatment] = useState<string | null>(null);
  const scrollToElementRef = useRef<HTMLDivElement>(null);

  const GstTreatmentOptions = [
    {
      label: "Registered Business - Regular",
      subLabel: "Businesses that is registered under GST",
      value: "Registered Business - Regular",
    },
    {
      label: "Registered Business - Composition",
      subLabel: "Businesses that is registered under the composition Schema in GST",
      value: "Registered Business - Composition",
    },
    {
      label: "Unregistered Business",
      subLabel: "Businesses that has not been registered under GST",
      value: "Unregistered Business",
    },
    {
      label: "Overseas",
      subLabel: "Persons with whom you do import or export of supplies outside India",
      value: "Overseas",
    },
  ];

  const taxOptions = [
    { label: "Not Applicable", value: "0" },
    { label: "Commission [3.80%]", value: "3.80%" },
    { label: "Commission [5%]", value: "5%" },
  ];

  const currency = [
    { label: "AED- UAE Dirham", value: "AED" },
    { label: "AUD- Australian Dollar", value: "AUD" },
    { label: "BND- Brunei Dollar", value: "BND" },
    { label: "CAD- Canadian Dollar", value: "CAD" },
    { label: "INR- Indian Rupee", value: "INR" },
  ];

  const balance = [
    { label: "Credit", value: "credit" },
    { label: "Debit", value: "debit" },
  ];

  const paymentTerms = [
    { label: "Net 15", value: "net15" },
    { label: "Net 30", value: "net30" },
    { label: "Net 45", value: "net45" },
    { label: "Net 60", value: "net60" },
    { label: "Due On Receipt", value: "dueToReceipt" },
    { label: "Due end of the month", value: "dueEndOfMonth" },
    { label: "Due end of next month", value: "dueEndOfNextMonth" },
  ];

  const handleGstTreatmentChange = (value: string) => {
    setGstTreatment(value);
    form.setFieldValue("gst_treatment", value);
  };

  const updateField = (fieldName: string, value: string | number | boolean | Date | null) => {
    form.setFieldValue(fieldName, value);
  };

  const handleAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "") {
      setNameUpdated(false);
    }
    updateField("contact_name", value);
    if (!nameUpdated) {
      updateField("full_name", value);
    }
  };

  const handleFullNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    updateField("full_name", value);

    if (!form.getFieldValue("contact_name")) {
      updateField("contact_name", value);
    }
    setNameUpdated(true);
  };

  const selectStateBaseOnCity = (val: string) => {
    const stateValue = getStateByCity(val)?.trim();
    form.setFieldValue("state", stateValue ? stateValue : null);
  };

  // useEffect(() => {
  //   const imageUrl = profileImage?.images?.[0]?.thumbUrl || profileImage?.url;
  //   setPreviewImage(imageUrl || personSVG);
  // }, [profileImage]);

  useEffect(() => {
    form.setFieldValue("country", "IN");
    // const imageUrl = profileImage?.url;
    // if (imageUrl) {
    //   setPreviewImage(imageUrl);
    // }

    const fullName = form.getFieldValue("full_name");
    if (fullName) {
      setNameUpdated(true);
    }

    // let referenceByValue = form.getFieldValue("reference_by");
    // if (referenceBy && (!referenceByValue || _.trim(referenceByValue) === "")) {
    //   form.setFieldValue("reference_by", referenceBy);
    // } else if (_.trim(referenceByValue) === "") {
    //   form.setFieldValue("reference_by", null);
    // }

    // const contactNumber = form.getFieldValue("contact_number");
    // if (mobileNumber && !contactNumber) {
    //   form.setFieldValue("contact_number", mobileNumber);
    // }
    // }, [form, profileImage, referenceBy, mobileNumber]);
  }, [form]);

  useEffect(() => {
    if (isUploadCollapse && scrollToElementRef.current) {
      scrollToElementRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [isUploadCollapse]);

  const getBase64 = (file: File, callback: (imageUrl: string) => void) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result as string);
  };

  const handleChange: UploadProps["onChange"] = ({ fileList }) => {
    const latestFileList = fileList.slice(-1);

    if (latestFileList.length > 0) {
      const latestFile = latestFileList[0].originFileObj as File;
      if (latestFile && latestFile.size <= 2 * 1024 * 1024 && /\.(jpe?g|png)$/i.test(latestFile.name)) {
        getBase64(latestFile, (imageUrl: string) => {
          setPreviewImage(imageUrl);
        });
      } else {
        setPreviewImage(personSVG);
        console.log("error");
      }
    } else {
      setPreviewImage(personSVG);
    }
  };

  const validateGST = (_rule: any, value: any) => {
    if (!value) {
      return Promise.resolve();
    }
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (gstRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Invalid GST number");
  };

  const validatePAN = (_rule: any, value: any) => {
    if (!value) {
      return Promise.resolve();
    }
    const gstRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (gstRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Invalid PAN number");
  };

  const props: UploadProps = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const { Option } = Select;

  return (
    <div className={FormCss.FormComponent}>
      <div className="pt-5 pr-5 pl-5">
        <Row gutter={[4, 4]} align={"middle"}>
          <Col span={6}>
            <Typography className="titleMendetory">Vendor A/C Name*</Typography>
          </Col>
          <Col span={16}>
            <InputBox
              className="input-field"
              name={"account_name"}
              placeholder="Enter Account name"
              onChange={handleAccountNameChange}
              rules={[{ required: true }]}
              style={{ width: "80%" }}
            />
          </Col>
          <Col span={6}>
            <Typography className="titleField">Alias</Typography>
          </Col>
          <Col span={16}>
            <InputBox
              className="input-field"
              name={"nick_name"}
              placeholder="Enter Nickname"
              onChange={handleAccountNameChange}
              rules={[{ required: true }]}
              style={{ width: "80%" }}
            />
          </Col>
          {/* <Col span={1}>
            <IoMdMore size={24} color="#d9d9d9"/>
          </Col> */}
          <Col span={6}>
            <Typography className="titleMendetory">GST Treatment*</Typography>
          </Col>
          <Col span={16}>
            <Form.Item
              name={"gst_treatment"}
              rules={[
                {
                  required: true,
                  message: "Please enter GST Treatment!",
                },
              ]}
              style={{ width: "80%" }}
            >
              <Select
                size="large"
                placeholder="Select a GST treatment"
                optionLabelProp="label"
                onChange={handleGstTreatmentChange}
                suffixIcon={<IoIosArrowDown size={16} />}
                dropdownStyle={{ borderRadius: "4px" }}
              >
                {GstTreatmentOptions.map((option, index) => (
                  <Option key={index} value={option.label} title={option.label} name={"gst_treatment"}>
                    <div>
                      <Typography style={{ fontWeight: "600", lineHeight: "normal" }}>{option.label}</Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#a3a9b1",
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                        }}
                      >
                        {option.subLabel}
                      </Typography>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Typography className="titleMendetory">GST No*</Typography>
          </Col>
          <Col span={16}>
            <InputBox
              className="input-field"
              name={"gst_number"}
              placeholder="Enter GST number"
              rules={[
                {
                  required: true,
                  message: "Please enter GST number!",
                },
                {
                  validator: validateGST,
                },
              ]}
              style={{ width: "65%" }}
            />
          </Col>
          <Col span={6}>
            <Typography className="titleMendetory">PAN*</Typography>
          </Col>
          <Col span={16}>
            <InputBox
              className="input-field"
              name={"pan_number"}
              placeholder="Enter PAN number"
              rules={[
                {
                  required: true,
                  message: "Please enter PAN number!",
                },
                {
                  validator: validatePAN,
                },
              ]}
              style={{ width: "65%" }}
            />
          </Col>
          <Col span={6}>
            <Typography className="titleField">TDS</Typography>
          </Col>
          <Col span={16}>
            <InputSelect
              name={"tds"}
              options={taxOptions}
              allowClear
              placeholder="Select a Tax"
              // onChangeHandle={(value) => dispatch(setReferenceBy(value))}
              style={{ width: "80%" }}
            />
          </Col>
          <Col span={6}>
            <Typography className="titleField">Address</Typography>
          </Col>

          <Col span={16}>
            <InputBox
              className="input-field"
              name={"office_address"}
              placeholder="Enter address"
              style={{ width: "80%" }}
            />
          </Col>
          <Col span={6} className="titleField">
            City & Pincode
          </Col>
          <Col span={16}>
            <Row style={{ width: "80%" }} gutter={4}>
              <Col span={12}>
                <InputSelect
                  name={"city"}
                  options={
                    cities.map((ct) => ({
                      label: ct.name,
                      value: ct.name,
                    })) as { label: string; value: string }[]
                  }
                  placeholder="Select City"
                  showSearch
                  onChangeHandle={selectStateBaseOnCity}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={12}>
                <InputBox
                  className="input-field"
                  name={"pincode"}
                  placeholder="Enter Pin Code"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Typography className="titleMendetory">Source of Supply*</Typography>
          </Col>
          <Col span={16}>
            <InputBox
              className="input-field"
              name={"source_supply"}
              placeholder="Enter Source of Supply"
              // onChange={handleContactNameChange}
              rules={[{ required: true }]}
              style={{ width: "80%" }}
            />
          </Col>
          {/* <Col span={6}>
            <Typography className="titleField">Currency</Typography>
          </Col>
          <Col span={16}>
            <InputSelect
              name={"currency"}
              options={currency}
              allowClear
              placeholder="Select a Currency"
              showSearch
              // onChangeHandle={(value) => dispatch(setReferenceBy(value))}
            />
          </Col> */}
          {/* <Col span={6}>
            <Typography className="titleMendetory">Opening Balance</Typography>
          </Col>
          <Col span={16}>
            <Row gutter={4}>
              <Col span={16}>
                <InputBox
                  style={{ width: "100%" }}
                  className="input-field"
                  name={"balance"}
                  placeholder="Enter Balance"
                />
              </Col>
              <Col span={8}>
                <InputSelect
                  name={"balance_type"}
                  options={balance}
                  placeholder="Select a type"
                  allowClear
                  // onChangeHandle={(value) => dispatch(setReferenceBy(value))}
                />
              </Col>
            </Row>
          </Col> */}

          <Col span={6}>
            <Typography className="titleBottom">Opening Balance</Typography>
          </Col>
          <Col span={16}>
            <Input
              size="large"
              addonBefore={
                <InputSelect
                  name={"balance_type"}
                  options={balance}
                  placeholder="Select a type"
                  allowClear
                  style={{ width: 100 }}
                  // onChangeHandle={(value) => dispatch(setReferenceBy(value))}
                />
              }
              addonAfter={
                <InputSelect
                  name={"currency"}
                  options={currency}
                  style={{ width: 150 }}
                  allowClear
                  placeholder="Select a Currency"
                  showSearch
                  // onChangeHandle={(value) => dispatch(setReferenceBy(value))}
                />
              }
              placeholder="Enter Balance"
              name="balance"
            />
          </Col>

          <Col span={6}>
            <Typography className="titleField">Payment Terms</Typography>
          </Col>
          <Col span={16}>
            <InputSelect
              name={"payment_terms"}
              options={paymentTerms}
              placeholder="Select a Terms"
              allowClear
              style={{ width: "80%" }}
              // onChangeHandle={(value) => dispatch(setReferenceBy(value))}
            />
          </Col>

          <Col span={6}>
            <Typography className="titleField">Contact Person</Typography>
          </Col>
          <Col span={16}>
            {/* <ContactPersonTable /> */}
            {/* <ContactPersonCard /> */}
            <ContactPersonPopover />
          </Col>
          {/* <Col span={6}>
            <Typography className="titleField">Bank Details</Typography>
          </Col>
          <Col span={16}>
            <Row gutter={4}>
              <Col span={8} style={{ marginBottom: "5px" }}>
                <InputBox className="input-field" name={"acc_name"} placeholder="A/c Name" />
              </Col>
              <Col span={8} style={{ marginBottom: "5px" }}>
                <InputBox
                  style={{ width: "100%" }}
                  className="input-field"
                  name={"acc_number"}
                  placeholder="A/c Number"
                />
              </Col>
              <Col span={8} style={{ marginBottom: "5px" }}>
                <InputBox className="input-field" name={"ifsc"} placeholder="IFSC" />
              </Col>
            </Row>
          </Col> */}
        </Row>
        {/* <Col span={24}>
            <Button type="link" onClick={() => setShowBusinessAddress(!showBusinessAddress)} style={{ color: "black" }}>
              {showBusinessAddress ? "More less.." : "More"}
            </Button>
          </Col> */}
      </div>
      {/* <Divider
        style={{
          borderBlockStart: "1px solid rgba(219, 223, 233, 1)",
          margin: 0,
        }}
      /> */}

      {/* <ContactPerson form={form} /> */}
      <VisitingCard form={form} />
    </div>
  );
};

export default VendorForm;
