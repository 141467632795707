import { useEffect, useRef, useState } from "react";
import { Cropper } from "react-mobile-cropper";
import { Button, Modal, Upload } from "antd";
import "react-mobile-cropper/dist/style.css";
import "react-advanced-cropper/dist/style.css";

function FileUpload({ setFile, fileData, placeholder, onChange, onRemove, itemRender }: any) {
  const cropperRef = useRef<any>(null);
  // const [image, setImage] = useState<string | undefined>("");
  const [fileList, setFileList] = useState<any[]>([]);
  const [src, setSrc] = useState<string | undefined>("");
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fileData && setFileList(fileData);
  }, [fileData]);

  const onCrop = () => {
    if (cropperRef.current) {
      // setImage(cropperRef.current.getCanvas()?.toDataURL());

      setModalVisible(false);
      setFileList([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: cropperRef.current.getCanvas()?.toDataURL(),
        },
      ]);
      setFile([
        {
          ...fileList[0],
          thumbUrl: cropperRef.current.getCanvas()?.toDataURL(),
          originFileObj: dataURLtoFile(cropperRef.current.getCanvas()?.toDataURL(), "profile.png"),
        },
      ]);
      if (onChange) {
        onChange(dataURLtoFile(cropperRef.current.getCanvas()?.toDataURL(), "profile.png"));
      }
      setSrc("");
    }
  };

  const dataURLtoFile = (dataurl: any, filename: any) => {
    setLoading(true);
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    setLoading(false);
    return new File([u8arr], filename, { type: mime });
  };

  const handleUploadChange = (info: any) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    setFileList(fileList);
    if (fileList.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(fileList[0].originFileObj);
      reader.onloadend = () => {
        const base64data = reader.result;
        setSrc(base64data as string);
        setModalVisible(true);
      };
    }
  };

  const onImgRemove = () => {
    if (onRemove) {
      onRemove();
    }
    setFile([]);
  };

  return (
    <>
      <Upload
        onChange={handleUploadChange}
        listType="picture-card"
        fileList={fileList}
        beforeUpload={() => false}
        className="form-upload"
        onPreview={() => false}
        onRemove={onImgRemove}
        accept=".png, .jpeg, .jpg"
        itemRender={itemRender}
      >
        {fileList.length < 1 && placeholder}
      </Upload>
      <Modal
        title="Crop Image"
        visible={modalVisible}
        closable={false}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setModalVisible(false);
              setSrc("");
            }}
          >
            Cancel
          </Button>,
          <Button loading={loading} key="crop" type="primary" onClick={onCrop}>
            Crop
          </Button>,
        ]}
      >
        <div style={{ maxWidth: "500px" }}>
          <Cropper src={src} ref={cropperRef} />
        </div>
      </Modal>
    </>
  );
}

export default FileUpload;
