import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../utils/axiosInstance";

export const addVendor = createAsyncThunk("vendor/create", async (newPassenger: any, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(`${process.env.REACT_APP_VEND0R_SERVICE}/create`, newPassenger);
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const fetchVendorList = createAsyncThunk("vendor/list", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_VEND0R_SERVICE}/list`);
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const getVendorDetails = createAsyncThunk("vendor/get", async (id: string, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_VEND0R_SERVICE}/get/${id}`);
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const updateVendorList = createAsyncThunk(
  "vendor/update",
  async ({ id, updatedData }: { id: string; updatedData: any }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`${process.env.REACT_APP_VEND0R_SERVICE}/update/${id}`, updatedData);
      return response?.data?.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export interface DocumentInterface {
  doc_type: string;
  file_name: string;
  url?: string;
  exp_date?: string;
  _id?: string;
  doc_name?: string;
  upload_date?: string;
}
export interface VendorDataInterface {
  _id?: string;
  profile_image?: string;
  contact_name?: string;
  company_name?: string;
  full_name?: string;
  gst_number: string;
  gst_treatment: string;
  email?: string;
  website?: string;
  reference_by?: string;
  address?: {
    city?: string;
    country?: string;
    pincode?: string;
    office_address?: string;
    residents_address?: string;
    state?: string;
  };
  members?: any[];
  documents?: DocumentInterface[];
}
// interface IPersonImage {
//   [key: string]: any;
// }
// interface IFormData {
//   [formId: number]: any;
// }
export interface IReferenceInterface {
  _id: string;
  full_name: string;
  contact_name: string;
  contact_number: string;
  documents?: DocumentInterface[];
  key?: string;
  profile_image?: string;
}
export interface FormField {
  name: [string, string];
  label: string;
  type: string;
  placeholder?: string;
  options?: { label: string; value: string }[];
}

export interface HistoryItem {
  id: string;
  name: string;
  uploadDate: string;
  url?: string;
  file: File | null;
  docType: string;
}

export interface IContact {
  id?: string;
  contact_name?: string;
  full_name?: string;
  contact_number?: string;
  email?: string;
  role?: string;
  contactName?: string;
  name?: string;
  fullName?: string;
  contactNumber?: string;
  mobile?: string;
  contactEmail?: string;
}

interface IContactState {
  // profileImage: IPersonImage;
  // passPortImage: any;
  // passPortHistory: any;
  // panCardImage: any;
  // formData: IFormData;
  // mobileNumber: string | null;
  // referenceBy: string | null;
  otherDocHistory: any[];
  loading: any;
  error: string | null;
  allVendorList: VendorDataInterface[];
  referenceList: IReferenceInterface[];
  vendorData: VendorDataInterface | null;
  isUploadCollapse: boolean;
  contactPerson: IContact[];
}

const initialState: IContactState = {
  // profileImage: {},
  // passPortImage: {},
  // passPortHistory: {},
  // panCardImage: {},
  otherDocHistory: [],
  // formData: {},
  // mobileNumber: null,
  // referenceBy: null,
  loading: false,
  error: null,
  allVendorList: [],
  referenceList: [],
  vendorData: null,
  isUploadCollapse: false,
  contactPerson: [],
};

const vendorSlice = createSlice({
  name: "Vendor",
  initialState,
  reducers: {
    setContactPerson: (state, action: PayloadAction<IContact[]>) => {
      state.contactPerson = action.payload;
    },
    resetContactPerson: (state) => {
      state.contactPerson = [];
    },
    setOtherDocHistory: (state, action: PayloadAction<{ history: HistoryItem[] }>) => {
      const { history } = action.payload;
      state.otherDocHistory = Array.isArray(history) ? history : [];
    },
    setInitDocHistory: (state, action: PayloadAction<{ data: any }>) => {
      const { data } = action.payload;
      state.otherDocHistory = data;
    },
    deleteOtherDoc: (state, action: PayloadAction<{ itemId: string }>) => {
      const { itemId } = action.payload;
      if (state.otherDocHistory) {
        state.otherDocHistory = state.otherDocHistory.filter((item: any) => item.id !== itemId);
      }
    },
    // setFormData: (state, action: PayloadAction<any>) => {
    //   state.formData = action.payload;
    // },
    // setMobileNumber: (state, action: PayloadAction<string>) => {
    //   state.mobileNumber = action.payload;
    // },
    // setReferenceBy: (state, action: PayloadAction<string>) => {
    //   state.referenceBy = action.payload;
    // },
    setIsUploadCollapse: (state, action: PayloadAction<boolean>) => {
      state.isUploadCollapse = action.payload;
    },
    resetAllFormState: (state) => {
      // state.profileImage = {};
      state.otherDocHistory = [];
      state.contactPerson = []
      // state.formData = {};
      // state.mobileNumber = null;
      // state.referenceBy = null;
      state.vendorData = null;
      state.isUploadCollapse = false;
    },
  },
  extraReducers: (builder) => {
    // create vendor list
    builder.addCase(addVendor.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addVendor.fulfilled, (state, action) => {
      state.loading = false;
      state.allVendorList.push(action.payload);
    });
    builder.addCase(addVendor.rejected, (state, action) => {
      state.loading = false;
      const errData: any = action.payload;
      state.error = errData.message as string;
    });
    builder.addCase(fetchVendorList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchVendorList.fulfilled, (state, action) => {
      state.loading = false;
      state.allVendorList = action.payload;
    });
    builder.addCase(fetchVendorList.rejected, (state, action) => {
      state.loading = false;
      state.allVendorList = [];
    });
    builder.addCase(getVendorDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.vendorData = action.payload;
    });

    builder.addCase(updateVendorList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateVendorList.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateVendorList.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  setContactPerson,
  resetContactPerson,
  setOtherDocHistory,
  deleteOtherDoc,
  // setFormData,
  resetAllFormState,
  // setMobileNumber,
  // setReferenceBy,
  setIsUploadCollapse,
  setInitDocHistory,
} = vendorSlice.actions;
export default vendorSlice.reducer;
