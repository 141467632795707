import { useEffect, useState } from "react";
import { Badge, Button, Form, TabsProps } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import webSocketService from "../../utils/websocketService";
import AxiosService from "../../utils/APIService";
import { DRAWER_TYPE, TASK_STATUS } from "../../utils/const";
import SearchHeader from "../../components/SearchHeader";
import TabLayout from "./CommonComponent/Header/TaskTab";
import TaskNav from "./CommonComponent/Header/TaskNav";
import CreateTask from "./Task/CreateTask/CreateTask";
import Task from "./Task/Task";
import WishList from "./Task/WatchList";
import CompetedList from "./Task/CompetedList";
import DeleteRequest from "./Task/DeleteRequest";
import DeletedList from "./Task/DeletedList";
import { setDrawerOpen } from "../../slices/layoutSlice";
import {
  fetchCompletedLists,
  fetchDeletedLists,
  fetchDeleteRequestList,
  fetchPendingTasks,
  fetchWatchList,
  ITaskList,
  IUser,
} from "../../slices/taskSlice";
import { fetchReferenceList } from "../../slices/contactSlice";
import Taskcss from "./style.module.scss";
import commonCSS from "../../css/commonStyle.module.scss";

interface IFilter {
  is_personal_task: boolean;
}

function TaskInquiry() {
  const { user } = useSelector((state: RootState) => state.user);
  const { pendingTasks, watchList, completedList, deleteRequestList, deletedList } = useSelector(
    (state: RootState) => state.task
  );
  const apiService = new AxiosService({
    baseURL: process.env.REACT_APP_TASK_SERVICE,
  });
  const [taskList, setTaskList] = useState<any>();
  const [taskTab, setTaskTab] = useState(() => localStorage.getItem("taskTab") || "2");
  const [form] = Form.useForm();
  const [filter, setFilter] = useState<IFilter>({ is_personal_task: false });
  const [searchText, setSearchText] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();

  const getListData = () => {
    getPendingTask();
    getWatchList();
    getDeleteRequestList();
    getCompletedList();
    getDeletedList();
  };

  useEffect(() => {
    getTaskList();
    getListData();
    dispatch(fetchReferenceList());
  }, []);

  useEffect(() => {
    if (user) {
      webSocketService.connect(process.env.REACT_APP_TASK_SOCKET as string);
      webSocketService.sendMessage("register", user?._id);

      webSocketService.onMessage("taskPositionUpdate", (val) => {
        if (val.tenantId === user?.tenant_id) {
          setTimeout(() => {
            getTaskList();
          }, 200);
          getListData();
        }
      });
    }
    return () => webSocketService.disconnect();
  }, [user]);

  useEffect(() => {
    if (filter) {
      const timeoutIds = [setTimeout(getDeleteRequestList, 100), setTimeout(getDeletedList, 100)];
      return () => timeoutIds.forEach(clearTimeout);
    }
  }, [filter]);

  useEffect(() => {
    if (filter && user) {
      getTaskList();
    }
  }, [filter, user]);

  useEffect(() => {
    webSocketService.onMessage("watchListRefresh", (val) => {
      if (val.tenantId === user?.tenant_id) {
        getDeletedList();
      }
    });
  }, [user]);

  const parseDate = (dateStr: string) => {
    const date: Dayjs = dayjs(dateStr, "DD-MM-YYYY HH:mm");
    return new Date(date.toDate());
  };

  const getTaskList = () => {
    apiService
      .get("/list")
      .then((res: any) => {
        const data = res.data;
        const listObj = {
          Today: data
            ?.filter((dt: ITaskList) => dt?.task?.status === TASK_STATUS.inprogress)
            .sort(
              (a: ITaskList, b: ITaskList) =>
                parseDate(a.task.due_date).getTime() - parseDate(b.task.due_date).getTime()
            ),
          "Up-coming": data
            .filter((dt: ITaskList) => dt?.task?.status === TASK_STATUS.pending)
            .sort((a: ITaskList, b: ITaskList) => a.task.listOrder - b.task.listOrder),
        };

        if (filter?.is_personal_task && user) {
          listObj.Today = listObj.Today.filter((dt: ITaskList) =>
            dt.task.assign_to.some((usr: IUser) => usr._id === user?._id)
          );
          listObj["Up-coming"] = listObj["Up-coming"].filter((dt: ITaskList) =>
            dt.task.assign_to.some((usr: IUser) => usr._id === user?._id)
          );
        }
        setTaskList(listObj);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getPendingTask = () => {
    dispatch(fetchPendingTasks());
  };

  const getWatchList = () => {
    dispatch(
      fetchWatchList({
        status: TASK_STATUS.completedbyUser,
        ...filter,
      })
    );
  };

  const getCompletedList = () => {
    dispatch(
      fetchCompletedLists({
        status: TASK_STATUS.complete,
        ...filter,
      })
    );
  };

  const getDeleteRequestList = () => {
    dispatch(
      fetchDeleteRequestList({
        status: "REQUEST_FOR_DELETE",
        ...filter,
      })
    );
  };

  const getDeletedList = () => {
    dispatch(
      fetchDeletedLists({
        isDeleted: true,
        ...filter,
      })
    );
  };

  const TabsList: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <>
          Task
          {taskList && (taskList.Today?.length || 0) + (taskList["Up-coming"]?.length || 0) > 0 && (
            <Badge
              count={(taskList.Today?.length || 0) + (taskList["Up-coming"]?.length || 0)}
              size="small"
              overflowCount={99}
              offset={[3, -13]}
              style={{ fontSize: "10px" }}
            />
          )}
        </>
      ),
      children: "",
    },
    {
      key: "2",
      label: (
        <>
          Watchlist
          {watchList?.length > 0 && (
            <Badge
              count={watchList?.length}
              size="small"
              overflowCount={99}
              offset={[3, -13]}
              style={{ fontSize: "10px" }}
            />
          )}
        </>
      ),
      children: "",
    },
    {
      key: "3",
      label: (
        <div className={Taskcss.badge}>
          Completed
          {completedList?.length > 0 && (
            <Badge
              count={completedList?.length}
              size="small"
              overflowCount={99}
              offset={[3, -13]}
              style={{ fontSize: "10px" }}
            />
          )}
        </div>
      ),
      children: "",
    },
    ...(deleteRequestList?.length === 0
      ? []
      : [
          {
            key: "4",
            label: (
              <>
                Delete Request
                {deleteRequestList?.length > 0 && (
                  <Badge
                    count={deleteRequestList?.length}
                    size="small"
                    overflowCount={99}
                    offset={[3, -13]}
                    style={{ fontSize: "10px" }}
                  />
                )}
              </>
            ),
            children: "",
          },
        ]),
    { key: "5", label: "Deleted", children: "" },
  ];

  const handleTabChange = (key: string) => {
    setTaskTab(key);
    localStorage.setItem("taskTab", key);
  };

  useEffect(() => {
    form.setFieldValue("authority", true);
    form.setFieldValue("dueDate", dayjs());
  });

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const filterTasks = (tasks: any[]) =>
    tasks?.filter((task) => task?.task?.title?.toLowerCase().includes(searchText?.toLowerCase()));

  const filteredTaskList = taskList && {
    Today: filterTasks(taskList.Today),
    "Up-coming": filterTasks(taskList["Up-coming"]),
  };

  const filteredWatchList = filterTasks(watchList);
  const filteredCompletedList = filterTasks(completedList);
  const filteredDeleteRequestList = filterTasks(deleteRequestList);
  const filteredDeletedList = filterTasks(deletedList);

  const handleCreateClick = () => {
    dispatch(setDrawerOpen(DRAWER_TYPE.CREATE_TASK));
  };

  return (
    <>
      <TabLayout currentValue={taskTab} items={TabsList} onChange={handleTabChange} />

      <SearchHeader title={"My Tasks"} searchValue={searchText} onSearchChange={handleSearchChange}>
        <Button onClick={handleCreateClick} className={commonCSS.createBtn}>
          CREATE
        </Button>
      </SearchHeader>

      <TaskNav setFilter={setFilter} filterData={filter} />
      {taskTab === "1" && <Task pendingTasks={pendingTasks} taskList={filteredTaskList} setTaskList={setTaskList} />}
      {taskTab === "2" && <WishList filter={filter} WatchList={filteredWatchList} />}
      {taskTab === "3" && <CompetedList filter={filter} CompletedList={filteredCompletedList} />}
      {taskTab === "4" && <DeleteRequest deleteRequestList={filteredDeleteRequestList} />}
      {taskTab === "5" && <DeletedList filter={filter} deletedList={filteredDeletedList} />}
      <CreateTask />
    </>
  );
}

export default TaskInquiry;
