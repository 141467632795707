import React, { useEffect } from "react";
import { Button } from "antd";
import { calculateWinner, getComputerMove } from "../../utils/commonFunctions";
import TicTacToeSquare from "./TicTacToeSquare";

interface BoardProps {
  mode: "friends" | "computer";
  difficulty: "easy" | "medium" | "hard";
  squares: Array<string | null>;
  setSquares: (value: (string | null)[]) => void;
  isXNext: boolean;
  setIsXNext: (value: boolean) => void;
  winnerInfo: { winner: string | null; line: number[] | null };
  setWinnerInfo: (value: { winner: string | null; line: number[] | null }) => void;
  resetGame: () => void;
}

const Board: React.FC<BoardProps> = ({
  mode,
  difficulty,
  squares,
  setSquares,
  isXNext,
  setIsXNext,
  winnerInfo,
  setWinnerInfo,
  resetGame,
}) => {
  const handleClick = (index: number): void => {
    if (squares[index] || winnerInfo.winner) return;

    const newSquares = squares.slice();
    newSquares[index] = isXNext ? "X" : "O";
    setSquares(newSquares);
    setIsXNext(!isXNext);
  };

  useEffect(() => {
    if (mode === "computer" && !isXNext && !winnerInfo.winner) {
      const move = getComputerMove(squares, difficulty);
      handleClick(move);
    }
  }, [isXNext, mode, difficulty, squares, winnerInfo.winner]);

  useEffect(() => {
    setWinnerInfo(calculateWinner(squares));
  }, [squares]);

  return (
    <div>
      <div className="status">
        {winnerInfo.winner ? `Winner: ${winnerInfo.winner}` : `Next player: ${isXNext ? "X" : "O"}`}
      </div>
      <div
        className="board"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 100px)",
          gap: "5px",
        }}
      >
        {squares?.map((value, index) => (
          <TicTacToeSquare
            key={index}
            value={value}
            onClick={() => handleClick(index)}
            style={{
              backgroundColor: winnerInfo.line?.includes(index) ? "#90ee90" : "#ddd",
            }}
          />
        ))}

        <Button onClick={resetGame}>Reset Game</Button>
      </div>
    </div>
  );
};

export default Board;
