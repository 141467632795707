import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "antd";
import { AppDispatch, RootState } from "../../../store";
import { fetchHistory } from "../../../slices/taskSlice";

interface HistoryPropsInterface {
  id: string;
}

const HistoryBlock = ({ id }: HistoryPropsInterface) => {
  const dispatch = useDispatch<AppDispatch>();
  const { history } = useSelector((state: RootState) => state.task);
  const tableCol = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Created By",
      dataIndex: "create_by",
      key: "create_by",
    },
    {
      title: "Update Disposition",
      dataIndex: "update_desc",
      key: "update_desc",
    },
    {
      title: "Update",
      dataIndex: "update",
      key: "update",
    },
  ];
  useEffect(() => {
    if (id) {
      getHistory(id);
    }
  }, [id]);

  const getHistory = (taskId: string) => {
    dispatch(fetchHistory(taskId));
  };

  return (
    <>
      <div style={{ padding: 11, paddingBottom: 0 }}>
        <Table columns={tableCol} dataSource={history} pagination={false} />
      </div>
    </>
  );
};

export default HistoryBlock;
