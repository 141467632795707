import { ReactNode } from "react";
import { Tabs } from "antd";
import styleCss from "./style.module.scss";

interface TabsComponentProps {
  items: Array<{ key: string; label: string; children: ReactNode; className?: string }>;
  onChange?: (key: string) => void;
  activeKey?: string;
  defaultActiveKey?: string;
}

const NavTab = ({ items, onChange, activeKey, defaultActiveKey }: TabsComponentProps) => {
  const tabItems = items?.map((item) => ({
    key: item.key,
    label: <span className={`${item.className ? item.className : ""}`}>{item.label}</span>,
    children: item.children,
  }));

  return (
    <Tabs
      className={styleCss.customNavTab}
      onChange={onChange}
      activeKey={activeKey}
      defaultActiveKey={defaultActiveKey}
      items={tabItems}
    />
  );
};

export default NavTab;
