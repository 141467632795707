const cryptoJs = require("crypto-js");
export const decryptPassword = (hashedPassword: string, salt: string) => {
  if (!hashedPassword) return "";
  try {
    const pswrd: string = cryptoJs.AES.decrypt(hashedPassword, decryptSalt(salt)).toString(cryptoJs.enc.Utf8);

    return pswrd;
  } catch (error) {
    return "";
  }
};

const decryptSalt = (salt: any) => {
  try {
    const pswrd: string = cryptoJs.AES.decrypt(salt, process.env.REACT_APP_PRIVATE_KEY).toString(cryptoJs.enc.Utf8);
    return pswrd;
  } catch (error) {
    return "";
  }
};

export const encryptString = (plainPassword:String) => {
  try {
    const pswrd: string = cryptoJs.AES.encrypt(plainPassword, process.env.REACT_APP_ENC_KEY).toString();
    return pswrd;
  } catch (error) {
    return "";
  }
}
