import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../utils/axiosInstance";
import dayjs from "dayjs";
axiosInstance.defaults.baseURL = process.env.REACT_APP_TASK_SERVICE;

export const createTask = createAsyncThunk("task/create", async (taskData: any, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(`${process.env.REACT_APP_TASK_SERVICE}/create`, taskData);
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const fetchComments = createAsyncThunk(
  "task/fetchCommentsById ",
  async (taskId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_TASK_SERVICE}/comment/${taskId}`);
      return response?.data?.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchHistory = createAsyncThunk("task/fetchHistoryById ", async (taskId: string, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_TASK_SERVICE}/history/${taskId}`);
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const createPassengerQuickly = createAsyncThunk(
  "task/createPassengerQuickly ",
  async (passengerData: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_PASSENGER_SERVICE}/quick-create`,
        passengerData
      );
      return response?.data?.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const taskDeleteRequest = createAsyncThunk(
  "task/delete-request",
  async (requestData: { reason: string; taskId: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_TASK_SERVICE}/delete-request`, requestData);
      return response?.data?.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const changeTaskStatus = createAsyncThunk(
  "task/delete-request",
  async ({ status, taskId }: { status: string; taskId: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`${process.env.REACT_APP_TASK_SERVICE}/change-status/${taskId}`, {
        status,
      });
      return response?.data?.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const acceptTask = createAsyncThunk("task/accept", async (taskId: string, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(`${process.env.REACT_APP_TASK_SERVICE}/accept`, { taskId });
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const fetchPendingTasks = createAsyncThunk("task/pending-list", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_TASK_SERVICE}/pending-list`);
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const fetchWatchList = createAsyncThunk("task/fetchWatchList", async (filter: any, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_TASK_SERVICE}/list`, { params: filter });
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const fetchCompletedLists = createAsyncThunk(
  "task/fetchCompletedList",
  async (filter: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_TASK_SERVICE}/list`, { params: filter });
      return response?.data?.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchDeleteRequestList = createAsyncThunk(
  "task/fetchDeleteRequestList",
  async (filter: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_TASK_SERVICE}/list`, { params: filter });
      return response?.data?.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchDeletedLists = createAsyncThunk(
  "task/fetchDeletedLists",
  async (filter: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_TASK_SERVICE}/list`, { params: filter });
      return response?.data?.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const addPassenger = createAsyncThunk(
  "task/addPassenger",
  async (payload: { taskId: string; passengerId: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_TASK_SERVICE}/add-passenger`, payload);
      return response?.data?.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchSecStepPassengerList = createAsyncThunk(
  "task/fetchTaskPassenger",
  async (taskId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_TASK_SERVICE}/task-passenger/${taskId}`);
      return response?.data?.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const removeTaskPassenger = createAsyncThunk(
  "task/removePassengerInTask",
  async (data: { taskId: string; ids: string[] }, { rejectWithValue }) => {
    try {
      await axiosInstance.post(`${process.env.REACT_APP_TASK_SERVICE}/remove-passenger`, data);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Define the interface for the task item
export interface ICreateTask {
  clientId?: string;
  dueDate: moment.Moment;
  is_prior?: boolean;
  notes?: string;
  title: string;
  type: string[];
}
export interface ITask {
  accept_by_user: boolean;
  accept_user: string;
  assign_to: IUser[];
  authorized_user: string[] | IUser;
  created_by: IUser;
  created_on: string;
  documents: any;
  due_date: string;
  is_deleted: boolean;
  is_high_prior: boolean;
  listOrder: number;
  master: IMaster;
  note?: string;
  status: string;
  subtask: ISubTask[];
  task_status: string;
  task_type: string[];
  title: string;
  __v: number;
  _id: string;
  client?: IClient;
  delete_reason?: string;
}

export interface ITaskList {
  assign_at: string;
  created_on: string;
  master: IMaster;
  read_at: string;
  read_status: boolean;
  task: ITask;
  task_id: string;
  user_id: string;
  _id: string;
}

export interface IClient {
  contact_name: string;
  contact_number: string;
  full_name: string;
  _id: string;
}

export interface IQuickPassenger {
  contactNumber?: string;
  fullName: string;
  passengerType: string;
  referenceBy?: string;
  member?: string;
}

export interface AuthorizedUser {
  _id: string;
}
interface ISubTask {
  name: string;
  task: any[];
}

export interface ITaskDetails {
  tenantId: string;
  task: ITask;
}

export interface FetchTaskDataResponse {
  data: ITask;
}

interface IMaster {
  created_on: string;
  passangers: Array<any>;
  step: number;
  task: string;
  _id: string;
}

export interface MinList {
  name: string;
  _id: string;
  type: "TASK" | "CONTACT" | "INQUIRY" | "VENDOR";
}

export interface subTaskData {
  id?: string;
  is_completed: boolean;
  title: string;
  due_date?: string;
}
export interface subTaskDataListInterface {
  name: string;
  task: subTaskData[];
}

export interface IFile {
  uid: string;
  name: string;
  status: string;
  url: string;
}

export interface IUser {
  _id: string;
  full_name: string;
  email?: string;
}

export interface IComment {
  _id: string;
  task: string;
  user: IUser;
  message: string;
  mentions: any;
  replies: string[];
  reactions: string[];
  created_on: string;
  __v: number;
}

export interface ICommentAction {
  tenantId: string;
  comment: IComment;
  action: string;
  commentId?: string;
}

export interface AssigneeListParams {
  includes: (id: string) => boolean;
}

export interface IAssignee {
  _id: string;
  name: string;
}
// Define the interface for the state
export interface TaskState {
  minimizeList: MinList[];
  task: any;
  openTaskId: string;
  comments: IComment[];
  history: any;
  taskList: any;
  pendingTasks: any;
  watchList: any;
  completedList: any;
  deleteRequestList: any;
  deletedList: any;
  secondeStepPassengerList: any;
}

// Initial state
const initialState: TaskState = {
  minimizeList: [],
  task: null,
  openTaskId: "",
  comments: [],
  history: [],
  taskList: [],
  pendingTasks: [],
  watchList: [],
  completedList: [],
  deleteRequestList: [],
  deletedList: [],
  secondeStepPassengerList: [],
};

const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    setMinimizeItem(state, action: PayloadAction<MinList>) {
      if (!state.minimizeList.find((tl) => tl._id === action.payload._id)) {
        state.minimizeList.push(action.payload);
      }
    },
    closeMinimizeTask(state, action: PayloadAction<string>) {
      state.minimizeList = state.minimizeList.filter((tsk) => tsk._id !== action.payload);
    },
    openTask(state, action: PayloadAction<string>) {
      state.openTaskId = action.payload;
    },
    closeTask(state) {
      state.openTaskId = "";
    },
    addComment(state, action: PayloadAction<{ taskId: string; commentData: IComment }>) {
      state.comments.push(action.payload.commentData);
    },
    removeComment(state, action: PayloadAction<string>) {
      state.comments = state.comments.filter((comment) => comment?._id !== action?.payload);
    },
    resetPassengerList(state) {
      state.secondeStepPassengerList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createTask.fulfilled, (state, action) => {
      state.task = action.payload;
    });
    builder.addCase(fetchComments.fulfilled, (state, action) => {
      state.comments = action.payload;
    });
    builder.addCase(fetchHistory.fulfilled, (state, action) => {
      const data = action?.payload?.map((dt: any) => ({
        key: dt._id,
        date: dayjs(dt.created_at).format("DD-MM-YYYY HH:mm"),
        create_by: dt?.created_by?.full_name,
        update_desc: dt?.update_description,
        update: dt?.update,
      }));
      state.history = data;
    });
    builder.addCase(createPassengerQuickly.fulfilled, (state, action) => {});
    builder.addCase(fetchPendingTasks.fulfilled, (state, action) => {
      state.pendingTasks = action.payload;
    });
    builder.addCase(fetchWatchList.fulfilled, (state, action) => {
      state.watchList = action.payload;
    });
    builder.addCase(fetchCompletedLists.fulfilled, (state, action) => {
      state.completedList = action.payload;
    });
    builder.addCase(fetchDeleteRequestList.fulfilled, (state, action) => {
      state.deleteRequestList = action.payload;
    });
    builder.addCase(fetchDeletedLists.fulfilled, (state, action) => {
      state.deletedList = action.payload;
    });
    builder.addCase(addPassenger.fulfilled, (state, action) => {
      state.secondeStepPassengerList.push(action?.payload);
    });
    builder.addCase(fetchSecStepPassengerList.fulfilled, (state, action) => {
      state.secondeStepPassengerList = action.payload;
    });
    builder.addCase(removeTaskPassenger.fulfilled, (state, action) => {
      const { ids } = action.payload;
      state.secondeStepPassengerList = state.secondeStepPassengerList?.filter(
        (passenger: any) => !ids.includes(passenger?._id)
      );
    });
  },
});

export const {
  setMinimizeItem,
  closeMinimizeTask,
  openTask,
  closeTask,
  addComment,
  removeComment,
  resetPassengerList,
} = taskSlice.actions;

export default taskSlice.reducer;
