import React, { useEffect, useState } from "react";
import { Checkbox, Col, Flex, Form, Input, Row, Select, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { capitalizeFirstLetter, capitalizeFirstLetterOfWord } from "../../../utils/commonFunctions";
import { InputBox } from "../../../components/FormInput";
import fireActiveIcon from "../../../assets/fireactive.svg";
import fireIcon from "../../../assets/fire.svg";
import webSocketService from "../../../utils/websocketService";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { ITask } from "../../../slices/taskSlice";
import TaskStyleCss from "../createDrawer.module.scss";
import { IoIosArrowDown } from "react-icons/io";

interface IFirstStepProps {
  taskData: ITask;
}

const FirstStep = ({ taskData }: IFirstStepProps) => {
  const [taskForm] = Form.useForm();
  const { referenceList } = useSelector((state: RootState) => state.contact);
  const { user } = useSelector((state: RootState) => state.user);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isPrior, setIsPrior] = useState(false);
  const [note, setNote] = useState("");

  useEffect(() => {
    setIsPrior(taskData?.is_high_prior);
    taskForm.setFieldValue("is_prior", taskData?.is_high_prior);
    taskForm.setFieldValue("title", taskData?.title);
    taskForm.setFieldValue("notes", taskData?.note);
    taskForm.setFieldValue("clientId", taskData?.client?.contact_name);
  }, [taskData]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (note !== taskData?.note) {
        webSocketService.sendMessage("taskNoteUpdate", {
          tntId: user?.tenant_id,
          taskId: taskData?._id,
          note: capitalizeFirstLetter(note),
        });
      }
    }, 2000);
    return () => {
      clearTimeout(handler);
    };
  }, [note]);

  const handleTitleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsEditingTitle(false);
    webSocketService.sendMessage("taskTitleChange", {
      tntId: user?.tenant_id,
      taskId: taskData?._id,
      title: capitalizeFirstLetterOfWord(e.target.value),
      userId: user?._id,
    });
  };

  const handlePriority = (e: CheckboxChangeEvent) => {
    setIsPrior(e.target.checked);
    webSocketService.sendMessage("taskPriorityUpdate", {
      tntId: user?.tenant_id,
      taskId: taskData._id,
      isPrior: e.target.checked,
      userId: user?._id,
    });
  };

  const noteUpdate = (e: React.FocusEvent<HTMLInputElement>) => {
    webSocketService.sendMessage("taskNoteUpdate", {
      tntId: user?.tenant_id,
      taskId: taskData?._id,
      note: capitalizeFirstLetter(e.target.value),
    });
  };

  const handleCustomerChange = (value: string) => {
    webSocketService.sendMessage("taskClientUpdate", {
      tntId: user?.tenant_id,
      taskId: taskData._id,
      userId: user?._id,
      clientId: value,
    });
  };

  const handleEditTitleClick = () => {
    setIsEditingTitle(true);
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  return (
    <Form name="task" form={taskForm} initialValues={{ clientId: taskData?.client?.contact_name }}>
      <Row className="title-wrapper" justify={"space-between"} align="middle" style={{ padding: "10px 0" }}>
        <Col xxl={13} xl={18} lg={16} sm={14} xs={12}>
          <Form.Item name="title" className="mb-0">
            {isEditingTitle ? (
              <Input
                placeholder="Enter task name"
                variant="borderless"
                onBlur={handleTitleBlur}
                defaultValue={taskData?.title}
                autoFocus
              />
            ) : (
              <div style={{ cursor: "pointer" }}>
                <Typography.Text>{taskData?.title || "Enter task name"}</Typography.Text>
                <button
                  onClick={handleEditTitleClick}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                >
                  <EditOutlined style={{ paddingLeft: "10px", color: "#535c6957" }} />
                </button>
              </div>
            )}
          </Form.Item>
        </Col>
        <Col>
          {taskData?.client?.contact_number && (
            <Col>
              <Typography.Text className="clientContact">{taskData?.client?.contact_number}</Typography.Text>
            </Col>
          )}
        </Col>
        <Col>
          <Form.Item name="clientId">
            <Select
              placeholder="Select Customer"
              style={{ width: 300 }}
              options={referenceList.map((refDt) => ({
                label: refDt.contact_name || refDt.full_name,
                value: refDt._id,
              }))}
              className="template-dropdown"
              rootClassName="taskType"
              onSelect={handleCustomerChange}
              suffixIcon={<IoIosArrowDown size={18}/>}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item className="mb-0" name={"is_prior"} valuePropName="checked">
            <Checkbox onChange={handlePriority}>
              <Flex gap={8} align="center">
                High Priority <img src={isPrior ? fireActiveIcon : fireIcon} alt="priority icon" />
              </Flex>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item className={TaskStyleCss.notes}>
        <InputBox
          type="textarea"
          name={"notes"}
          onBlur={noteUpdate}
          onChange={handleNoteChange}
          placeholder="Add Notes here"
          style={{ marginBottom: "10px" }}
          autoSize={{ minRows: "8", maxRows: "22" }}
        />
      </Form.Item>
    </Form>
  );
};

export default FirstStep;
