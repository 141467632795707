// Reminder Notes List
import { useSelector } from "react-redux";
import { Typography } from "antd";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { LuBell } from "react-icons/lu";
import { Masonry } from "@mui/lab";
import { RootState } from "../../store";
import NoteCard from "./NoteCard";
import { IMyNote } from "../../slices/notesSlice";
import styleCss from "./style.module.scss";

dayjs.extend(isSameOrAfter);

const ReminderNoteList = () => {
  const now = dayjs();
  const { allNotesList } = useSelector((state: RootState) => state.notes);

  // Filter notes with past reminders
  const pastReminder = allNotesList.filter((noteObj) => {
    const reminderTime = dayjs(noteObj.note.reminder);
    return reminderTime.isBefore(now);
  });

  // Filter notes with future reminders
  const futureReminderNotes = allNotesList.filter((noteObj) => {
    const reminderTime = noteObj?.note?.reminder;
    if (reminderTime) {
      const reminderDate = dayjs(reminderTime);
      return reminderDate.isSameOrAfter(now);
    }
    return false;
  });

  return (
    <div className={styleCss.noteGirdViewContainer}>
      <div className={styleCss.filterTitle}>
        {!!pastReminder.length && (
          <>
            <Typography className="title" style={{ paddingTop: "0px" }}>
              PAST
            </Typography>

            <Masonry className={styleCss.myMasonryGrid}>
              {pastReminder?.map((note: IMyNote) => (
                <NoteCard {...note} key={note?._id} />
              ))}
            </Masonry>
          </>
        )}
        {!!pastReminder.length && !!futureReminderNotes?.length && (
          <Typography className="title" style={{ paddingTop: "20px" }}>
            UPCOMING
          </Typography>
        )}
        {!!futureReminderNotes?.length && (
          <Masonry className={styleCss.myMasonryGrid}>
            {futureReminderNotes?.map((note: IMyNote) => (
              <NoteCard {...note} key={note?._id} />
            ))}
          </Masonry>
        )}
      </div>
      {!pastReminder.length && !futureReminderNotes?.length && (
        <div className="no-data-notification">
          <LuBell size={90} className="note-no-data-icon" />
          <Typography className="no-data-text">Notes with upcoming reminders appear here</Typography>
        </div>
      )}
    </div>
  );
};

export default ReminderNoteList;
