import React from "react";
import { Table } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table/interface";

export interface TableColumnInterface {
  title: string;
  dataIndex: string;
  key: string;
  render?: (value: any, record: any, index: number) => React.ReactNode;
  width?: string;
}
interface DataTableInterface {
  column: ColumnsType<any>;
  data: any[];
  rowSelection?: any;
  pagination?: false | TablePaginationConfig;
  expandable?: any;
  onRow?: any;
  loading?: any;
}
function DataTable({ column, data, ...rest }: DataTableInterface) {
  return <Table {...rest} columns={column} dataSource={data} className="data-table" />;
}

export default DataTable;
