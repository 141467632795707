import { useEffect, useState } from "react";
import { Button, Flex, Modal, Upload, UploadProps } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { FiUpload } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { getDocumentURL } from "../../../utils/AWSService";
import { createTheme, deleteTheme, updateTheme } from "../../../slices/userSlice";
import { AppDispatch, RootState } from "../../../store";
import styleCss from "./style.module.scss";

interface IThemeModal {
  isOpen: boolean;
  handleModelClose: () => void;
}

interface IThemeImage {
  url: string;
  removable?: boolean;
  id?: string;
}

const ThemeModal = ({ isOpen, handleModelClose }: IThemeModal) => {
  const dispatch = useDispatch<AppDispatch>();
  const { userConfig } = useSelector((state: RootState) => state.user);
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [backgroundImage, setBackgroundImage] = useState<IThemeImage>();
  const [imageUrls, setImageUrls] = useState<IThemeImage[]>([]);

  useEffect(() => {
    if (backgroundImage?.url) {
      document.body.style.backgroundImage = `url(${backgroundImage?.url})`;
    }
  }, [backgroundImage]);

  useEffect(() => {
    const defaultThemeUrls = [
      { url: getDocumentURL(`theme/bg1.jpg`), removable: false },
      { url: getDocumentURL(`theme/bg2.jpg`), removable: false },
      { url: getDocumentURL(`theme/bg3.jpg`), removable: false },
      { url: getDocumentURL(`theme/bg7.jpg`), removable: false },
      { url: getDocumentURL(`theme/bg5.jpg`), removable: false },
      { url: getDocumentURL(`theme/bg8.jpg`), removable: false },
      { url: getDocumentURL(`theme/main-bg.png`), removable: false },
    ];

    const uploadedThemes =
      userConfig?.myUploads
        ?.filter((item) => !!item?.fileName)
        ?.map((item) => ({
          url: getDocumentURL(`theme/${item.fileName}`),
          removable: item?.removable || true,
          id: item?._id,
        })) || [];

    const combinedImageUrls = [...defaultThemeUrls, ...uploadedThemes];

    setImageUrls(combinedImageUrls);
  }, [userConfig]);

  const setOriginalTheme = () => {
    document.body.style.backgroundImage = `url(${userConfig?.theme?.themeUrl})`;
  };

  const getUrlToFileName = (url: string) => {
    return url.split("/theme/")[1] || "";
  };

  const handleImageClick = (image: IThemeImage) => {
    setBackgroundImage(image);
  };

  const closeThemeModal = () => {
    setOriginalTheme();
    handleModelClose();
  };

  const uploadModalClose = () => {
    setUploadModal(false);
    setUploadedFile(null);
    setOriginalTheme();
  };

  const handleUpdateTheme = () => {
    if (backgroundImage) {
      const { id, url } = backgroundImage;
      const data = {
        themeId: id,
        fileName: getUrlToFileName(url),
        themeUrl: url,
      };
      dispatch(updateTheme(data));
    }
    handleModelClose();
  };

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    accept: ".png, .jpeg, .jpg",
    listType: "picture",
    beforeUpload: () => false,
    onChange: (info) => {
      const { fileList } = info;
      const lastFile = fileList[fileList.length - 1];
      setUploadedFile(lastFile);

      if (lastFile?.originFileObj) {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result) {
            setBackgroundImage({ url: reader.result.toString() });
          }
        };
        reader.readAsDataURL(lastFile.originFileObj);
      }
    },
    onRemove: () => {
      setUploadedFile(null);
      setOriginalTheme();
    },
    style: { backgroundColor: "white", marginTop: 26 },
    fileList: uploadedFile ? [uploadedFile] : [],
  };

  const handleFileUpload = async () => {
    if (uploadedFile) {
      const file = uploadedFile.originFileObj;
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result) {
            setBackgroundImage({ url: reader.result.toString() });
            setUploadModal(false);
          }
        };
        reader.readAsDataURL(file);
        const formData = new FormData();
        formData.append("file", file);
        await dispatch(createTheme(formData));
      }
    }
    uploadModalClose();
  };

  const handleDeleteImage = (image: IThemeImage) => {
    if (image?.id) {
      dispatch(deleteTheme(image?.id));
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        width={800}
        footer={null}
        centered
        closable={true}
        onCancel={closeThemeModal}
        title={"Theme"}
        mask={false}
        className={styleCss.themeModel}
      >
        <div
          className="image-grid"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: "8px",
            maxHeight: "400px",
            overflowY: "scroll",
          }}
        >
          {imageUrls?.map((item, index) => (
            <div
              key={index}
              onClick={() => handleImageClick(item)}
              className="image-container"
              style={{
                cursor: "pointer",
                borderRadius: "8px",
                overflow: "hidden",
                transition: "transform 0.3s ease",
                border: backgroundImage?.url === item?.url ? "3px solid #bbed21" : "3px solid transparent",
                height: "130px",
              }}
            >
              <img
                src={item?.url}
                alt={`Image ${index + 1}`}
                className="image"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              {item?.removable && (
                <div className="hover-overlay">
                  <MdDelete
                    className="delete-button"
                    size={18}
                    color="white"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteImage(item);
                    }}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
        <Flex className="Modelfooter" style={{ marginTop: "20px" }} align="center" justify="space-between">
          <div onClick={() => setUploadModal(true)} className="custom">
            Custom theme
          </div>
          <Flex gap={10}>
            <Button onClick={closeThemeModal} className="cancel">
              CANCEL
            </Button>
            <Button onClick={handleUpdateTheme} className="save">
              SAVE
            </Button>
          </Flex>
        </Flex>
      </Modal>

      <Modal
        open={uploadModal}
        onCancel={uploadModalClose}
        centered
        width={700}
        mask={false}
        footer={null}
        className={styleCss.themeModel}
      >
        <Upload.Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <FiUpload size={40} color="#99A1BB" />
          </p>
          <p className="ant-upload-text" style={{ color: "#1A73E8" }}>
            Upload background image or Drag an image here
          </p>
          <p className="ant-upload-hint" style={{ color: "#99A1BB" }}>
            Upload an image in either a PNG or JPEG format. Maximum size: 2MB.
          </p>
        </Upload.Dragger>
        <Flex gap={10} className="Modelfooter" style={{ cursor: "pointer", marginTop: "20px" }} justify="end">
          <Button onClick={uploadModalClose} className="cancel">
            CANCEL
          </Button>
          <Button onClick={handleFileUpload} className="save" disabled={!uploadedFile}>
            OK
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

export default ThemeModal;
