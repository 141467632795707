import React, { useEffect, useState } from "react";
import { Avatar, Button, Flex, Image, Space, Table, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { fetchVendorList, setIsUploadCollapse } from "../../slices/vendorSlice";
import { getDocumentURL } from "../../utils/AWSService";
import SearchHeader from "../../components/SearchHeader";
import DataTable from "../../components/DataTable";
import docIcon from "../../assets/docIcon.svg";
import { UserOutlined } from "@ant-design/icons";
import { setContactDrawerOpen, setVendorDrawerOpen } from "../../slices/layoutSlice";
import { DRAWER_TYPE } from "../../utils/const";
import { openItem } from "../../slices/minimizeSlice";
import CreateVendor from "./CreateVendor";
import commonCss from "../../css/commonStyle.module.scss";
const _ = require("lodash");

interface Document {
  passport: any;
  pan_card: any;
  other_documents: any[];
}

interface Contact {
  key: string;
  name: string;
  contactNumber: string;
  ref: string;
  tag: string;
  doc: Document;
  profile: string;
  children?: Contact[];
}

interface Person {
  name: string;
  children?: Person[];
}

interface Member extends Person {
  _id: string;
  contact_name: string;
  contact_number: string;
  reference_by: { contact_name: string };
  type: string;
  documents: Document;
  profile_image: string;
}

function VendorList() {
  const dispatch = useDispatch<AppDispatch>();
  const { allVendorList, loading } = useSelector((state: RootState) => state.vendor);

  const { user } = useSelector((state: RootState) => state.user);
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    dispatch(fetchVendorList());
  }, []);

  const getImageUrl = (vendorId: string, fileName: string) => {
    return getDocumentURL(`vendor_doc/${user?.tenant_id}/${vendorId}/${fileName}`) ?? false;
  };

  const column: any[] = [
    Table.SELECTION_COLUMN,
    {
      title: "Vendor A/C Name",
      dataIndex: "account_name",
      key: "account_name",
      onCell: (record: Contact) => {
        return {
          onClick: () => {
            vendorOpenHandle(record);
          },
        };
      },
    },
    {
      title: "GST No",
      dataIndex: "gst_number",
      key: "gst_number",
      width: "250px",
      onCell: (record: Contact) => {
        return {
          onClick: () => {
            vendorOpenHandle(record);
          },
        };
      },
    },
    {
      title: "GST Treatment",
      dataIndex: "gst_treatment",
      key: "gst_treatment",
      width: "300px",
      onCell: (record: Contact) => {
        return {
          onClick: () => {
            vendorOpenHandle(record);
          },
        };
      },
    },
    {
      title: "Document",
      dataIndex: "doc",
      key: "doc",
      render: (item: Document, record: Contact) => {
        const { other_documents } = item;

        const onUpload = () => {
          vendorOpenHandle(record);
          dispatch(setIsUploadCollapse(true));
        };

        return (
          <>
            {!other_documents?.length ? (
              <Typography.Link onClick={onUpload}>+ upload</Typography.Link>
            ) : (
              <Flex gap={8}>{other_documents?.length >= 1 && <Image src={docIcon} preview={false} />}</Flex>
            )}
          </>
        );
      },
      width: "180px",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
    },
    getCheckboxProps: (record: any) => {},
  };

  const formattedData = _.map(allVendorList, (item: any) => {
    const referenceByName = item?.reference_by;

    const referenceContactName = referenceByName
      ? _.get(
          _.find(allVendorList, (contact: any) => contact._id === referenceByName),
          "company_name",
          "-"
        )
      : "-";

    return {
      key: item?._id,
      account_name: item?.account_name,
      gst_treatment: item?.gst_treatment,
      gst_number: item?.gst_number,
      ref: referenceContactName,
      doc: _.defaults(item?.documents, { other_documents: [] }),
      profile: item?.profile_image,
    };
  });

  const openCreateModel = () => {
    dispatch(setVendorDrawerOpen(DRAWER_TYPE.VENDOR_DRAWER));
  };

  const vendorOpenHandle = (value: Contact) => {
    dispatch(
      openItem({
        _id: value.key,
        name: value.name,
        type: "VENDOR",
      })
    );
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value);
  };

  const filterData = (data: Person[], keyword: string) => {
    if (!keyword) {
      return data;
    }

    return data.filter((item) => {
      const mainPersonMatches = item.name?.toLowerCase().includes(keyword.toLowerCase());
      const groupPersonMatches = item.children?.some((child: Person) =>
        child.name.toLowerCase().includes(keyword.toLowerCase())
      );

      return mainPersonMatches || groupPersonMatches;
    });
  };

  const filteredData = filterData(formattedData, searchKeyword);

  return (
    <div>
      <SearchHeader title={"Vendor List"} searchValue={searchKeyword} onSearchChange={handleSearchChange}>
        <Button onClick={openCreateModel} className={commonCss.createBtn}>
          CREATE
        </Button>
      </SearchHeader>
      <CreateVendor />
      <div style={{ marginTop: 15, cursor: "pointer" }}>
        <DataTable
          column={column}
          data={filteredData}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          loading={loading}
          pagination={false}
        />
      </div>
    </div>
  );
}

export default VendorList;
