
// Define SVGs as components or strings
const svgMap: any = {
  AIR_TICKET_INT: (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none">
      <rect width="26" height="26" rx="4" fill="#535c69" fillOpacity="0.1" />
      <path
        d="M16.8667 17.8L15.6667 12.3333L18 10C19 9 19.3333 7.66667 19 7C18.3333 6.66667 17 7 16 8L13.6667 10.3333L8.2 9.13333C7.86667 9.06667 7.6 9.2 7.46667 9.46667L7.26667 9.8C7.13333 10.1333 7.2 10.4667 7.46667 10.6667L11 13L9.66667 15H7.66667L7 15.6667L9 17L10.3333 19L11 18.3333V16.3333L13 15L15.3333 18.5333C15.5333 18.8 15.8667 18.8667 16.2 18.7333L16.5333 18.6C16.8 18.4 16.9333 18.1333 16.8667 17.8Z"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  AIR_TICKET_DOM: (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <rect width="26" height="26" rx="4" fill="#535c69" fillOpacity="0.1" />
      <path
        d="M16.8667 17.8L15.6667 12.3333L18 10C19 9 19.3333 7.66667 19 7C18.3333 6.66667 17 7 16 8L13.6667 10.3333L8.2 9.13333C7.86667 9.06667 7.6 9.2 7.46667 9.46667L7.26667 9.8C7.13333 10.1333 7.2 10.4667 7.46667 10.6667L11 13L9.66667 15H7.66667L7 15.6667L9 17L10.3333 19L11 18.3333V16.3333L13 15L15.3333 18.5333C15.5333 18.8 15.8667 18.8667 16.2 18.7333L16.5333 18.6C16.8 18.4 16.9333 18.1333 16.8667 17.8Z"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  TOUR_PACKAGE_DOM: (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <rect width="26" height="26" rx="4" fill="#535c69" fillOpacity="0.1" />
      <path d="M10.333 9V13" stroke="#535c69" strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15 9V13" stroke="#535c69" strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.33301 13H19.3997" stroke="#535c69" strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M16.9997 17H18.9997C18.9997 17 19.333 15.8667 19.533 15.1333C19.5997 14.8667 19.6663 14.6 19.6663 14.3333C19.6663 14.0667 19.5997 13.8 19.533 13.5333L18.5997 10.2C18.3997 9.53333 17.733 9 16.9997 9H7.66634C7.31272 9 6.97358 9.14048 6.72353 9.39052C6.47348 9.64057 6.33301 9.97971 6.33301 10.3333V17H8.33301"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.66634 18.3337C10.4027 18.3337 10.9997 17.7367 10.9997 17.0003C10.9997 16.2639 10.4027 15.667 9.66634 15.667C8.92996 15.667 8.33301 16.2639 8.33301 17.0003C8.33301 17.7367 8.92996 18.3337 9.66634 18.3337Z"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11 17H14.3333" stroke="#535c69" strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M15.6663 18.3337C16.4027 18.3337 16.9997 17.7367 16.9997 17.0003C16.9997 16.2639 16.4027 15.667 15.6663 15.667C14.93 15.667 14.333 16.2639 14.333 17.0003C14.333 17.7367 14.93 18.3337 15.6663 18.3337Z"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  TOUR_PACKAGE_INT: (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <rect width="26" height="26" rx="4" fill="#535c69" fillOpacity="0.1" />
      <path d="M10.333 9V13" stroke="#535c69" strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15 9V13" stroke="#535c69" strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.33301 13H19.3997" stroke="#535c69" strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M16.9997 17H18.9997C18.9997 17 19.333 15.8667 19.533 15.1333C19.5997 14.8667 19.6663 14.6 19.6663 14.3333C19.6663 14.0667 19.5997 13.8 19.533 13.5333L18.5997 10.2C18.3997 9.53333 17.733 9 16.9997 9H7.66634C7.31272 9 6.97358 9.14048 6.72353 9.39052C6.47348 9.64057 6.33301 9.97971 6.33301 10.3333V17H8.33301"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.66634 18.3337C10.4027 18.3337 10.9997 17.7367 10.9997 17.0003C10.9997 16.2639 10.4027 15.667 9.66634 15.667C8.92996 15.667 8.33301 16.2639 8.33301 17.0003C8.33301 17.7367 8.92996 18.3337 9.66634 18.3337Z"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11 17H14.3333" stroke="#535c69" strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M15.6663 18.3337C16.4027 18.3337 16.9997 17.7367 16.9997 17.0003C16.9997 16.2639 16.4027 15.667 15.6663 15.667C14.93 15.667 14.333 16.2639 14.333 17.0003C14.333 17.7367 14.93 18.3337 15.6663 18.3337Z"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  VISA: (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <rect width="26" height="26" rx="4" fill="#535c69" fillOpacity="0.1" />
      <path
        d="M18.3335 8.33337H7.66683C6.93045 8.33337 6.3335 8.93033 6.3335 9.66671V16.3334C6.3335 17.0698 6.93045 17.6667 7.66683 17.6667H18.3335C19.0699 17.6667 19.6668 17.0698 19.6668 16.3334V9.66671C19.6668 8.93033 19.0699 8.33337 18.3335 8.33337Z"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.3335 11.6666H19.6668"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  INSURENCE: (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <rect width="26" height="26" rx="4" fill="#535c69" fillOpacity="0.1" />
      <path
        d="M18.3332 13.6666C18.3332 17 15.9998 18.6666 13.2265 19.6333C13.0813 19.6825 12.9235 19.6802 12.7798 19.6266C9.99984 18.6666 7.6665 17 7.6665 13.6666V8.99997C7.6665 8.82316 7.73674 8.65359 7.86177 8.52857C7.98679 8.40355 8.15636 8.33331 8.33317 8.33331C9.6665 8.33331 11.3332 7.53331 12.4932 6.51997C12.6344 6.39931 12.8141 6.33301 12.9998 6.33301C13.1856 6.33301 13.3653 6.39931 13.5065 6.51997C14.6732 7.53997 16.3332 8.33331 17.6665 8.33331C17.8433 8.33331 18.0129 8.40355 18.1379 8.52857C18.2629 8.65359 18.3332 8.82316 18.3332 8.99997V13.6666Z"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 13L12.3333 14.3333L15 11.6666"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  ACCOUNT: (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <rect width="26" height="26" rx="4" fill="#535c69" fillOpacity="0.1" />
      <path
        d="M16.9998 6.33337H8.99984C8.26346 6.33337 7.6665 6.93033 7.6665 7.66671V18.3334C7.6665 19.0698 8.26346 19.6667 8.99984 19.6667H16.9998C17.7362 19.6667 18.3332 19.0698 18.3332 18.3334V7.66671C18.3332 6.93033 17.7362 6.33337 16.9998 6.33337Z"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.3335 9H15.6668" stroke="#535c69" strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.6665 14.3334V17" stroke="#535c69" strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M15.6665 11.6666H15.6732"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13 11.6666H13.0067" stroke="#535c69" strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M10.3335 11.6666H10.3402"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13 14.3334H13.0067" stroke="#535c69" strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M10.3335 14.3334H10.3402"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13 17H13.0067" stroke="#535c69" strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.3335 17H10.3402" stroke="#535c69" strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  CO_ORDINATOR: (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <rect width="26" height="26" rx="4" fill="#535c69" fillOpacity="0.1" />
      <path
        d="M15.6668 19V17.6667C15.6668 16.9594 15.3859 16.2811 14.8858 15.781C14.3857 15.281 13.7074 15 13.0002 15H9.00016C8.29292 15 7.61464 15.281 7.11454 15.781C6.61445 16.2811 6.3335 16.9594 6.3335 17.6667V19"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0002 12.3333C12.4729 12.3333 13.6668 11.1394 13.6668 9.66667C13.6668 8.19391 12.4729 7 11.0002 7C9.5274 7 8.3335 8.19391 8.3335 9.66667C8.3335 11.1394 9.5274 12.3333 11.0002 12.3333Z"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.6665 19V17.6667C19.6661 17.0758 19.4694 16.5019 19.1074 16.0349C18.7454 15.5679 18.2386 15.2344 17.6665 15.0867"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6665 7.08667C16.2401 7.23354 16.7485 7.56714 17.1116 8.03488C17.4747 8.50262 17.6717 9.07789 17.6717 9.67C17.6717 10.2621 17.4747 10.8374 17.1116 11.3051C16.7485 11.7729 16.2401 12.1065 15.6665 12.2533"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  ADMIN: (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <rect width="26" height="26" rx="4" fill="#535c69" fillOpacity="0.1" />
      <path
        d="M17.6667 7H8.33333C7.59695 7 7 7.59695 7 8.33333V17.6667C7 18.403 7.59695 19 8.33333 19H17.6667C18.403 19 19 18.403 19 17.6667V8.33333C19 7.59695 18.403 7 17.6667 7Z"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 13.6666C14.1046 13.6666 15 12.7712 15 11.6666C15 10.5621 14.1046 9.66663 13 9.66663C11.8954 9.66663 11 10.5621 11 11.6666C11 12.7712 11.8954 13.6666 13 13.6666Z"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.6665 19V17.6667C9.6665 17.3131 9.80698 16.9739 10.057 16.7239C10.3071 16.4739 10.6462 16.3334 10.9998 16.3334H14.9998C15.3535 16.3334 15.6926 16.4739 15.9426 16.7239C16.1927 16.9739 16.3332 17.3131 16.3332 17.6667V19"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  SALES_MARKETING: (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <rect width="26" height="26" rx="4" fill="#535c69" fillOpacity="0.1" />
      <path d="M13 15.6666V19" stroke="#535c69" strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.6665 14.3334V19" stroke="#535c69" strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.3335 11.6666V19" stroke="#535c69" strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M19.6668 7L13.9028 12.764C13.8719 12.795 13.8351 12.8197 13.7946 12.8365C13.7541 12.8533 13.7107 12.8619 13.6668 12.8619C13.623 12.8619 13.5796 12.8533 13.5391 12.8365C13.4986 12.8197 13.4618 12.795 13.4308 12.764L11.2362 10.5693C11.1737 10.5068 11.0889 10.4717 11.0005 10.4717C10.9121 10.4717 10.8273 10.5068 10.7648 10.5693L6.3335 15"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.6665 17V19" stroke="#535c69" strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.3335 14.3334V19" stroke="#535c69" strokeWidth="0.85" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  PERSONAL: (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <rect width="26" height="26" rx="4" fill="#535c69" fillOpacity="0.1" />
      <path
        d="M15.6668 19V17.6667C15.6668 16.9594 15.3859 16.2811 14.8858 15.781C14.3857 15.281 13.7074 15 13.0002 15H9.00016C8.29292 15 7.61464 15.281 7.11454 15.781C6.61445 16.2811 6.3335 16.9594 6.3335 17.6667V19"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0002 12.3333C12.4729 12.3333 13.6668 11.1394 13.6668 9.66667C13.6668 8.19391 12.4729 7 11.0002 7C9.5274 7 8.3335 8.19391 8.3335 9.66667C8.3335 11.1394 9.5274 12.3333 11.0002 12.3333Z"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6665 12.3333L16.9998 13.6667L19.6665 11"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  MULTI: (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <rect width="26" height="26" rx="4" fill="#535c69" fillOpacity="0.1" />
      <path
        d="M8.99984 18.3333C8.64622 18.3333 8.30708 18.1929 8.05703 17.9428C7.80698 17.6928 7.6665 17.3536 7.6665 17V10.3333C7.6665 9.97971 7.80698 9.64057 8.05703 9.39052C8.30708 9.14048 8.64622 9 8.99984 9H16.9998C17.3535 9 17.6926 9.14048 17.9426 9.39052C18.1927 9.64057 18.3332 9.97971 18.3332 10.3333V17C18.3332 17.3536 18.1927 17.6928 17.9426 17.9428C17.6926 18.1929 17.3535 18.3333 16.9998 18.3333"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3335 17V7.66671C10.3335 7.31309 10.474 6.97395 10.724 6.7239C10.9741 6.47385 11.3132 6.33337 11.6668 6.33337H14.3335C14.6871 6.33337 15.0263 6.47385 15.2763 6.7239C15.5264 6.97395 15.6668 7.31309 15.6668 7.66671V17"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6665 18.3334H14.3332"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6668 19.6667C16.4032 19.6667 17.0002 19.0697 17.0002 18.3333C17.0002 17.597 16.4032 17 15.6668 17C14.9304 17 14.3335 17.597 14.3335 18.3333C14.3335 19.0697 14.9304 19.6667 15.6668 19.6667Z"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3333 19.6667C11.0697 19.6667 11.6667 19.0697 11.6667 18.3333C11.6667 17.597 11.0697 17 10.3333 17C9.59695 17 9 17.597 9 18.3333C9 19.0697 9.59695 19.6667 10.3333 19.6667Z"
        stroke="#535c69"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  cloud: (
    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0303 10.4696C15.3232 10.7625 15.3232 11.2374 15.0303 11.5303L11.5303 15.0303C11.2374 15.3232 10.7625 15.3232 10.4696 15.0303L8.46962 13.0303C8.17673 12.7374 8.17673 12.2625 8.46962 11.9696C8.76252 11.6767 9.23739 11.6767 9.53028 11.9696L11 13.4393L13.9696 10.4696C14.2625 10.1767 14.7374 10.1767 15.0303 10.4696Z"
        fill="#535c69"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9318 6.20825C11.3815 6.20825 10.0405 7.11058 9.40807 8.42206C9.23802 8.77467 8.82537 8.93718 8.46055 8.79522C7.96981 8.60427 7.43535 8.49913 6.87455 8.49913C4.45808 8.49913 2.49915 10.4581 2.49915 12.8745C2.49915 15.291 4.45808 17.2499 6.87455 17.2499H18.5232C20.1677 17.2499 21.5008 15.9168 21.5008 14.2724C21.5008 12.6279 20.1677 11.2948 18.5232 11.2948C18.2556 11.2948 17.9974 11.3299 17.7523 11.3954C17.5121 11.4595 17.2558 11.4005 17.0678 11.2377C16.8799 11.0749 16.7848 10.8296 16.814 10.5827C16.832 10.4305 16.8413 10.2753 16.8413 10.1177C16.8413 7.95858 15.091 6.20825 12.9318 6.20825ZM8.37996 7.19396C9.34171 5.69967 11.0204 4.70825 12.9318 4.70825C15.8124 4.70825 18.167 6.9597 18.3321 9.79883C18.3955 9.79616 18.4592 9.79481 18.5232 9.79481C20.9961 9.79481 23.0008 11.7995 23.0008 14.2724C23.0008 16.7453 20.9961 18.7499 18.5232 18.7499H6.87455C3.62965 18.7499 0.999146 16.1194 0.999146 12.8745C0.999146 9.62964 3.62965 6.99913 6.87455 6.99913C7.39421 6.99913 7.89893 7.06678 8.37996 7.19396Z"
        fill="#535c69"
      />
    </svg>
  ),
  edit: (
    <svg width="17px" height="17px" viewBox="0 0 24 24" focusable="false" fill="currentColor">
      <path d="M0 0h24v24H0z" fill="none"></path>
      <path d="M18.41 5.8L17.2 4.59c-.78-.78-2.05-.78-2.83 0l-2.68 2.68L3 15.96V20h4.04l8.74-8.74 2.63-2.63c.79-.78.79-2.05 0-2.83zM6.21 18H5v-1.21l8.66-8.66 1.21 1.21L6.21 18zM11 20l4-4h6v4H11z"></path>
    </svg>
  ),
  CALENDER: (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="4.5"
        y="5"
        width="16"
        height="16"
        rx="2"
        stroke="#6D788D"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16.5 3V7" stroke="#6D788D" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.5 3V7" stroke="#6D788D" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 11H20.5" stroke="#6D788D" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
      <rect
        x="8.5"
        y="15"
        width="2"
        height="2"
        stroke="#6D788D"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  OVERDUECALENDER: (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="4.5"
        y="5"
        width="16"
        height="16"
        rx="2"
        stroke="#F8285A"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16.5 3V7" stroke="#F8285A" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.5 3V7" stroke="#F8285A" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 11H20.5" stroke="#F8285A" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
      <rect
        x="8.5"
        y="15"
        width="2"
        height="2"
        stroke="#F8285A"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  TODAYCALENDER: (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="4.5"
        y="5"
        width="16"
        height="16"
        rx="2"
        stroke="#008000"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16.5 3V7" stroke="#008000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.5 3V7" stroke="#008000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 11H20.5" stroke="#008000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
      <rect
        x="8.5"
        y="15"
        width="2"
        height="2"
        stroke="#008000"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

const Icon = ({ name, ...props }: any) => {
  return <span {...props}>{svgMap[name] || null}</span>;
};

export default Icon;
