import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../utils/axiosInstance";

export interface TeamMemberInterface {
  full_name: string;
  _id: string;
  email?: string;
}

interface TeamMemberState {
  assignList: TeamMemberInterface[];
  loading: boolean;
  error: string | null;
}

const initialState: TeamMemberState = {
  assignList: [],
  loading: false,
  error: null,
};
export const fetchTeamMemberList = createAsyncThunk(
  "teamMembers/fetchTeamMemberList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_USER_SERVICE}/list`);
      return response?.data?.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message || "Failed to fetch team member list");
    }
  }
);

const commonDataSlice = createSlice({
  name: "teamMembers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTeamMemberList.fulfilled, (state, action) => {
      state.loading = false;
      state.assignList = action.payload;
    });
  },
});

export default commonDataSlice.reducer;
