//  CardFooter
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, Flex, Input, List, Popover, Tooltip, Typography, UploadFile } from "antd";
import { IoIosCheckboxOutline } from "react-icons/io";
import { GoTag } from "react-icons/go";
import _ from "lodash";
import { AppDispatch, RootState } from "../../store";
import {
  BellIcon,
  UserPlusIcon,
  ColorPickerIcon,
  FrameIcon,
  MoreOptionsIcon,
  UndoIcon,
  RedoIcon,
} from "./NotesFooterIcons";
import { NOTES_FOOTER_TYPE } from "../../utils/const";
import { getUniqIdsOnly } from "../../utils/commonFunctions";
import { TeamMemberInterface } from "../../slices/commonDataSlice";
import { addLabelByNoteId, createLabel, IFilterLabel, ILabels, IShareList } from "../../slices/notesSlice";
import styleCss from "./style.module.scss";

interface CardFooterProps {
  isHover: boolean;
  onColorChange: (color: string) => void;
  onCheckboxToggle: () => void;
  showCheckboxes: boolean;
  id?: string;
  onUserListChange: (val: any[]) => void;
  selectedLabels: any[];
  setSelectedLabels: (val: any[]) => void;
  userShareList: any[];
  reminder: string;
  setReminder: (data: string) => void;
  isRemainderOptionOpen: boolean;
  setIsRemainderOptionOpen: (data: boolean) => void;
  uploadFileList?: UploadFile[];
  setUploadFileList?: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  footerType: string | undefined;
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  handleRedo?: () => void | undefined;
  handleUndo?: () => void | undefined;
  canRedo?: boolean;
  canUndo?: boolean;
}

const CardFooter: React.FC<CardFooterProps> = ({
  isHover,
  onColorChange,
  onCheckboxToggle,
  showCheckboxes,
  id = "",
  onUserListChange,
  selectedLabels,
  setSelectedLabels,
  userShareList,
  reminder,
  setReminder,
  isRemainderOptionOpen,
  setIsRemainderOptionOpen,
  uploadFileList,
  setUploadFileList,
  footerType = NOTES_FOOTER_TYPE.cardBottom,
  setIsModalOpen,
  handleRedo,
  handleUndo,
  canRedo,
  canUndo,
}) => {
  const { user } = useSelector((state: RootState) => state.user);
  const { assignList } = useSelector((state: RootState) => state.commonData);
  const { allNotesList } = useSelector((state: RootState) => state.notes);

  const [isColorPopoverOpen, setIsColorPopoverOpen] = useState(false);
  const [isMoreOptionOpen, setIsMoreOptionOpen] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [teamList, setTeamList] = useState<TeamMemberInterface[]>([]);
  const [shareList, setShareList] = useState<IShareList[]>([]);
  const [isAddLabelPopoverVisible, setIsAddLabelPopoverVisible] = useState(false);
  const [searchLabel, setSearchLabel] = useState("");
  const { allLabelList } = useSelector((state: RootState) => state.notes);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!user || !assignList?.length) return;

    const filteredNote = allNotesList?.find((note) => note?.note?._id === id);
    const creatorId = filteredNote?.note?.created_by;
    const updatedTeamList = assignList.filter(
      (member: TeamMemberInterface) => member._id !== user._id && member._id !== creatorId
    );

    setTeamList(updatedTeamList);
  }, [assignList, user, id]);

  useEffect(() => {
    setShareList(userShareList);
  }, [userShareList]);

  const handleVisibleChange = (visible: boolean) => {
    setIsColorPopoverOpen(visible);
  };

  const handleAddLabelPopoverVisibleChange = (visible: boolean) => {
    setIsAddLabelPopoverVisible(visible);
    if (_.isBoolean(visible) && !visible) {
      setSearchLabel("");
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchLabel(e.target.value);
  };

  const handleAddLabel = (label: string) => {
    if (_.trim(label) && !_.find(allLabelList, (item) => _.get(item, "title") === label)) {
      dispatch(createLabel(label));
      setSearchLabel("");
    }
  };

  const filteredLabels = _.filter(allLabelList, (label) =>
    _.toLower(_.get(label, "title", "")).includes(_.toLower(searchLabel))
  );

  const handleLabelChange = async (checked: boolean, label: ILabels) => {
    const updatedLabels = checked
      ? _.concat(selectedLabels, { title: _.get(label, "title"), _id: _.get(label, "_id") })
      : _.filter(selectedLabels, (l) => _.get(l, "_id") !== _.get(label, "_id"));

    setSelectedLabels(updatedLabels);

    if (id && footerType !== NOTES_FOOTER_TYPE.bulkAction) {
      dispatch(addLabelByNoteId({ noteId: id, labelIds: getUniqIdsOnly(updatedLabels) }));
    }
  };

  const addLabelPopover = (
    <div>
      <Typography>Label note</Typography>
      <Input.Search
        placeholder="Search labels"
        value={searchLabel}
        onChange={handleSearchChange}
        onSearch={handleAddLabel}
        enterButton="Add label"
      />
      <List>
        {filteredLabels?.map((label: IFilterLabel) => {
          return (
            <List.Item key={label?._id}>
              <Checkbox
                checked={!!selectedLabels?.find((item) => item?._id === label?._id)}
                onChange={(e) => handleLabelChange(e.target.checked, label)}
              >
                {label?.title}
              </Checkbox>
            </List.Item>
          );
        })}
      </List>
    </div>
  );

  return (
    <>
      {footerType === NOTES_FOOTER_TYPE.cardBottom ? (
        <>
          <div className={`${styleCss.placeholder} ${styleCss.showSpace}`}></div>
          <Flex
            justify="space-between"
            className={`${styleCss.cardFooter} ${
              !isHover && !isColorPopoverOpen && !isMoreOptionOpen && !isShareOpen && !isRemainderOptionOpen
                ? ""
                : styleCss.showBlock
            }`}
            onClick={(event) => event.stopPropagation()}
          >
            <BellIcon
              isRemainderOptionOpen={isRemainderOptionOpen}
              setIsRemainderOptionOpen={setIsRemainderOptionOpen}
              reminder={reminder}
              setReminder={setReminder}
              footerType={footerType}
            />
            <UserPlusIcon
              shareOpen={isShareOpen}
              setSherOpen={setIsShareOpen}
              teamList={teamList}
              userList={onUserListChange}
              shareList={shareList}
            />
            <ColorPickerIcon
              onColorChange={onColorChange}
              handleVisibleChange={handleVisibleChange}
              isColorPopoverOpen={isColorPopoverOpen}
              footerType={footerType}
            />
            <FrameIcon id={id} footerType={footerType} />
            <MoreOptionsIcon
              isMoreOptionOpen={isMoreOptionOpen}
              setIsMoreOptionOpen={setIsMoreOptionOpen}
              onCheckboxToggle={onCheckboxToggle}
              showCheckboxes={showCheckboxes}
              id={id}
              selectedLabels={selectedLabels}
              setSelectedLabels={setSelectedLabels}
              footerType={footerType}
            />
          </Flex>
        </>
      ) : (
        <>
          <BellIcon
            isRemainderOptionOpen={isRemainderOptionOpen}
            setIsRemainderOptionOpen={setIsRemainderOptionOpen}
            reminder={reminder}
            setReminder={setReminder}
            footerType={footerType}
          />
          <UserPlusIcon
            shareOpen={isShareOpen}
            setSherOpen={setIsShareOpen}
            teamList={teamList}
            userList={onUserListChange}
            shareList={shareList}
          />
          <ColorPickerIcon
            onColorChange={onColorChange}
            handleVisibleChange={handleVisibleChange}
            isColorPopoverOpen={isColorPopoverOpen}
            footerType={footerType}
          />
          <FrameIcon
            uploadFileList={uploadFileList}
            setUploadFileList={setUploadFileList}
            id={id}
            footerType={footerType}
          />
          {footerType === NOTES_FOOTER_TYPE.createNote ? (
            <>
              <Tooltip title="Add label">
                <Popover
                  overlayClassName={styleCss.addLabel}
                  content={addLabelPopover}
                  trigger="click"
                  visible={isAddLabelPopoverVisible}
                  onVisibleChange={handleAddLabelPopoverVisibleChange}
                  placement="bottom"
                >
                  <Button type="link" icon={<GoTag size={16} color="#444444" />} />
                </Popover>
              </Tooltip>
              <Tooltip title="Show checkbox">
                <Button
                  type="link"
                  icon={<IoIosCheckboxOutline size={16} color="#444444" />}
                  onClick={onCheckboxToggle}
                />
              </Tooltip>
            </>
          ) : (
            <MoreOptionsIcon
              isMoreOptionOpen={isMoreOptionOpen}
              setIsMoreOptionOpen={setIsMoreOptionOpen}
              onCheckboxToggle={onCheckboxToggle}
              showCheckboxes={showCheckboxes}
              id={id}
              selectedLabels={selectedLabels}
              setSelectedLabels={setSelectedLabels}
              footerType={footerType}
              setIsModalOpen={setIsModalOpen ? () => setIsModalOpen((prev) => !prev) : undefined}
            />
          )}
          <UndoIcon handleUndo={handleUndo} canUndo={canUndo} />
          <RedoIcon handleRedo={handleRedo} canRedo={canRedo} />
        </>
      )}
    </>
  );
};

export default CardFooter;
