// src/services/websocketService.ts

import { io, Socket } from "socket.io-client";

class WebSocketService {
  private socket: Socket | null = null;

  connect(url: string): void {
    this.socket = io(url);

    this.socket.on("connect", () => {
      console.log("WebSocket connected");
    });

    this.socket.on("disconnect", () => {
      console.log("WebSocket disconnected");
    });
  }

  disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  sendMessage(event: string, message: any): void {
    if (this.socket) {
      this.socket.emit(event, message);
    }
  }

  onMessage(event: string, callback: (message: any) => void): void {
    if (this.socket) {
      this.socket.on(event, callback);
    }
  }

  offMessage(event: string): void {
    if (this.socket) {
      this.socket.off(event);
    }
  }
  isConnected(): boolean {
    return this.socket ? this.socket.connected : false;
  }
}

const webSocketService = new WebSocketService();
export default webSocketService;
