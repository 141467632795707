import { useState } from "react";
import { Button, Col, Flex, List, Row, Typography, Upload, UploadProps } from "antd";
import FormCss from "./form.module.scss";
import { CloseOutlined, DeleteOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { InputBox } from "../../../components/FormInput";
import { AppDispatch, RootState } from "../../../store";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { deleteOtherDoc, HistoryItem, setOtherDocHistory } from "../../../slices/vendorSlice";
import { FiUpload } from "react-icons/fi";
import dayjs from "dayjs";

const { Title } = Typography;
interface OtherDocPropsInterface {
  form: any;
}

const VisitingCard = ({ form }: OtherDocPropsInterface) => {
  const dispatch = useDispatch<AppDispatch>();
  const { otherDocHistory } = useSelector((state: RootState) => state.vendor);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [selectedDocType, setSelectedDocType] = useState<string | null>(null);

  const [addPassport, setAddPassport] = useState(false);

  const toggleDoc = () => {
    setAddPassport(!addPassport);
  };

  const props: UploadProps = {
    name: "file",
    multiple: false,
    accept: ".pdf ",
    listType: "picture",
    showUploadList: true,
    beforeUpload: () => false,
    onChange: (info) => {
      const { status } = info.file;
      if (status !== "uploading" && status !== "removed") {
        const file = info.fileList[info.fileList.length - 1];
        if (file.originFileObj) {
          const reader = new FileReader();
          reader.onloadend = () => {
            const blob = new Blob([reader.result as string], { type: file.type });
            const url = URL.createObjectURL(blob);
            const updatedFile = {
              ...file,
              previewUrl: url,
            };
            setUploadedFile(updatedFile);
          };
          reader.readAsArrayBuffer(file.originFileObj);
        }
      }
    },
    onRemove: () => {
      setUploadedFile(null);
    },
    style: { backgroundColor: "white" },
    fileList: uploadedFile ? [uploadedFile] : [],
  };

  const handleUpload = () => {
    if (!uploadedFile || !selectedDocType) return;

    const newHistoryItem: HistoryItem = {
      id: Date.now().toString(),
      name: selectedDocType,
      uploadDate: dayjs().format("DD-MM-YYYY"),
      url: uploadedFile.previewUrl,
      file: uploadedFile,
      docType: selectedDocType,
    };

    const updatedHistory = otherDocHistory ? [...otherDocHistory, newHistoryItem] : [newHistoryItem];

    dispatch(setOtherDocHistory({ history: updatedHistory }));

    setUploadedFile(null);
    setSelectedDocType(null);
    form.setFieldsValue({
      documentType: "",
    });
  };

  const handleView = (url: string) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  const handleDelete = (id: string) => {
    dispatch(deleteOtherDoc({ itemId: id }));
  };

  return (
    <>
      <div className="pt-5 pb-5 pl-5 pr-5">
        <Button
          onClick={toggleDoc}
          type="primary"
          className={`${FormCss.addButton} mb-3`}
          block
          icon={<PlusOutlined />}
        >
          Upload Document
        </Button>
        {addPassport && (
          <div className={`${FormCss.uploadCard} mb-2`}>
            <Flex justify="space-between" align="center" className="card-header">
              <Title className="card-title" level={2}>
                Upload
              </Title>
              <Button onClick={toggleDoc} type="link" style={{ color: "inherit" }}>
                <CloseOutlined style={{ fontSize: "15px" }} />
              </Button>
            </Flex>
            <div className="card-body">
              <div className="space-wrapper">
                <div className="passport-dropbox-md">
                  <Row gutter={[0, 15]}>
                    <Col span={24}>
                      <Upload.Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                          <FiUpload size={40} color="#99A1BB" />
                        </p>
                        <p className="ant-upload-text" style={{ color: "#1A73E8" }}>
                          Click or drag file to this area to upload (PDF only)
                        </p>
                        <p className="ant-upload-hint" style={{ color: "#99A1BB" }}>
                          Upload a PDF format. Maximum size: 2MB.
                        </p>
                      </Upload.Dragger>
                    </Col>
                    <Col span={24}>
                      <InputBox
                        type="text"
                        placeholder="Enter Document Type"
                        value={selectedDocType || ""}
                        onChange={(e: any) => setSelectedDocType(e.target.value)}
                        className="input-field"
                        label="Document Type"
                        style={{ marginBottom: "0px" }}
                        name="documentType"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <Flex justify="flex-end">
                <Button
                  className={FormCss.formButton}
                  type="primary"
                  onClick={handleUpload}
                  disabled={!!!uploadedFile || !!!selectedDocType}
                >
                  Upload
                </Button>
              </Flex>
            </div>
          </div>
        )}
        {otherDocHistory && (
          <div className={`${FormCss.uploadCard}`}>
            <Flex justify="space-between" align="center" className="card-header">
              <Title level={3} style={{ marginBottom: 0 }} className={FormCss.titleLevel3}>
                Uploaded Documents
              </Title>
            </Flex>
            <div className="card-body">
              <List
                size="small"
                className={FormCss.docList}
                header={
                  <div className="doc-list-header">
                    <div>Name</div>
                    <div>Uploaded Date</div>
                    <div>Action</div>
                  </div>
                }
                dataSource={otherDocHistory}
                renderItem={(item: HistoryItem) => (
                  <List.Item className="doc-list-item" key={item.id}>
                    <div>{item.name}</div>
                    <div>{item.uploadDate}</div>
                    <div>
                      <Flex gap={16}>
                        <Button
                          style={{ color: "rgba(153, 161, 187, 1)" }}
                          type="link"
                          icon={<EyeOutlined />}
                          onClick={() => item.url && handleView(item.url)}
                        />
                        <Button
                          style={{ color: "rgba(153, 161, 187, 1)" }}
                          type="link"
                          icon={<DeleteOutlined />}
                          onClick={() => handleDelete(item.id)}
                        />
                      </Flex>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default VisitingCard;
