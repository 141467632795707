// axiosInstance.ts
import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import { LocalStorageService } from "./LocalStorage";
import store from "../store";
import { isLogin, setProfileData, setUserData } from "../slices/userSlice";

const axiosInstance: AxiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    const token = LocalStorageService.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      LocalStorageService.removeItem("token");
      LocalStorageService.removeItem("user");
      store.dispatch(isLogin(false));
      store.dispatch(setProfileData(null));
      store.dispatch(setUserData(null));
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
