import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Form } from "antd";
import "react-phone-number-input/style.css";

interface ContactInputInterface {
  form: any;
  name: any;
  label?: string;
  onChange?: (value: string) => void;
  required?: boolean;
  value?: string;
  style?: React.CSSProperties;
}

function ContactInput({ form, name, label, onChange, required = true, value, style }: ContactInputInterface) {
  return (
    <Form.Item
      name={name}
      rules={
        required
          ? [
              {
                required: true,
                message: "Please enter phone number!",
              },
              {
                validator(_, value) {
                  if (value && !isValidPhoneNumber(value)) {
                    return Promise.reject(new Error("Invalid Contact Number"));
                  }
                  return Promise.resolve();
                },
              },
            ]
          : [] // No rules if validation is disabled
      }
      label={label}
    >
      <PhoneInput
        defaultCountry="IN"
        value={value ? value : form.getFieldValue(name) || "+91"}
        onChange={(val) => {
          const newValue = val ?? "+91";
          form.setFieldValue(name, newValue);
          if (onChange) {
            onChange(newValue);
          }
        }}
        placeholder={`Enter ${label || "contact number"}`}
        limitMaxLength
        autoComplete="false"
        style={style}
      />
    </Form.Item>
  );
}

export default ContactInput;
