// awsConfig.ts
import AWS from "aws-sdk";

// Configure AWS
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME;
const s3 = new AWS.S3({
  signatureVersion: "v4",
});
export const getDocumentURL = (key: any) => {
  try {
    // const params = {
    //     Bucket: bucketName,
    //     Key: key,
    //     ACL: 'public-read',
    // };
    // const url = s3.getSignedUrl("getObject", params);
    let url = `https://${bucketName}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${key}`;
    return url;
  } catch (error) {
    console.log("error", error);
    return "";
  }
};

export const uploadFile = (file: any, key: string) => {
  try {
    const params: any = {
      ACL: "public-read",
      Body: file,
      Bucket: bucketName,
      Key: key,
    };
    s3.putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log("Progress:", Math.round((evt.loaded / evt.total) * 100) + "%");
      })
      .send((err) => {
        if (err) {
          console.log("Error uploading file:", err);
        } else {
          console.log("Successfully uploaded file.");
        }
      });
  } catch (error) {
    console.log("error", error);
  }
};
export const downloadDocument = (key: string, type: string, fileName?: string) => {
  const params: any = {
    Bucket: bucketName,
    Key: key,
  };
  s3.getObject(params, (err, data: any) => {
    if (err) {
      console.log(err, err.stack);
    } else {
      const base64Data = data.Body.toString("base64");
      const base64WithPrefix = `data:${data.ContentType};base64,${base64Data}`;
      const link = document.createElement("a");
      link.href = base64WithPrefix;
      link.download = `${fileName || "download"}.${type}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  });
};
