import { useEffect, useRef, useState } from "react";
import {
  Checkbox,
  Flex,
  Form,
  Input,
  Typography,
  Image,
  InputRef,
  MenuProps,
  Avatar,
  Tooltip,
  Dropdown,
  Space,
  Tag,
  UploadProps,
  UploadFile,
  GetProp,
  Divider,
  Button,
  Collapse,
} from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { TweenOneGroup } from "rc-tween-one";
import dayjs from "dayjs";
import { RiCloseLine, RiPushpin2Fill, RiPushpin2Line } from "react-icons/ri";
import { FiClock, FiPlus } from "react-icons/fi";
import { FaCaretDown } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { MdDragIndicator } from "react-icons/md";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { getBase64, getInitials, getUniqIdsOnly } from "../../utils/commonFunctions";
import { NOTES_FOOTER_TYPE } from "../../utils/const";
import { ITags } from "../../utils/interface";
import { TeamMemberInterface } from "../../slices/commonDataSlice";
import { addNote, IHistory, INote, INoteContent, ISelectedLabels } from "../../slices/notesSlice";
import { CheckBoxInCrateNote, FrameIcon } from "./NotesFooterIcons";
import CardFooter from "./CardFooter";
import FileListItem from "../../components/FileListItem";
import commonCSS from "../../css/commonStyle.module.scss";
import styleCss from "./style.module.scss";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];
const Panel = Collapse.Panel;

const CreateNote = () => {
  const { user } = useSelector((state: RootState) => state.user);
  const { assignList } = useSelector((state: RootState) => state.commonData);
  const InputRef = useRef<InputRef>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [taskForm] = Form.useForm();
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  const [isExpanded, setIsExpanded] = useState(true);
  const [uploadFileList, setUploadFileList] = useState<UploadFile[]>([]);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [noteContent, setNoteContent] = useState<string | INoteContent[] | null>("");
  const [noteTitle, setNoteTitle] = useState("");
  const [history, setHistory] = useState<IHistory[]>([]);
  const [historyIndex, setHistoryIndex] = useState<number>(-1);
  const [isRedoUndo, setIsRedoUndo] = useState(false);
  const [isNotesPin, setIsNotesPin] = useState<boolean>(false);
  const [shareNote, setShareNote] = useState<string[]>([]);
  const [selectedLabels, setSelectedLabels] = useState<ISelectedLabels[]>([]);
  const [reminder, setReminder] = useState<string | null>(null);
  const [isRemainderOpen, setIsRemainderOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState({
    isPreviewOpen: false,
    previewUrl: "",
  });
  const [uncheckedItems, setUncheckedItems] = useState<INoteContent[]>([]);
  const [checkedItems, setCheckedItems] = useState<INoteContent[]>([]);
  const colors = ["#fa7777", "#30bfee"];
  const [isCheckedCollapse, setIsCheckedCollapse] = useState<boolean>(true);
  let isShareAll = false;

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Share all",
      onClick: () => {
        isShareAll = true;
        taskForm.submit();
      },
    },
  ];

  useEffect(() => {
    if (showCheckboxes && Array.isArray(noteContent)) {
      setUncheckedItems(showCheckboxes ? _.filter(noteContent, (line) => !_.get(line, "checked")) : []);
      setCheckedItems(showCheckboxes ? _.filter(noteContent, (line) => _.get(line, "checked")) : []);
    }
  }, [showCheckboxes]);

  useEffect(() => {
    if (showCheckboxes) {
      setNoteContent(_.concat(uncheckedItems || [], checkedItems || []));
    }
  }, [uncheckedItems, checkedItems, showCheckboxes]);

  useEffect(() => {
    if (_.size(uploadFileList)) {
      setIsExpanded(true);
    }
  }, [uploadFileList]);

  const canUndo = historyIndex > 0;
  const canRedo = historyIndex < history.length - 1;

  useEffect(() => {
    if (!isRedoUndo) {
      const previousState = history[historyIndex];
      const hasChanges =
        !_.isEqual(previousState?.noteContent, noteContent) ||
        previousState?.noteTitle !== noteTitle ||
        previousState?.showCheckboxes !== showCheckboxes ||
        !_.isEqual(previousState?.uploadFileList, uploadFileList);

      if (hasChanges) {
        const setHistoryTimeout = setTimeout(() => {
          const historyList: IHistory[] = [...history];
          const historyObj: IHistory = {
            noteContent,
            noteTitle,
            showCheckboxes,
            uploadFileList,
          };

          if (historyIndex + 1 < historyList.length) {
            historyList.splice(historyIndex + 1);
          }

          historyList.push(historyObj);
          setHistory(historyList);
          setHistoryIndex(historyList.length - 1);
        }, 300);

        return () => clearTimeout(setHistoryTimeout);
      }
    } else {
      setIsRedoUndo(false);
    }
  }, [noteContent, noteTitle, showCheckboxes, uploadFileList, historyIndex]);

  const handleUndo = () => {
    if (canUndo) {
      setIsRedoUndo(true);
      setHistoryIndex(historyIndex - 1);
    }
  };

  const handleRedo = () => {
    if (canRedo) {
      setIsRedoUndo(true);
      setHistoryIndex(historyIndex + 1);
    }
  };

  useEffect(() => {
    if (historyIndex >= 0 && historyIndex < history.length) {
      const currentState = history[historyIndex];
      setNoteContent(currentState.noteContent ?? null);
      setNoteTitle(currentState.noteTitle ?? "");
      setShowCheckboxes(currentState.showCheckboxes ?? false);
      setUploadFileList(currentState.uploadFileList ?? []);

      if (currentState.showCheckboxes && Array.isArray(currentState.noteContent)) {
        const checked = currentState.noteContent.filter((item: INoteContent) => item.checked);
        const unchecked = currentState.noteContent.filter((item: INoteContent) => !item.checked);
        setCheckedItems(checked);
        setUncheckedItems(unchecked);
      }

      taskForm.setFieldValue("title", currentState.noteTitle ?? "");
    }
  }, [historyIndex]);

  const createNote = async (val: INote) => {
    const formData = new FormData();
    let hasContent = false;

    formData.append("descriptionType", showCheckboxes ? "checkbox" : "text");
    formData.append("bg_color", backgroundColor ?? "#ffffff");
    formData.append("is_pinned", `${isNotesPin}`);

    if (!_.isNil(reminder)) {
      formData.append("reminder", reminder ?? null);
      hasContent = true;
    }

    if (!_.isEmpty(val.title)) {
      if (typeof val.title === "string") {
        formData.append("title", val.title);
        hasContent = true;
      }
    }

    if (!_.isEmpty(noteContent)) {
      formData.append(
        showCheckboxes ? "checkList" : "description",
        typeof noteContent == "string" ? noteContent : JSON.stringify(noteContent)
      );
      hasContent = true;
    }

    if (!_.isEmpty(uploadFileList)) {
      _.forEach(uploadFileList, (file: any, index: number) => {
        formData.append(`doc_${index + 1}`, file.originFileObj);
      });
      hasContent = true;
    }

    if (!_.isEmpty(selectedLabels)) {
      formData.append("tags", JSON.stringify(getUniqIdsOnly(selectedLabels)));
    }

    if (isShareAll) {
      const teamList = _.map(
        _.filter(assignList, (dt: TeamMemberInterface) => dt._id !== user?._id),
        "_id"
      );
      formData.append("shareTo", JSON.stringify(teamList));
      hasContent = true;
    } else if (!_.isEmpty(shareNote)) {
      formData.append("shareTo", JSON.stringify(shareNote));
      hasContent = true;
    }

    if (hasContent) {
      const response = await dispatch(addNote(formData));
      if (response?.payload) {
        taskForm.resetFields();
        setNoteContent(null);
        setUploadFileList([]);
        setIsNotesPin(false);
        setShareNote([]);
        setBackgroundColor("#ffffff");
        setSelectedLabels([]);
        setReminder(null);
        setShowCheckboxes(false);
        setNoteTitle("");
        setHistory([]);
        setHistoryIndex(-1);
      }
    }
  };

  const parseContent = (text: string) =>
    _.map(_.split(text, "\n"), (line: string) => ({ title: line, checked: false }));

  const handleSubmit = () => {
    isShareAll = false;
    _.invoke(taskForm, "submit");
  };

  const handleColorChange = (color: string) => {
    setBackgroundColor(color);
  };

  const handleCheckboxChange = (index: number, isUncheckedList: boolean) => {
    if (isUncheckedList) {
      const itemToMove = _.nth(uncheckedItems, index);
      setUncheckedItems((prevItems) => _.filter(prevItems, (_, i) => i !== index));
      setCheckedItems((prevItems) => [...prevItems, { ...itemToMove, checked: true }]);
    } else {
      const itemToMove = _.nth(checkedItems, index);
      setCheckedItems((prevItems) => _.filter(prevItems, (_, i) => i !== index));
      setUncheckedItems((prevItems) => [...prevItems, { ...itemToMove, checked: false }]);
    }
  };

  const handleLineTextChange = (index: number, text: string, isUncheckedList: boolean) => {
    if (isUncheckedList) {
      setUncheckedItems((prevItems) => {
        const newItems = _.cloneDeep(prevItems);
        _.set(newItems, `[${index}].title`, text);
        return newItems;
      });
    } else {
      setCheckedItems((prevItems) => {
        const newItems = _.cloneDeep(prevItems);
        _.set(newItems, `[${index}].title`, text);
        return newItems;
      });
    }
  };

  const handleAddNewLine = () => {
    if (_.size(uncheckedItems) && !_.last(uncheckedItems)?.title) return;

    setUncheckedItems((prevItems) => {
      return _.isArray(prevItems) ? [...prevItems, { title: "", checked: false }] : [{ title: "", checked: false }];
    });
  };

  const handleAddNewLineAtIndex = (index: number, isChecked: boolean) => {
    const newLine = { title: "", checked: isChecked };

    if (isChecked) {
      setCheckedItems((prevItems: INoteContent[]) => {
        const newCheckedItems = _.cloneDeep(prevItems);
        _.pullAt(newCheckedItems, index + 1);
        newCheckedItems.splice(index + 1, 0, newLine);
        return newCheckedItems;
      });
    } else {
      setUncheckedItems((prevItems: INoteContent[]) => {
        const newUncheckedItems = _.cloneDeep(prevItems);
        _.pullAt(newUncheckedItems, index + 1);
        newUncheckedItems.splice(index + 1, 0, newLine);
        return newUncheckedItems;
      });
    }
  };

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNoteContent(e.target.value);
    setIsRedoUndo(false);
  };

  const handleRemoveLine = (index: number, isUncheckedList: boolean) => {
    if (isUncheckedList) {
      setUncheckedItems((prevItems) => _.filter(prevItems, (_, i) => i !== index));
    } else {
      setCheckedItems((prevItems) => _.filter(prevItems, (_, i) => i !== index));
    }
  };

  const handleCheckboxToggle = () => {
    setIsExpanded(true);
    setShowCheckboxes((prev) => {
      const newNoteContent = prev
        ? Array.isArray(noteContent)
          ? noteContent.map((line: INoteContent) => line.title).join("\n")
          : noteContent
        : parseContent(noteContent as string);

      setNoteContent(newNoteContent);
      return !prev;
    });
  };

  const handlePinNote = () => {
    setIsNotesPin((prev) => !prev);
  };

  const handleUserListChange = (userList: string[]) => {
    setShareNote(_.cloneDeep(userList));
  };

  const getFullNameById = (id: string) => {
    const user = _.find(assignList, { _id: id });
    return user ? user.full_name : undefined;
  };

  const handleRemoveNoteLabel = (label: ISelectedLabels) => {
    const updatedLabels = _.filter(selectedLabels, (l) => l?._id !== label?._id);
    setSelectedLabels(updatedLabels);
  };

  const handleEditReminder = () => {
    setIsRemainderOpen(true);
  };

  const removeReminder = () => {
    setReminder(null);
  };

  // Called only on remove
  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }: any) => {
    setUploadFileList(newFileList);
  };

  const handlePreview = async (file: UploadFile) => {
    let fileType = ["jpeg", "png", "jpg"];
    if (fileType.includes(file.name.split(".")[1])) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as FileType);
      }
      setImagePreview({
        isPreviewOpen: true,
        previewUrl: file.url || (file.preview as string),
      });
    } else {
      window.open(file.url, "_blank")?.focus();
    }
  };

  const handleKeyPress = (index: number, e: React.KeyboardEvent<HTMLDivElement>, isUncheckedList: boolean) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const classPrefix = isUncheckedList ? "editable-div-" : "checkedEditable-div-";
      handleAddNewLineAtIndex(index, !isUncheckedList);
      setTimeout(() => {
        const nextElement = document.querySelector(`.${classPrefix}${index + 1}`);
        if (nextElement) {
          (nextElement as HTMLElement).focus();
        }
      }, 0);
    } else if (e.key === "Backspace" && e.currentTarget.textContent === "") {
      e.preventDefault();
      const classPrefix = isUncheckedList ? "editable-div-" : "checkedEditable-div-";
      handleRemoveLine(index, isUncheckedList);
      setTimeout(() => {
        const previousElement = document.querySelector(`.${classPrefix}${index - 1}`);
        if (previousElement) {
          (previousElement as HTMLElement).focus();
        }
      }, 0);
    }
  };

  const handleDragEnd = (result: any) => {
    const { source, destination } = result;
    if (!destination || source.index === destination.index) return;

    const newUncheckedItems = _.clone(uncheckedItems);
    const [movedItem] = newUncheckedItems.splice(source.index, 1);
    newUncheckedItems.splice(destination.index, 0, movedItem);
    setUncheckedItems(newUncheckedItems);
  };

  const handleCheckedDragEnd = (result: any) => {
    const { source, destination } = result;
    if (!destination || source.index === destination.index) return;

    const newCheckedItems = Array.from(checkedItems);
    const [movedItem] = newCheckedItems.splice(source.index, 1);
    newCheckedItems.splice(destination.index, 0, movedItem);
    setCheckedItems(newCheckedItems);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Tab") {
      event.preventDefault();
      InputRef.current?.focus();
    }
    if (_.isEqual(event.key, "Enter")) {
      event.preventDefault();
      InputRef.current?.focus();
    }
  };

  const removeDocWithTimeout = (val: UploadFile) => {
    let fileDataList = [...uploadFileList];
    fileDataList = fileDataList.filter((dt) => dt.name !== val.name);
    setUploadFileList(fileDataList);
  };

  return (
    <div className={styleCss.createNoteWrapper} style={{ backgroundColor: backgroundColor }}>
      <Form name="note" onFinish={createNote} form={taskForm}>
        {isExpanded ? (
          <>
            <div className="pin" onClick={handlePinNote}>
              {isNotesPin ? (
                <Tooltip title="Unpin note">
                  <RiPushpin2Fill size={24} color="#444444" />
                </Tooltip>
              ) : (
                <Tooltip title="Pin note">
                  <RiPushpin2Line size={24} color="#444444" />
                </Tooltip>
              )}
            </div>
            {uploadFileList?.length > 0 && (
              <>
                <div style={{ marginBottom: "15px" }} className="createImage modelImage">
                  {uploadFileList.map((file) => {
                    let ext: any = file.name.split(".").pop();
                    let imgExt = ["jpeg", "jpg", "png"];
                    console.log(file);

                    return (
                      <FileListItem file={file} imgExt={imgExt} ext={ext} removeDocHandle={removeDocWithTimeout} />
                    );
                  })}
                </div>
                {imagePreview?.isPreviewOpen && (
                  <Image
                    wrapperStyle={{ display: "none" }}
                    preview={{
                      visible: imagePreview?.isPreviewOpen,
                      onVisibleChange: (visible) =>
                        setImagePreview((prevState) => ({
                          ...prevState,
                          isPreviewOpen: visible,
                        })),
                      afterOpenChange: (visible) => {
                        if (!visible) {
                          setImagePreview((prevState) => ({
                            ...prevState,
                            previewUrl: "",
                          }));
                        }
                      },
                    }}
                    src={imagePreview?.previewUrl}
                  />
                )}
              </>
            )}

            <Form.Item className="title" name="title" style={{ marginBottom: "15px" }}>
              <Input
                placeholder="Title"
                variant="borderless"
                style={{ backgroundColor: "transparent", width: "97%" }}
                className="title-input"
                autoComplete="off"
                onKeyDown={handleKeyDown}
                tabIndex={0}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setNoteTitle(e.target.value);
                  setIsRedoUndo(false);
                }}
              />
            </Form.Item>

            <Form.Item className="noteField" name="description">
              <div style={{ marginBottom: `${isExpanded ? "5px" : ""}` }}>
                {showCheckboxes ? (
                  <>
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="unchecked-list">
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.droppableProps}>
                            {uncheckedItems?.map((line: INoteContent, index: number) => (
                              <Draggable key={index} draggableId={`unchecked-${index}`} index={index}>
                                {(provided) => (
                                  <div ref={provided.innerRef} {...provided.draggableProps} className="note-content">
                                    <Flex align="center" className="contain-div">
                                      <div
                                        className="dragHandleCreateNote"
                                        {...provided.dragHandleProps}
                                        style={{ paddingRight: "6px" }}
                                      >
                                        <MdDragIndicator size={24} color="#979797" style={{ display: "inline-flex" }} />
                                      </div>
                                      <Checkbox
                                        checked={line.checked}
                                        onChange={() => handleCheckboxChange(index, true)}
                                        style={{ marginRight: "10px" }}
                                      />
                                      <div
                                        contentEditable
                                        style={{ flexGrow: 1, outline: "none" }}
                                        className={`editable-div editable-div-${index}`}
                                        onBlur={(e: React.FocusEvent<HTMLDivElement>) =>
                                          handleLineTextChange(index, e.currentTarget.textContent || "", true)
                                        }
                                        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
                                          handleKeyPress(index, e, true)
                                        }
                                      >
                                        {line?.title}
                                      </div>
                                      <RiCloseLine
                                        size={22}
                                        onClick={() => handleRemoveLine(index, true)}
                                        className="delete-item"
                                        color="#979797"
                                      />
                                    </Flex>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    {showCheckboxes && (
                      <div className="listItem" onClick={() => setIsExpanded(true)} style={{ paddingLeft: "28px" }}>
                        <Button
                          style={{ color: "#717171", fontSize: "13px", padding: 0 }}
                          size="large"
                          type="link"
                          onClick={handleAddNewLine}
                          icon={<FiPlus size={18} color="#717171" />}
                        >
                          List item
                        </Button>
                      </div>
                    )}
                    {Array.isArray(noteContent) &&
                      noteContent.some((line) => !line.checked) &&
                      noteContent.some((line) => line.checked) && (
                        <Divider
                          style={{
                            borderColor: "rgba(100, 100, 100, 0.2)",
                            margin: "5px 10px 15px 25px",
                            minWidth: "auto",
                            width: "auto",
                          }}
                        />
                      )}

                    {!!checkedItems?.length && (
                      <DragDropContext onDragEnd={handleCheckedDragEnd}>
                        <Droppable droppableId="checked-list">
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                              <Collapse
                                bordered={false}
                                style={{ backgroundColor: "transparent" }}
                                className={styleCss.modalCheckedCollapse}
                                defaultActiveKey={isCheckedCollapse ? ["1"] : []}
                                onChange={() => setIsCheckedCollapse(!isCheckedCollapse)}
                              >
                                <Panel
                                  header={`${checkedItems?.length} Completed ${
                                    checkedItems?.length >= 2 ? "items" : "item"
                                  }`}
                                  key="1"
                                >
                                  {checkedItems?.map((line: INoteContent, index: number) => (
                                    <Draggable key={`checked-${index}`} draggableId={`checked-${index}`} index={index}>
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          className="note-content"
                                        >
                                          <div
                                            style={{ display: "flex", alignItems: "center" }}
                                            className="contain-div"
                                          >
                                            <div
                                              className="dragHandle"
                                              {...provided.dragHandleProps}
                                              style={{ paddingRight: "6px", display: "inline-flex" }}
                                            >
                                              <MdDragIndicator size={24} color="#979797" />
                                            </div>
                                            <Checkbox
                                              checked={line.checked}
                                              onChange={() => handleCheckboxChange(index, false)}
                                              style={{ marginRight: "10px" }}
                                            />
                                            <div
                                              contentEditable
                                              suppressContentEditableWarning={true}
                                              style={{
                                                flexGrow: 1,
                                                outline: "none",
                                                textDecoration: "line-through",
                                                color: "#5f6368",
                                              }}
                                              className={`checkedEditable-div checkedEditable-div-${index}`}
                                              onBlur={(e: React.FocusEvent<HTMLDivElement>) =>
                                                handleLineTextChange(index, e.currentTarget.textContent || "", false)
                                              }
                                              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
                                                handleKeyPress(index, e, false)
                                              }
                                            >
                                              {line?.title}
                                            </div>
                                            <RiCloseLine
                                              size={22}
                                              onClick={() => handleRemoveLine(index, false)}
                                              className="delete-item"
                                              color="#979797"
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </Panel>
                              </Collapse>
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    )}
                  </>
                ) : (
                  <div className="create-note-input">
                    <Input.TextArea
                      ref={InputRef}
                      autoSize={{ minRows: 1, maxRows: 18 }}
                      value={typeof noteContent == "string" ? noteContent : ""}
                      onChange={handleContentChange}
                      placeholder="Take a note..."
                      variant="borderless"
                      onClick={() => setIsExpanded(true)}
                      tabIndex={1}
                    />
                  </div>
                )}
              </div>
            </Form.Item>

            <TweenOneGroup
              appear={false}
              enter={{ scale: 0.8, opacity: 0, type: "from", duration: 100 }}
              leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
              onEnd={(e) => {
                if (e.type === "appear" || e.type === "enter") {
                  (e.target as any).style = "display: inline-block";
                }
              }}
            >
              <Flex wrap gap={5} className={styleCss.reminderLabel} style={{ margin: "10px 0" }}>
                {reminder && (
                  <Flex className="reminder" onClick={handleEditReminder} align="center" gap={4}>
                    <FiClock size={14} style={{ minWidth: "14px", maxWidth: "14px", width: "14px" }} color="#3c4043" />
                    <Typography.Text ellipsis>{dayjs(reminder)?.format("MMM D, h:mm A")}</Typography.Text>
                    <IoClose
                      onClick={(e) => {
                        e.stopPropagation();
                        removeReminder();
                      }}
                      size={14}
                      className="close"
                      color="#3c4043"
                    />
                  </Flex>
                )}
                {!!selectedLabels && selectedLabels.length > 0 && (
                  <>
                    {selectedLabels.map((tag: ITags) => (
                      <Tag
                        key={tag._id}
                        onClose={(e) => {
                          e.preventDefault();
                          handleRemoveNoteLabel(tag);
                        }}
                      >
                        <Flex align="center" gap={3}>
                          <Typography>{tag?.title}</Typography>
                          <IoClose
                            size={14}
                            onClick={(e) => {
                              e.preventDefault();
                              handleRemoveNoteLabel(tag);
                            }}
                            className="close"
                            color="#3c4043"
                          />
                        </Flex>
                      </Tag>
                    ))}
                  </>
                )}
              </Flex>
            </TweenOneGroup>

            {!!shareNote && shareNote.length > 0 && (
              <div style={{ margin: "15px 0px", paddingLeft: "5px" }}>
                <Avatar.Group
                  max={{
                    count: 3,
                    style: { color: "#ffffffd6", backgroundColor: "#76a3da", fontSize: "14px" },
                    popover: { rootClassName: "AsssignnePopover" },
                  }}
                  size={30}
                >
                  {shareNote?.map((asn: string, idx: number) => {
                    const fullName = getFullNameById(asn);
                    return (
                      <Tooltip title={fullName} key={idx}>
                        <Avatar
                          style={{
                            fontSize: "14px",
                            backgroundColor: colors[idx % colors.length],
                            marginLeft: "-5px",
                          }}
                        >
                          {getInitials(fullName)}
                        </Avatar>
                      </Tooltip>
                    );
                  })}
                </Avatar.Group>
              </div>
            )}

            <Flex align="center" justify="space-between" style={{ marginTop: "15px" }}>
              <Flex gap={20} className="footerBtn">
                <CardFooter
                  isHover={true}
                  onColorChange={handleColorChange}
                  onCheckboxToggle={handleCheckboxToggle}
                  showCheckboxes={showCheckboxes}
                  onUserListChange={handleUserListChange}
                  selectedLabels={selectedLabels}
                  setSelectedLabels={setSelectedLabels}
                  userShareList={shareNote.map((d) => ({ _id: d }))}
                  reminder={reminder ?? ""}
                  setReminder={setReminder}
                  isRemainderOptionOpen={isRemainderOpen}
                  setIsRemainderOptionOpen={setIsRemainderOpen}
                  uploadFileList={uploadFileList}
                  setUploadFileList={setUploadFileList}
                  footerType={NOTES_FOOTER_TYPE.createNote}
                  handleRedo={handleRedo}
                  handleUndo={handleUndo}
                  canRedo={canRedo}
                  canUndo={canUndo}
                />
              </Flex>
              <Space className="actionBtnWrapper">
                <Button
                  onClick={() => {
                    setIsExpanded(false);
                    handleSubmit();
                  }}
                  className="cancel"
                >
                  CANCEL
                </Button>
                <Dropdown.Button
                  menu={{ items }}
                  icon={<FaCaretDown />}
                  onClick={handleSubmit}
                  className={commonCSS.noteCreate}
                  trigger={["click"]}
                >
                  CREATE
                </Dropdown.Button>
              </Space>
            </Flex>
          </>
        ) : (
          <>
            <div className="noteField not-expanded-container">
              <Input.TextArea
                placeholder="Take a note..."
                autoSize={{ minRows: 1, maxRows: 18 }}
                variant="borderless"
                onClick={() => setIsExpanded(true)}
              />
              <div className="Create-suffix-icon">
                <CheckBoxInCrateNote onCheckboxToggle={handleCheckboxToggle} />
                <FrameIcon
                  setUploadFileList={setUploadFileList}
                  uploadFileList={uploadFileList}
                  id=""
                  footerType={NOTES_FOOTER_TYPE.createNoteSuffix}
                />
              </div>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default CreateNote;
