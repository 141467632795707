import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { LocalStorageService } from "../utils/LocalStorage";
import { UTILS } from "../utils/const";
import { RootState } from "../store";
import { UserInterface } from "../slices/userSlice";

const ProtectRoute = ({ layout: Layout, component: Component, role, ...rest }: any) => {
  const { user, isUserLogin } = useSelector((state: RootState) => state.user);
  const userData: UserInterface = user || LocalStorageService.getItem(UTILS.user);

  const isAuthenticated = () => {
    const token = LocalStorageService.getItem(UTILS.token);
    return isUserLogin || token;
  };
  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  // if((userData?.role === ROLE.agentAdmin && role !== ROLE.agentAdmin) ||
  // (userData?.role === ROLE.agentUser && role !== ROLE.agentUser) ||
  // (userData?.role === ROLE.admin && role !== ROLE.admin)){
  //     if(userData?.role === ROLE.agentAdmin){
  //         return <Navigate to={'/team'} />
  //     }else if(userData?.role === ROLE.agentUser){
  //         return <Navigate to={'/inquiry'} />
  //     }else {
  //         return <Navigate to={'/login'} />
  //     }
  // }

  return (
    <Layout>
      <Component {...rest} />
    </Layout>
  );
};

export default ProtectRoute;
