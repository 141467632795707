import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LayoutState {
  sidebarOpen: boolean;
  taskDrawerOpen: "CREATE_TASK" | null;
  contactDrawerOpen: "CREATE_CONTACT" | null;
  vendorDrawerOpen: "CREATE_VENDOR" | null;
}

const initialState: LayoutState = {
  sidebarOpen: false,
  taskDrawerOpen: null,
  contactDrawerOpen: null,
  vendorDrawerOpen: null,
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setSidebarToggle(state, action: PayloadAction<boolean>) {
      state.sidebarOpen = action.payload;
    },
    setDrawerOpen(state, action: PayloadAction<"CREATE_TASK" | null>) {
      state.taskDrawerOpen = action.payload;
    },
    setContactDrawerOpen(state, action: PayloadAction<"CREATE_CONTACT" | null>) {
      state.contactDrawerOpen = action.payload;
    },
    setVendorDrawerOpen(state, action: PayloadAction<"CREATE_VENDOR" | null>) {
      state.vendorDrawerOpen = action.payload;
    },
  },
});

export const { setSidebarToggle, setDrawerOpen, setContactDrawerOpen, setVendorDrawerOpen } = layoutSlice.actions;

export default layoutSlice.reducer;
