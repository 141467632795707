import { useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { RiPushpin2Line, RiCloseLine, RiPushpin2Fill } from "react-icons/ri";
import { FiClock, FiPlus } from "react-icons/fi";
import { MdDragIndicator, MdOutlineMoreVert } from "react-icons/md";
import {
  Card,
  Tag,
  Typography,
  Avatar,
  Modal,
  Form,
  Button,
  Input,
  Flex,
  Checkbox,
  Tooltip,
  Image,
  Upload,
  UploadFile,
  GetProp,
  UploadProps,
  Menu,
  Dropdown,
  Divider,
  Collapse,
  Spin,
  notification,
} from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { TweenOneGroup } from "rc-tween-one";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { formatDateTime, getBase64, getInitials, getUniqIdsOnly } from "../../utils/commonFunctions";
import { downloadDocument } from "../../utils/AWSService";
import webSocketService from "../../utils/websocketService";
import { NOTES_FILTER_TYPE, NOTES_FOOTER_TYPE } from "../../utils/const";
import { INoteCard, ITags } from "../../utils/interface";
import { TeamMemberInterface } from "../../slices/commonDataSlice";
import {
  addLabelByNoteId,
  colorChange,
  deleteDocByNoteId,
  IEditedNotes,
  IHistory,
  IImage,
  IMyNote,
  INoteContent,
  ISelectedLabels,
  IShareList,
  pinNote,
  setSelectedNotes,
  updateNote,
  updateReminderByNoteId,
  updateShareList,
} from "../../slices/notesSlice";
import ImageGallery from "./ImageGallery";
import CardFooter from "./CardFooter";
import FileListItem from "../../components/FileListItem";
import { DeleteForEver, RestoreFromBin } from "./NotesFooterIcons";
import docx from "../../assets/docx.png";
import pdf from "../../assets/pdf.png";
import ppt from "../../assets/ppt.png";
import webp from "../../assets/webp.png";
import xlsx from "../../assets/xlsx.png";
import xml from "../../assets/xml.png";
import styleCss from "./style.module.scss";

dayjs.extend(isToday);

const colors = ["#fa7777", "#30bfee"];
type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];
const Panel = Collapse.Panel;

const NoteCard = (note: any) => {
  const { selectedNotes, activeFilterTab } = useSelector((state: RootState) => state.notes);
  const { user } = useSelector((state: RootState) => state.user);
  const { assignList } = useSelector((state: RootState) => state.commonData);
  const { is_pinned, tag } = note;
  const {
    _id,
    title,
    description,
    descriptionType,
    checkList,
    bg_color,
    docs,
    share_to,
    reminder,
    updated_on,
    created_on,
    created_by,
  } = note?.note;
  const loading = useSelector((state: RootState) => state.notes.noteLoading[_id]);
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const [isHover, setIsHover] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState<INoteCard | null>(null);
  const [showCheckboxes, setShowCheckboxes] = useState(descriptionType === "checkbox" ? true : false);
  const [noteContent, setNoteContent] = useState(descriptionType === "checkbox" ? checkList : description);
  const [noteTitle, setNoteTitle] = useState(title || "");
  const [history, setHistory] = useState<IHistory[]>([]);
  const [historyIndex, setHistoryIndex] = useState<number>(-1);
  const [isRedoUndo, setIsRedoUndo] = useState<boolean>(false);
  const [selectedLabels, setSelectedLabels] = useState<ISelectedLabels[]>(tag || []);
  const [reminderTime, setReminderTime] = useState<string | null>(null);
  const [isRemainderOpen, setIsRemainderOpen] = useState(false);
  const [isRemainderModalOpen, setIsRemainderModalOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState({
    isPreviewOpen: false,
    previewUrl: "",
  });
  const [initFileList, setInitFileList] = useState<UploadFile[]>([]);
  const [uncheckedItems, setUncheckedItems] = useState<INoteContent[]>([]);
  const [checkedItems, setCheckedItems] = useState<INoteContent[]>([]);
  const [isCheckedCollapse, setIsCheckedCollapse] = useState<boolean>(true);
  const undoTimeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const deletedFileRef = useRef<any | null>(null);

  const createdByName = assignList?.find((user: TeamMemberInterface) => {
    return user?._id === created_by;
  });

  notification.config({
    placement: "bottomLeft",
    duration: 3,
  });

  useEffect(() => {
    if (showCheckboxes) {
      setUncheckedItems(showCheckboxes ? noteContent?.filter((line: INoteContent) => !line?.checked) : []);
      setCheckedItems(showCheckboxes ? noteContent?.filter((line: INoteContent) => line.checked) : []);
    }
  }, [showCheckboxes]);

  useEffect(() => {
    if (showCheckboxes && noteContent?.length) {
      setNoteContent([...uncheckedItems, ...checkedItems]);
    }
  }, [uncheckedItems, checkedItems, showCheckboxes]);

  const canUndo = historyIndex > 0;
  const canRedo = historyIndex < history.length - 1;

  useEffect(() => {
    if (!isRedoUndo) {
      const previousState = history[historyIndex];
      const hasChanges =
        !_.isEqual(previousState?.noteContent, noteContent) ||
        previousState?.noteTitle !== noteTitle ||
        previousState?.showCheckboxes !== showCheckboxes;

      if (hasChanges) {
        undoTimeoutIdRef.current = null;

        const setHistoryTimeout = setTimeout(() => {
          const historyList: IHistory[] = [...history];
          const historyObj: IHistory = {
            noteContent,
            noteTitle,
            showCheckboxes,
          };

          if (historyIndex + 1 < historyList.length) {
            historyList.splice(historyIndex + 1);
          }

          historyList.push(historyObj);
          setHistory(historyList);
          setHistoryIndex(historyList.length - 1);
        }, 300);

        return () => clearTimeout(setHistoryTimeout);
      }
    } else {
      setIsRedoUndo(false);
    }
  }, [noteContent, noteTitle, showCheckboxes, historyIndex]);

  const handleUndo = () => {
    if (!!undoTimeoutIdRef.current) {
      cancelDelete();
    } else if (canUndo && !undoTimeoutIdRef.current) {
      setIsRedoUndo(true);
      setHistoryIndex(historyIndex - 1);
    }
  };

  const handleRedo = () => {
    if (canRedo) {
      setIsRedoUndo(true);
      setHistoryIndex(historyIndex + 1);
    }
  };

  useEffect(() => {
    if (historyIndex >= 0 && historyIndex < history.length) {
      const currentState = history[historyIndex];
      setNoteContent(currentState.noteContent ?? null);
      setNoteTitle(currentState.noteTitle ?? "");
      setShowCheckboxes(currentState.showCheckboxes ?? false);

      if (currentState.showCheckboxes && Array.isArray(currentState.noteContent)) {
        const checked = currentState.noteContent.filter((item: INoteContent) => item.checked);
        const unchecked = currentState.noteContent.filter((item: INoteContent) => !item.checked);
        setCheckedItems(checked);
        setUncheckedItems(unchecked);
      }

      form.setFieldValue("title", currentState.noteTitle);
    }
  }, [historyIndex]);

  const deleteImage = async (fileName: string) => {
    await dispatch(deleteDocByNoteId({ noteId: _id, fileName }));
    clearDeleteImageState();
  };

  const clearDeleteImageState = () => {
    undoTimeoutIdRef.current = null;
    deletedFileRef.current = null;
  };

  const removeDocWithTimeout = (file: any) => {
    deletedFileRef.current = file;
    notification.destroy();
    showUndoNotification();
    setInitFileList((prev) => prev.filter((fls) => fls.name !== file.name));

    const timeoutId = setTimeout(() => {
      deleteImage(file.name);
      clearDeleteImageState();
      notification.destroy();
    }, 5000);

    undoTimeoutIdRef.current = timeoutId;
  };

  const cancelDelete = () => {
    if (undoTimeoutIdRef.current) {
      if (deletedFileRef.current) {
        setInitFileList((prev) => [...prev, deletedFileRef.current]);
      }
      notification.destroy();
      clearTimeout(undoTimeoutIdRef.current);
      clearDeleteImageState();
      setTimeout(() => {
        showUndoneNotification();
      }, 300);
    }
  };

  const showUndoNotification = () => {
    notification.open({
      message: "Image deleted",
      btn: (
        <Button type="link" onClick={cancelDelete} style={{ padding: 0 }}>
          Undo
        </Button>
      ),
      pauseOnHover: false,
      className: styleCss.imageUndoNotification,
      style: {
        backgroundColor: "#323232",
        borderRadius: "6px",
        padding: "10px 10px 10px 20px",
      },
    });
  };

  const showUndoneNotification = () => {
    notification.open({
      message: "Action undone",
      pauseOnHover: false,
      className: styleCss.imageUndoNotification,
      style: {
        backgroundColor: "#323232",
        borderRadius: "6px",
        padding: "10px 10px 10px 20px",
      },
    });
  };

  const modalStyle = {
    body: {
      backgroundColor: bg_color,
      borderRadius: "8px",
    },
  };

  const imageTypes = ["jpg", "jpeg", "png"];
  const doc = docs.filter((image: IImage) => !imageTypes.includes(image.fileType));

  useEffect(() => {
    const validImages = docs?.filter((image: IImage) => imageTypes?.includes(image?.fileType));
    const fileList: UploadFile[] = validImages
      ?.map((image: IImage, index: number) => ({
        uid: `${index}`,
        name: image?.fileName,
        status: "done",
        url: image?.url,
        order: image.order,
      }))
      .sort((a: any, b: any) => a.order - b.order);
    setInitFileList(fileList);
  }, [docs]);

  useEffect(() => {
    setSelectedLabels(tag);
    setShowCheckboxes(descriptionType === "checkbox" ? true : false);
    setNoteContent(descriptionType === "checkbox" ? checkList : description);
    setReminderTime(reminder);
  }, [tag, bg_color, descriptionType, description, checkList, reminder]);

  const parseContent = (text: string) =>
    text.split("\n").map((line: string) => ({
      title: line,
      checked: false,
    }));

  const handleEditNote = (note: INoteCard) => {
    setSelectedNote(note);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    form.submit();
    setIsModalOpen(false);
    setSelectedNote(null);
    setHistory([]);
    setHistoryIndex(-1);
  };

  const handleEditNotes = (value?: { title?: string }, noteData?: INoteContent[], isCheckbox?: boolean) => {
    const editedNotes: IEditedNotes = {
      descriptionType: isCheckbox ? "checkbox" : "text",
    };
    value && (editedNotes.title = value.title);
    if (isCheckbox) {
      editedNotes.checkList = noteData;
    } else {
      editedNotes.description = Array.isArray(noteData)
        ? noteData.map((note) => note.title).join(", ")
        : noteData || "";
    }
    dispatch(updateNote({ id: _id, data: editedNotes }));
  };

  const handleColorChange = (color: string) => {
    dispatch(colorChange({ id: _id, color }));
  };

  const handlePinNote = () => {
    dispatch(pinNote({ id: _id, isPinned: !is_pinned }));
  };

  const handleCheckboxToggle = () => {
    setShowCheckboxes((prev) => {
      if (!prev) {
        setNoteContent(parseContent(noteContent));
        setUncheckedItems(parseContent(noteContent));
        handleEditNotes({ title }, parseContent(noteContent), !prev);
      } else {
        setNoteContent(noteContent?.map((line: INoteContent) => line?.title).join("\n"));
        handleEditNotes({ title }, noteContent?.map((line: INoteContent) => line?.title).join("\n"), !prev);
        setUncheckedItems([]);
        setCheckedItems([]);
      }
      return !prev;
    });
  };

  const handleCheckboxChange = (index: number, isUncheckedList: boolean) => {
    let updatedNoteContent;
    if (isUncheckedList) {
      const itemToMove = uncheckedItems[index];
      const newUncheckedItems = uncheckedItems.filter((_, i) => i !== index);
      const newCheckedItems = [...checkedItems, { ...itemToMove, checked: true }];
      setUncheckedItems(newUncheckedItems);
      setCheckedItems(newCheckedItems);
      updatedNoteContent = [...newUncheckedItems, ...newCheckedItems];
    } else {
      const itemToMove = checkedItems[index];
      const newCheckedItems = checkedItems.filter((_, i) => i !== index);
      const newUncheckedItems = [...uncheckedItems, { ...itemToMove, checked: false }];
      setCheckedItems(newCheckedItems);
      setUncheckedItems(newUncheckedItems);
      updatedNoteContent = [...newUncheckedItems, ...newCheckedItems];
    }
    handleEditNotes({ title }, updatedNoteContent, showCheckboxes);
  };

  const handleAddNewLine = () => {
    if (uncheckedItems.length && !uncheckedItems[uncheckedItems.length - 1].title) return;
    setUncheckedItems((prevItems) => [...prevItems, { title: "", checked: false }]);
  };

  const handleLineTextChange = (index: number, text: string, isUncheckedList: boolean) => {
    if (!text) return;

    const updateItems = (prevItems: INoteContent[]) => {
      const newItems = [...prevItems];
      newItems[index] = { ...newItems[index], title: text };
      return newItems;
    };

    if (isUncheckedList) {
      setUncheckedItems(updateItems);
    } else {
      setCheckedItems(updateItems);
    }
  };

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!showCheckboxes) setNoteContent(e.target.value);
    setIsRedoUndo(false);
  };

  const handleRemoveLine = (index: number, isUncheckedList: boolean) => {
    if (isUncheckedList) {
      setUncheckedItems((prevItems) => prevItems.filter((_, i) => i !== index));
    } else {
      setCheckedItems((prevItems) => prevItems.filter((_, i) => i !== index));
    }
  };

  const handleDragEnd = (result: any) => {
    const { source, destination } = result;
    if (!destination || source.index === destination.index) return;

    const newUncheckedItems = Array.from(uncheckedItems);
    const [movedItem] = newUncheckedItems.splice(source.index, 1);
    newUncheckedItems.splice(destination.index, 0, movedItem);
    setUncheckedItems(newUncheckedItems);
  };

  const handleCheckedDragEnd = (result: any) => {
    const { source, destination } = result;
    if (!destination || source.index === destination.index) return;

    const newCheckedItems = Array.from(checkedItems);
    const [movedItem] = newCheckedItems.splice(source.index, 1);
    newCheckedItems.splice(destination.index, 0, movedItem);
    setCheckedItems(newCheckedItems);
  };

  const handleUserListChange = (userList: string[]) => {
    dispatch(updateShareList({ noteId: _id, shareList: userList }));
  };

  const handleRemoveNoteLabel = (label: ISelectedLabels) => {
    const updatedLabels = selectedLabels?.filter((l) => l?._id !== label?._id);
    if (_id) {
      dispatch(addLabelByNoteId({ noteId: _id, labelIds: getUniqIdsOnly(updatedLabels) }));
      setSelectedLabels(updatedLabels);
    }
  };

  const handleEditReminder = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsRemainderOpen(true);
  };

  const removeReminder = () => {
    setReminderTime(null);
    setIsRemainderOpen(false);
    dispatch(updateReminderByNoteId({ noteId: _id, reminder: null }));
  };

  const handleAddNewLineAtIndex = (index: number, isChecked: boolean) => {
    const newLine = { title: "", checked: isChecked };

    if (isChecked) {
      setCheckedItems((prevItems: INoteContent[]) => {
        const newCheckedItems = [...prevItems];
        newCheckedItems.splice(index + 1, 0, newLine);
        return newCheckedItems;
      });
    } else {
      setUncheckedItems((prevItems: INoteContent[]) => {
        const newUncheckedItems = [...prevItems];
        newUncheckedItems.splice(index + 1, 0, newLine);
        return newUncheckedItems;
      });
    }
  };

  const handleKeyPress = (index: number, e: React.KeyboardEvent<HTMLDivElement>, isUncheckedList: boolean) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const classPrefix = isUncheckedList ? "editable-div-" : "checkedEditable-div-";
      handleAddNewLineAtIndex(index, !isUncheckedList);
      setTimeout(() => {
        const nextElement = document.querySelector(`.${classPrefix}${index + 1}`);
        if (nextElement) {
          (nextElement as HTMLElement).focus();
        }
      }, 0);
    } else if (e.key === "Backspace" && e.currentTarget.textContent === "") {
      e.preventDefault();
      const classPrefix = isUncheckedList ? "editable-div-" : "checkedEditable-div-";
      handleRemoveLine(index, isUncheckedList);
      setTimeout(() => {
        const previousElement = document.querySelector(`.${classPrefix}${index - 1}`);
        if (previousElement) {
          (previousElement as HTMLElement).focus();
        }
      }, 0);
    }
  };

  const handlePreview = async (file: UploadFile) => {
    let fileType = ["jpeg", "png", "jpg"];
    if (fileType.includes(file.name?.split(".")[1])) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as FileType);
      }
      setImagePreview({
        isPreviewOpen: true,
        previewUrl: file.url || (file.preview as string),
      });
    } else {
      window.open(file.url, "_blank")?.focus();
    }
  };

  const handleCheckBoxClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const menu = (image: any) => {
    return (
      <Menu>
        <Menu.Item onClick={() => handlePreview(image)}>Preview</Menu.Item>
        <Menu.Item onClick={() => fileDownloadHandle(image.fileName, image.fileType, image?.localName)}>
          Download
        </Menu.Item>
        <Menu.Item onClick={() => dispatch(deleteDocByNoteId({ noteId: _id, fileName: image?.fileName }))}>
          Delete
        </Menu.Item>
      </Menu>
    );
  };

  const iconMap: { [key: string]: string } = {
    csv: pdf,
    xls: xlsx,
    doc: docx,
    pdf: pdf,
    txt: xml,
    xml: xml,
    pptx: ppt,
    webp: webp,
    plain: xml,
  };

  const renderCheckedItems = () =>
    checkedItems?.map((line, index) => (
      <div key={`checked-${index}`}>
        <Checkbox
          checked={line.checked}
          className={styleCss.cardContent}
          onChange={() => handleCheckboxChange(index, false)}
          style={{
            marginRight: "8px",
            marginBottom: "0px",
            textDecoration: "line-through",
            color: "#5f6368",
          }}
          onClick={handleCheckBoxClick}
        >
          {line.title}
        </Checkbox>
      </div>
    ));

  const fileDownloadHandle = async (fileName: string, type: string, saveName?: string) => {
    downloadDocument(`notes/${user?.tenant_id}/${_id}/${fileName}`, type, saveName);
  };

  const imageDragHandle = (result: any) => {
    const { source, destination } = result;

    if (!destination || source.index === destination.index) return;

    let initFileItems = Array.from(initFileList);
    const [movedItem] = initFileItems.splice(source.index, 1);
    initFileItems.splice(destination.index, 0, movedItem);
    initFileItems = initFileItems.map((fl, index) => ({ ...fl, order: index }));
    setInitFileList(initFileItems);
    webSocketService.sendMessage("noteImageOrderChange", {
      tntId: user?.tenant_id,
      userId: user?._id,
      noteId: _id,
      fileList: initFileItems,
    });
  };
  return (
    <div>
      <Card
        className={`${styleCss.noteCard} ${styleCss.mb16}`}
        classNames={{
          body: "note-card-body",
          header: "note-card-head",
        }}
        style={{
          boxShadow: isHover ? "0px 6px 15px 0px #00000026" : "none",
          backgroundColor: bg_color,
          transition: "300ms all",
          opacity: isModalOpen ? 0 : 1,
          width: "240px",
        }}
        onMouseOver={() => setIsHover(true)}
        onMouseOut={() => setIsHover(false)}
        bordered={false}
        onClick={() => {
          if (selectedNotes.length === 0) {
            handleEditNote(note);
          }
        }}
        loading={!!loading}
        title={
          <>
            <div
              className={styleCss.checkCircle}
              style={{ opacity: selectedNotes.some((dt: IMyNote) => dt?.note?._id === _id) ? 1 : "revert-layer" }}
              onClick={(event) => {
                event.stopPropagation();
                dispatch(setSelectedNotes(note));
              }}
            >
              <Tooltip title={is_pinned ? "Selected pin note" : " Selected Note"}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="7" y="9" width="10" height="7" fill={bg_color} />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12ZM15.7127 10.7197C16.0055 10.4268 16.0055 9.95192 15.7127 9.65903C15.4198 9.36614 14.9449 9.36614 14.652 9.65903L10.9397 13.3713L9.34869 11.7804C9.0558 11.4875 8.58092 11.4875 8.28803 11.7804C7.99514 12.0732 7.99514 12.5481 8.28803 12.841L10.4093 14.9623C10.7022 15.2552 11.1771 15.2552 11.47 14.9623L15.7127 10.7197Z"
                    fill="black"
                  />
                </svg>
              </Tooltip>
            </div>
            <div className={styleCss.notesPin} onClick={(event) => event.stopPropagation()}>
              {is_pinned ? (
                <Tooltip title="Unpin note">
                  <RiPushpin2Fill size={24} color="#444444" onClick={handlePinNote} />
                </Tooltip>
              ) : (
                <Tooltip title="Pin note">
                  <RiPushpin2Line size={24} color="#444444" onClick={handlePinNote} />
                </Tooltip>
              )}
            </div>
          </>
        }
      >
        {selectedNotes?.length > 0 && (
          <div
            className={`${styleCss.selectOverlay} ${
              selectedNotes?.some((dt: IMyNote) => dt?.note?._id === _id) ? styleCss.select : ""
            }`}
            onClick={() => dispatch(setSelectedNotes(note))}
          ></div>
        )}

        {docs.length > 0 &&
          docs?.filter((doc: IImage) => ["jpg", "jpeg", "png"].includes(doc?.fileType?.toLowerCase()))?.length > 0 && (
            <div className={styleCss.galleryContainer} style={{ maxHeight: "600px" }}>
              <ImageGallery images={docs} noteId={_id} />
            </div>
          )}

        <Flex vertical gap={18}>
          {/* Note title */}
          {title && <Typography.Title className={styleCss.cardTitle}>{title}</Typography.Title>}

          {/* Note description */}
          {noteContent && (
            <>
              {showCheckboxes ? (
                <>
                  {/* Unchecked items first */}
                  {uncheckedItems.length > 0 && (
                    <div>
                      {uncheckedItems.map((line, index) => (
                        <div key={`unchecked-${index}`}>
                          <Checkbox
                            checked={line?.checked}
                            className={`${styleCss.cardContent}`}
                            onChange={() => handleCheckboxChange(index, true)}
                            style={{ marginRight: "8px", marginBottom: "0px" }}
                            onClick={(e) => handleCheckBoxClick(e)}
                          >
                            {line?.title}
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                  )}
                  {/* Divider */}
                  {Array.isArray(noteContent) &&
                    noteContent.some((line) => !line.checked) &&
                    noteContent.some((line) => line.checked) && (
                      <Divider
                        style={{
                          borderColor: "rgba(100, 100, 100, 0.2)",
                          margin: "-10px 0px",
                          minWidth: "auto",
                          width: "auto",
                        }}
                      />
                    )}

                  {/* Checked items */}

                  {!!checkedItems?.length &&
                    (!isCheckedCollapse ? (
                      <Collapse
                        bordered={false}
                        style={{ backgroundColor: "transparent" }}
                        className={styleCss.cardCheckedCollapse}
                      >
                        <Panel
                          header={`+ ${checkedItems.length} Completed ${checkedItems.length >= 2 ? "items" : "item"}`}
                          key="1"
                          showArrow={false}
                        >
                          {renderCheckedItems()}
                        </Panel>
                      </Collapse>
                    ) : (
                      <div>{renderCheckedItems()}</div>
                    ))}
                </>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: noteContent?.replace(/\n/g, "<br />"),
                  }}
                  className={`${styleCss.cardContent} ${styleCss.lineClamp}`}
                />
              )}
            </>
          )}

          {/* Empty note label */}
          {!title && !noteContent && !reminderTime && !share_to?.length && !tag?.length && !docs?.length && (
            <Typography.Title className={styleCss.emptyNotesTitle}>Empty notes</Typography.Title>
          )}

          {/* Document list */}
          {doc.length > 0 && (
            <div className={styleCss.documentWrapper}>
              {doc?.map((documents: IImage) => (
                <Flex align="center" className="document" gap={5} style={{ lineHeight: "1" }} key={documents?.url}>
                  <img
                    src={iconMap[documents.fileType] || xml}
                    height={22}
                    width={20}
                    alt={`${documents.fileType || "default"} icon`}
                  />
                  <Typography.Text ellipsis>{documents?.localName}</Typography.Text>
                </Flex>
              ))}
            </div>
          )}

          {/* Reminder, label list */}
          {(reminderTime || (selectedLabels && selectedLabels.length > 0)) && (
            <TweenOneGroup
              appear={false}
              enter={{ scale: 0.8, opacity: 0, type: "from", duration: 100 }}
              leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
              onEnd={(e) => {
                if (e.type === "appear" || e.type === "enter") {
                  (e.target as any).style = "display: inline-block";
                }
              }}
            >
              <Flex wrap gap={5} className={styleCss.reminderLabel}>
                {reminderTime && (
                  <Flex className="reminder" onClick={handleEditReminder} align="center" gap={4}>
                    <FiClock size={14} style={{ minWidth: "14px", maxWidth: "14px", width: "14px" }} color="#3c4043" />
                    <Typography.Text ellipsis>{dayjs(reminderTime)?.format("MMM D, h:mm A")}</Typography.Text>
                    <IoClose
                      onClick={(e) => {
                        e.stopPropagation();
                        removeReminder();
                      }}
                      size={14}
                      className="close"
                      color="#3c4043"
                    />
                  </Flex>
                )}
                {!!selectedLabels && selectedLabels.length > 0 && (
                  <>
                    {selectedLabels.slice(0, 2).map((tag: ITags) => (
                      <Tag
                        key={tag._id}
                        onClose={(e) => {
                          e.preventDefault();
                          handleRemoveNoteLabel(tag);
                        }}
                      >
                        {tag.title}
                      </Tag>
                    ))}

                    {selectedLabels.length > 2 && <Tag>+{selectedLabels.length - 2}</Tag>}
                  </>
                )}
              </Flex>
            </TweenOneGroup>
          )}

          {/*Note share user list  */}
          {!!share_to && share_to.length > 0 && (
            <div style={{ paddingLeft: "5px" }}>
              <Avatar.Group
                max={{
                  count: 3,
                  style: { color: "#ffffffd6", backgroundColor: "#76a3da", fontSize: "14px" },
                  popover: { rootClassName: "AsssignnePopover" },
                }}
                size={30}
              >
                {share_to?.map((asn: IShareList, idx: number) => {
                  return (
                    <Tooltip title={asn?.full_name} key={idx}>
                      <Avatar
                        style={{
                          fontSize: "14px",
                          backgroundColor: colors[idx % colors?.length],
                          marginLeft: "-5px",
                        }}
                      >
                        {getInitials(asn.full_name)}
                      </Avatar>
                    </Tooltip>
                  );
                })}
              </Avatar.Group>
            </div>
          )}
        </Flex>
        {!(activeFilterTab === NOTES_FILTER_TYPE.bin) ? (
          <CardFooter
            isHover={isHover}
            onColorChange={handleColorChange}
            onCheckboxToggle={handleCheckboxToggle}
            showCheckboxes={showCheckboxes}
            id={_id}
            onUserListChange={handleUserListChange}
            selectedLabels={selectedLabels}
            setSelectedLabels={setSelectedLabels}
            userShareList={share_to}
            reminder={reminderTime ?? ""}
            setReminder={(val: any) => {
              setReminderTime(val);
              dispatch(updateReminderByNoteId({ noteId: _id, reminder: val }));
            }}
            isRemainderOptionOpen={isRemainderOpen}
            setIsRemainderOptionOpen={setIsRemainderOpen}
            footerType={NOTES_FOOTER_TYPE.cardBottom}
          />
        ) : (
          <div onClick={(event) => event.stopPropagation()}>
            <div className={`${styleCss.placeholder} ${styleCss.showSpace}`}></div>
            <div className={`${styleCss.cardFooter} ${!isHover ? "" : styleCss.showBlock}`}>
              <DeleteForEver id={_id} />
              <RestoreFromBin id={_id} />
            </div>
          </div>
        )}
      </Card>

      {/* Modal for editing notes */}
      <Form form={form} layout="vertical" onFinish={(val) => handleEditNotes(val, noteContent, showCheckboxes)}>
        {isModalOpen && selectedNote && (
          <Modal
            open={isModalOpen}
            onCancel={handleModalClose}
            footer={null}
            closable={false}
            width={600}
            className={styleCss.noteModel}
            styles={modalStyle}
            centered
          >
            <Spin spinning={!!loading}>
              <div className="ModelWrapper">
                <div className="ModelPin" style={{ cursor: "pointer" }} onClick={(event) => event.stopPropagation()}>
                  {is_pinned ? (
                    <Tooltip title="Unpin note">
                      <RiPushpin2Fill size={24} color="#444444" onClick={handlePinNote} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Pin note">
                      <RiPushpin2Line size={24} color="#444444" onClick={handlePinNote} />
                    </Tooltip>
                  )}
                </div>

                {initFileList?.length > 0 && (
                  <>
                    <DragDropContext onDragEnd={imageDragHandle}>
                      <Droppable droppableId="file-list" direction="horizontal">
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.droppableProps} className="modelImage">
                            {initFileList.map((file, index) => (
                              <Draggable key={index} draggableId={`file-${index}`} index={index}>
                                {(provided) => {
                                  let ext: any = file.name.split(".").pop();
                                  let imgExt = ["jpeg", "jpg", "png"];
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <FileListItem
                                        file={file}
                                        imgExt={imgExt}
                                        ext={ext}
                                        removeDocHandle={removeDocWithTimeout}
                                        fileDownloadHandle={fileDownloadHandle}
                                      />
                                    </div>
                                  );
                                }}
                              </Draggable>
                            ))}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </>
                )}

                {imagePreview?.isPreviewOpen && (
                  <Image
                    wrapperStyle={{ display: "none" }}
                    preview={{
                      visible: imagePreview?.isPreviewOpen,
                      onVisibleChange: (visible) =>
                        setImagePreview((prevState) => ({
                          ...prevState,
                          isPreviewOpen: visible,
                        })),
                      afterOpenChange: (visible) => {
                        if (!visible) {
                          setImagePreview((prevState) => ({
                            ...prevState,
                            previewUrl: "",
                          }));
                        }
                      },
                    }}
                    src={imagePreview?.previewUrl}
                  />
                )}

                <div className="Notetitle">
                  <Form.Item name="title" initialValue={title}>
                    <Input
                      variant="borderless"
                      placeholder="Title"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setNoteTitle(e.target.value);
                        setIsRedoUndo(false);
                      }}
                    />
                  </Form.Item>
                </div>

                <Form.Item className="NoteDecr" name="content">
                  <div>
                    {showCheckboxes ? (
                      <>
                        {/* Unchecked items - draggable */}
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <Droppable droppableId="unchecked-list">
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.droppableProps}>
                                {uncheckedItems?.map((line: INoteContent, index: number) => (
                                  <Draggable key={index} draggableId={`unchecked-${index}`} index={index}>
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        className="note-content"
                                      >
                                        <div style={{ display: "flex", alignItems: "center" }} className="contain-div">
                                          <div
                                            className="dragHandle"
                                            {...provided.dragHandleProps}
                                            style={{ paddingRight: "6px", display: "inline-flex" }}
                                          >
                                            <MdDragIndicator size={24} color="#979797" />
                                          </div>
                                          <Checkbox
                                            checked={line.checked}
                                            onChange={() => handleCheckboxChange(index, true)}
                                            style={{ marginRight: "10px" }}
                                          />
                                          <div
                                            contentEditable
                                            suppressContentEditableWarning={true}
                                            style={{ flexGrow: 1, outline: "none" }}
                                            className={`editable-div editable-div-${index}`}
                                            onBlur={(e: React.FocusEvent<HTMLDivElement>) =>
                                              handleLineTextChange(index, e.currentTarget.textContent || "", true)
                                            }
                                            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
                                              handleKeyPress(index, e, true)
                                            }
                                          >
                                            {line?.title}
                                          </div>
                                          <RiCloseLine
                                            size={22}
                                            onClick={() => handleRemoveLine(index, true)}
                                            className="delete-item"
                                            color="#979797"
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                        {showCheckboxes && (
                          <div className="listItem">
                            <Button
                              style={{ color: "#333333", fontSize: "14px", padding: 0, paddingLeft: "28px" }}
                              size="large"
                              type="link"
                              onClick={handleAddNewLine}
                              icon={<FiPlus size={20} color="#333333" />}
                            >
                              List item
                            </Button>
                          </div>
                        )}

                        {/* Divider between unchecked and checked items */}
                        {Array.isArray(noteContent) &&
                          noteContent.some((line) => !line.checked) &&
                          noteContent.some((line) => line.checked) && (
                            <Divider
                              style={{
                                borderColor: "rgba(100, 100, 100, 0.2)",
                                margin: "5px 10px 15px 25px",
                                minWidth: "auto",
                                width: "auto",
                              }}
                            />
                          )}

                        {/* Checked items - not draggable but editable */}
                        {!!checkedItems?.length && (
                          <DragDropContext onDragEnd={handleCheckedDragEnd}>
                            <Droppable droppableId="checked-list">
                              {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                  <Collapse
                                    bordered={false}
                                    style={{ backgroundColor: "transparent" }}
                                    className={styleCss.modalCheckedCollapse}
                                    defaultActiveKey={isCheckedCollapse ? ["1"] : []}
                                    onChange={() => setIsCheckedCollapse(!isCheckedCollapse)}
                                  >
                                    <Panel
                                      header={`${checkedItems?.length} Completed ${
                                        checkedItems?.length >= 2 ? "items" : "item"
                                      }`}
                                      key="1"
                                    >
                                      {checkedItems?.map((line: INoteContent, index: number) => (
                                        <Draggable
                                          key={`checked-${index}`}
                                          draggableId={`checked-${index}`}
                                          index={index}
                                        >
                                          {(provided) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              className="note-content"
                                            >
                                              <div
                                                style={{ display: "flex", alignItems: "center" }}
                                                className="contain-div"
                                              >
                                                <div
                                                  className="dragHandle"
                                                  {...provided.dragHandleProps}
                                                  style={{ paddingRight: "6px", display: "inline-flex" }}
                                                >
                                                  <MdDragIndicator size={24} color="#979797" />
                                                </div>
                                                <Checkbox
                                                  checked={line.checked}
                                                  onChange={() => handleCheckboxChange(index, false)}
                                                  style={{ marginRight: "10px" }}
                                                />
                                                <div
                                                  contentEditable
                                                  suppressContentEditableWarning={true}
                                                  style={{
                                                    flexGrow: 1,
                                                    outline: "none",
                                                    textDecoration: "line-through",
                                                    color: "#5f6368",
                                                  }}
                                                  className={`checkedEditable-div checkedEditable-div-${index}`}
                                                  onBlur={(e: React.FocusEvent<HTMLDivElement>) =>
                                                    handleLineTextChange(
                                                      index,
                                                      e.currentTarget.textContent || "",
                                                      false
                                                    )
                                                  }
                                                  onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
                                                    handleKeyPress(index, e, false)
                                                  }
                                                >
                                                  {line?.title}
                                                </div>
                                                <RiCloseLine
                                                  size={22}
                                                  onClick={() => handleRemoveLine(index, false)}
                                                  className="delete-item"
                                                  color="#979797"
                                                />
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </Panel>
                                  </Collapse>
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        )}
                      </>
                    ) : (
                      <Input.TextArea
                        autoSize={{ minRows: 1 }}
                        value={noteContent}
                        onChange={handleContentChange}
                        placeholder="Note content"
                        variant="borderless"
                        style={{ paddingLeft: "16px" }}
                      />
                    )}
                  </div>
                </Form.Item>

                <TweenOneGroup
                  appear={false}
                  enter={{ scale: 0.8, opacity: 0, type: "from", duration: 100 }}
                  leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                  onEnd={(e) => {
                    if (e.type === "appear" || e.type === "enter") {
                      (e.target as any).style = "display: inline-block";
                    }
                  }}
                >
                  <Flex
                    wrap
                    gap={5}
                    className={styleCss.reminderLabel}
                    style={{ paddingLeft: "16px", marginBottom: 20 }}
                  >
                    {reminderTime && (
                      <Flex className="reminder" onClick={() => setIsRemainderModalOpen(true)} align="center" gap={4}>
                        <FiClock
                          size={14}
                          style={{ minWidth: "14px", maxWidth: "14px", width: "14px" }}
                          color="#3c4043"
                        />
                        <Typography.Text ellipsis>{dayjs(reminderTime)?.format("MMM D, h:mm A")}</Typography.Text>
                        <IoClose
                          onClick={(e) => {
                            e.stopPropagation();
                            removeReminder();
                          }}
                          size={14}
                          className="close"
                          color="#3c4043"
                        />
                      </Flex>
                    )}
                    {!!selectedLabels && selectedLabels.length > 0 && (
                      <>
                        {selectedLabels.map((tag: ITags) => (
                          <Tag
                            key={tag._id}
                            onClose={(e) => {
                              e.preventDefault();
                              handleRemoveNoteLabel(tag);
                            }}
                          >
                            <Flex align="center" gap={3} style={{ lineHeight: "1" }}>
                              <Typography style={{ fontSize: "11px" }}>{tag?.title}</Typography>
                              <IoClose
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleRemoveNoteLabel(tag);
                                }}
                                className="close"
                                color="#3c4043"
                              />
                            </Flex>
                          </Tag>
                        ))}
                      </>
                    )}
                  </Flex>
                </TweenOneGroup>

                {doc.length > 0 && (
                  <div style={{ margin: "0px 15px 20px", paddingBottom: "0px" }} className={styleCss.documentWrapper}>
                    {doc?.map((documents: IImage) => (
                      <Flex align="center" className="document" justify="space-between" gap={8}>
                        <Flex align="center" gap={5} justify="">
                          <img
                            src={iconMap[documents.fileType] || xml}
                            height={22}
                            width={20}
                            alt={`${documents.fileType || "default"} icon`}
                          />
                          <Typography.Text ellipsis style={{ width: "200px", textAlign: "start" }}>
                            {documents?.localName}
                          </Typography.Text>
                        </Flex>
                        <Dropdown overlay={menu(documents)} trigger={["click"]} placement="bottom">
                          <MdOutlineMoreVert
                            size={20}
                            style={{ cursor: "pointer", width: "20px", minWidth: "20px", maxWidth: "20px" }}
                          />
                        </Dropdown>
                      </Flex>
                    ))}
                  </div>
                )}

                <Flex className="NoteTimeWrapper" justify="space-between" align="center">
                  <div style={{ paddingLeft: "10px" }}>
                    {!!share_to && share_to.length > 0 && (
                      <Avatar.Group
                        max={{
                          count: 3,
                          style: { color: "#ffffffd6", backgroundColor: "#76a3da", fontSize: "14px" },
                          popover: { rootClassName: "AsssignnePopover" },
                        }}
                        size={30}
                      >
                        {share_to?.map((asn: IShareList, idx: number) => {
                          return (
                            <Tooltip title={asn?.full_name} key={idx}>
                              <Avatar
                                style={{
                                  fontSize: "14px",
                                  backgroundColor: colors[idx % colors?.length],
                                  marginLeft: "-5px",
                                }}
                              >
                                {getInitials(asn.full_name)}
                              </Avatar>
                            </Tooltip>
                          );
                        })}
                      </Avatar.Group>
                    )}
                  </div>
                </Flex>
                <div style={{ display: "flex", justifyContent: "space-between", padding: "0 10px", marginTop: 4 }}>
                  <span style={{ fontSize: "12px", color: "#000c" }}>created by {createdByName?.full_name}</span>
                  <Tooltip title={`Created ${formatDateTime(created_on)}`} arrow={false} placement="bottom">
                    <span style={{ fontSize: "12px", color: "#000c" }}>Edited {formatDateTime(updated_on)}</span>
                  </Tooltip>
                </div>
              </div>
              <Flex justify="space-between" className="NoteFooter">
                <Flex align="center" gap={20}>
                  {!(activeFilterTab === NOTES_FILTER_TYPE.bin) ? (
                    <CardFooter
                      isHover={true}
                      onColorChange={handleColorChange}
                      onCheckboxToggle={handleCheckboxToggle}
                      showCheckboxes={showCheckboxes}
                      id={_id}
                      onUserListChange={handleUserListChange}
                      selectedLabels={selectedLabels}
                      setSelectedLabels={setSelectedLabels}
                      userShareList={share_to}
                      reminder={reminderTime ?? ""}
                      setReminder={(val: any) => {
                        setReminderTime(val);
                        dispatch(updateReminderByNoteId({ noteId: _id, reminder: val }));
                      }}
                      isRemainderOptionOpen={isRemainderModalOpen}
                      setIsRemainderOptionOpen={setIsRemainderModalOpen}
                      footerType={NOTES_FOOTER_TYPE.editNoteModal}
                      setUploadFileList={(value) => {
                        setInitFileList(value);
                      }}
                      uploadFileList={initFileList}
                      setIsModalOpen={setIsModalOpen}
                      handleRedo={handleRedo}
                      handleUndo={handleUndo}
                      canRedo={canRedo}
                      canUndo={canUndo || !!undoTimeoutIdRef.current}
                    />
                  ) : (
                    <div>
                      <DeleteForEver id={_id} onConfirmButtonClick={() => handleModalClose()} />
                      <RestoreFromBin id={_id} />
                    </div>
                  )}
                </Flex>
                <Button shape="default" onClick={handleModalClose} className="closeBtn">
                  Close
                </Button>
              </Flex>
            </Spin>
          </Modal>
        )}
      </Form>
    </div>
  );
};

export default NoteCard;
