import { Avatar, Button, Dropdown, Flex, List, MenuProps, Space, Steps, Tooltip } from "antd";
import { MenuOutlined, SettingOutlined } from "@ant-design/icons";
import { FaCheck } from "react-icons/fa6";
import AxiosService from "../../../utils/APIService";
import { formateDate, getInitials, statusClassName } from "../../../utils/commonFunctions";
import { INQUIRY_TYPE, TASK_STATUS } from "../../../utils/const";
import Icon from "../CommonComponent/Icon";
import fireActiveIcon from "../../../assets/fireactive.svg";
import { ITaskList, IUser } from "../../../slices/taskSlice";
import Taskcss from "../style.module.scss";

interface IDeletedList {
  filter: {
    is_personal_task: boolean;
  };
  deletedList: ITaskList[];
}

const colors = ["#76a3da"];

function DeletedList({ deletedList }: IDeletedList) {
  const apiService = new AxiosService({
    baseURL: process.env.REACT_APP_TASK_SERVICE,
  });

  const items: MenuProps["items"] = [
    {
      key: TASK_STATUS.pending,
      label: "Move back to Task",
    },
    {
      key: TASK_STATUS.completedbyUser,
      label: "Move back to Watchlist",
    },
    {
      key: TASK_STATUS.complete,
      label: "Move back to Completed",
    },
  ];

  const handleTaskMove = (status: any, id: string) => {
    apiService
      .put(`/change-status/${id}`, {
        status: status.key,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <div className={Taskcss.taskCollaps}>
        <div className="task-header">
          <span className="length-count">{String(deletedList?.length)?.padStart(2, "0")}</span>
          <span>Deleted</span>
        </div>
        <List size="small" bordered>
          <List.Item>
            <div
              className="task-grid-wrapper header"
              style={{ gridTemplateColumns: "1fr minmax(160px, 160px) minmax(169px, 160px)" }}
            >
              <Space>
                <SettingOutlined
                  style={{ lineHeight: "1", marginLeft: "35px", color: "#6f7994a3", marginRight: "1px" }}
                />
                <span>NAME</span>
              </Space>
              <div>
                <span>DUE DATE</span>
              </div>
              <div className="assigneebg">
                <span>ASSIGNEE</span>
              </div>
            </div>
          </List.Item>
          {deletedList?.map((dt) => {
            return (
              <List.Item key={dt._id}>
                <div
                  className="task-grid-wrapper"
                  style={{ gridTemplateColumns: "1fr minmax(160px, 160px) minmax(169px, 160px)" }}
                >
                  <Flex gap={10} align="center" justify="space-between">
                    <Space>
                      {dt.task.task_type?.length > 1 ? (
                        <Tooltip title="Multiple Inquiries">
                          <Icon name="MULTI" style={{ cursor: "pointer" }} />
                        </Tooltip>
                      ) : (
                        <Tooltip title={INQUIRY_TYPE[dt?.task?.task_type[0]]?.title}>
                          <Icon name={dt?.task?.task_type?.[0]} style={{ cursor: "pointer" }} />
                        </Tooltip>
                      )}
                      <div onClick={(event) => event.stopPropagation()}>
                        <Dropdown
                          menu={{ items, onClick: (val: any) => handleTaskMove(val, dt.task._id) }}
                          trigger={["click"]}
                        >
                          <Button type="link" style={{ padding: "0px", height: "auto" }}>
                            <MenuOutlined style={{ lineHeight: "1" }} />
                          </Button>
                        </Dropdown>
                      </div>
                      <span className="list-item-title">
                        {dt?.task?.title} {dt?.task?.is_high_prior && <img src={fireActiveIcon} />}
                      </span>
                    </Space>
                    <div>
                      {dt?.task?.task_type.includes("PERSONAL") ? (
                        <div style={{ pointerEvents: "none", cursor: "not-allowed" }} className="deleteStatus">
                          <span className={` ${statusClassName(dt.task.task_status)}`}>
                            {dt?.task?.delete_reason?.toUpperCase()}
                          </span>
                        </div>
                      ) : (
                        <div>
                          <Steps
                            className="stepper"
                            current={dt?.master?.step - 1 || 0}
                            labelPlacement="vertical"
                            items={[
                              {
                                icon: dt?.master?.step >= 1 ? <FaCheck fill="white" size={10} /> : null,
                              },
                              {
                                icon: dt?.master?.step >= 2 ? <FaCheck fill="white" size={10} /> : null,
                              },
                              {
                                icon: dt?.master?.step >= 3 ? <FaCheck fill="white" size={10} /> : null,
                              },
                              {
                                icon: dt?.master?.step === 4 ? <FaCheck fill="white" size={10} /> : undefined,
                              },
                            ]}
                          />
                        </div>
                      )}
                    </div>
                  </Flex>
                  <div>
                    <span style={{ pointerEvents: "none", cursor: "not-allowed" }}>
                      {formateDate(dt.task.due_date)}
                    </span>
                  </div>
                  <div className="assigneebg">
                    <Flex align="center" className="assignee-cell" style={{ width: "100%" }}>
                      <Avatar.Group
                        max={{
                          count: 3,
                          style: { backgroundColor: "#76a3da" },
                          popover: { rootClassName: "AsssignnePopover" },
                        }}
                        size={28}
                      >
                        {dt.task.assign_to.map((asn: IUser, idx: number) => {
                          return (
                            <Tooltip title={asn.full_name} key={idx}>
                              <Avatar
                                style={{
                                  backgroundColor: colors[idx % colors.length],
                                  marginLeft: "-6px",
                                }}
                              >
                                {getInitials(asn.full_name)}
                              </Avatar>
                            </Tooltip>
                          );
                        })}
                      </Avatar.Group>
                    </Flex>
                  </div>
                </div>
              </List.Item>
            );
          })}
        </List>
      </div>
    </>
  );
}

export default DeletedList;
