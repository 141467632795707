import { useState } from "react";

interface ITicTacToeSquareProps {
  value: string | null;
  onClick: () => void;
  style: any;
}

const TicTacToeSquare = ({ value, onClick, style }: ITicTacToeSquareProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      className="square"
      style={{
        width: "100px",
        height: "100px",
        backgroundColor: isHovered ? "#ccc" : "#ddd",
        fontSize: "42px",
        border: "1px solid #333",
        textAlign: "center",
        lineHeight: "100px",
        cursor: "pointer",
        ...style,
      }}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {value}
    </button>
  );
};

export default TicTacToeSquare;
