import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MinList {
  name: string;
  _id: string;
  type: "TASK" | "CONTACT" | "INQUIRY" | "VENDOR";
}
interface IUserState {
  minimizeItemList: MinList[];
  openItemList: MinList[];
}
const initialState: IUserState = {
  minimizeItemList: [],
  openItemList: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addItemInList(state, action: PayloadAction<MinList>) {
      if (!state.minimizeItemList.find((itm) => itm._id === action.payload._id)) {
        state.minimizeItemList.push(action.payload);
      }
    },
    removeItemInList(state, action: PayloadAction<string>) {
      state.minimizeItemList = state.minimizeItemList.filter((item) => item._id !== action.payload);
    },
    openItem(state, action: PayloadAction<MinList>) {
      if (!state.openItemList.find((itm) => itm._id === action.payload._id)) {
        state.openItemList.push(action.payload);
      }
    },
    closeItem(state, action: PayloadAction<string>) {
      state.openItemList = state.openItemList.filter((item) => item._id !== action.payload);
    },
  },
});

export const { addItemInList, removeItemInList, openItem, closeItem } = userSlice.actions;

export default userSlice.reducer;
