import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { Button, Card, Flex, Form, Modal } from "antd";
import Typography from "antd/es/typography/Typography";
import { InputBox } from "../../components/FormInput";
import AxiosService from "../../utils/APIService";
import { LocalStorageService } from "../../utils/LocalStorage";
import { UTILS } from "../../utils/const";
import { isLogin, setProfileData, setUserData } from "../../slices/userSlice";
import styleCss from "./style.module.scss";
import { encryptString } from "../../utils/passEncDec";

interface LoginValueInterface {
  email: String;
  password: String;
}

function Login() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const apiService = new AxiosService({
    baseURL: process.env.REACT_APP_AUTH_SERVICE,
  });
  const [isModalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [forgotPasswordForm] = Form.useForm();
  const [loginForm] = Form.useForm();

  const onFinish = (value: LoginValueInterface) => {
    apiService
      .post("/sign-in", {
        email: encryptString(value.email),
        password: encryptString(value.password)
      })
      .then((res: any) => {
        dispatch(isLogin(true));
        dispatch(setUserData(res.user));
        dispatch(setProfileData({ ...res.profile, _id: undefined }));
        LocalStorageService.setItem(UTILS.token, res?.token);
        LocalStorageService.setItem(UTILS.user, res?.user);

        navigate("/inquiry");
        loginForm.resetFields();
        // if(res?.user?.role === ROLE.agentUser){
        // }else if(res?.user?.role === ROLE.agentAdmin){
        //     navigate('/team')
        // }
      })
      .catch(() => {
        dispatch(isLogin(false));
        dispatch(setUserData(null));
        dispatch(setProfileData(null));
      });
  };

  const handleForgotPassword = (value: LoginValueInterface) => {
    setLoading(true);
    apiService
      .post("/forget-password", { email: value.email })
      .then(() => {
        setModalVisible(false);
        forgotPasswordForm.resetFields();
        loginForm.resetFields();
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Flex justify="center" align="center" style={{ height: "100vh" }} className={styleCss.loginPage}>
      <Card
        style={{
          maxWidth: 500,
          width: "100%",
        }}
      >
        <Form form={loginForm} name="Login" onFinish={onFinish} layout="vertical">
          <InputBox name={"email"} label="Email" rules={[{ required: true, message: "Please Enter Email!" }]} />
          <InputBox
            type="password"
            name={"password"}
            label="Password"
            rules={[{ required: true, message: "Please Enter Password!" }]}
          />
          <Typography className="forget" onClick={() => setModalVisible(true)}>
            Forget Password?
          </Typography>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Modal open={isModalVisible} footer={null} onCancel={() => setModalVisible(false)}>
        <Form form={forgotPasswordForm} name="ForgotPassword" onFinish={handleForgotPassword} layout="vertical">
          <InputBox
            name={"email"}
            label="Enter your email"
            rules={[{ required: true, message: "Please enter your email!" }]}
          />
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Send Reset Link
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Flex>
  );
}

export default Login;
