import { Flex, Tag, Typography } from "antd";
import { TweenOneGroup } from "rc-tween-one";
import { UserInvite } from "../../../components/UserInviteModel";
import { AssigneeListParams, IAssignee } from "../../../slices/taskSlice";
import TaskStyleCss from "../createDrawer.module.scss";

interface IAssigne {
  selectedAssignee: IAssignee[];
  assignList: any;
  handleCloseTag: (e: string) => void;
  getAssigneeList: (val: AssigneeListParams) => void;
}

function Assignee({ selectedAssignee, assignList, handleCloseTag, getAssigneeList }: IAssigne) {
  return (
    <div>
      <div className="assigneeField">
        <Flex align="center" gap={30}>
          <Typography.Text className="label">Assignee</Typography.Text>
          <div className={TaskStyleCss.customeAssigneeInput}>
            <TweenOneGroup
              appear={false}
              enter={{ scale: 0.8, opacity: 0, type: "from", duration: 100 }}
              leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
              onEnd={(e) => {
                if (e.type === "appear" || e.type === "enter") {
                  (e.target as any).style = "display: inline-block";
                }
              }}
            >
              <Flex wrap gap={5} align="center">
                {selectedAssignee.map((dt) => (
                  <Tag
                    closable
                    onClose={(e) => {
                      e.preventDefault();
                      handleCloseTag(dt._id);
                    }}
                  >
                    {dt.name}
                  </Tag>
                ))}
                <UserInvite
                  assignList={assignList}
                  assigned={selectedAssignee.map((assignee) => ({
                    _id: assignee._id,
                    full_name: assignee.name,
                  }))}
                  onSubmit={getAssigneeList}
                >
                  <span className="label-tag pl-2">+ Add member</span>
                </UserInvite>
              </Flex>
            </TweenOneGroup>
          </div>
        </Flex>
      </div>
    </div>
  );
}

export default Assignee;
