import React, { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Card, Col, Flex, Input, Popover, Row, Typography } from "antd";
import { CiEdit } from "react-icons/ci";
import FormStyleCss from "./form.module.scss";
import { useDispatch } from "react-redux";
import { setContactPerson } from "../../../slices/vendorSlice";
import { IoMdClose } from "react-icons/io";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";

const ContactPersonPopover: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { contactPerson } = useSelector((state: RootState) => state.vendor);
  const [contactPersons, setContactPersons] = useState<any>([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    if (contactPerson?.length) {
      setContactPersons([...contactPerson]);
    } else {
      setContactPersons([{ name: "", mobile: "", email: "" }]);
    }
  }, [contactPerson]);

  const closePopover = () => {
    setIsPopoverOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setIsPopoverOpen(newOpen);
  };

  const addContactPerson = () => {
    setContactPersons([...contactPersons, { name: "", mobile: "", email: "" }]);
  };

  const removeContactPerson = (index: number) => {
    setContactPersons(contactPersons.filter((_: any, i: any) => i !== index));
  };

  const updateContactPerson = (index: number, key: "name" | "mobile" | "email", value: string) => {
    setContactPersons((prev: any) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [key]: value };
      return updated;
    });
  };

  const handleSave = () => {
    dispatch(setContactPerson(contactPersons));
    closePopover();
  };

  const handleRemoveContact = (contactId: string) => {
    const updatedContacts = contactPersons.filter((person: any) => person.id !== contactId);
    setContactPersons(updatedContacts);
    dispatch(setContactPerson(updatedContacts));
  };

  const handleEditContact = () => {
    setIsPopoverOpen(true);
  };

  const content = (
    <div style={{ display: "flex", flexDirection: "column", rowGap: 6 }}>
      {contactPersons?.map((person: any, index: number) => (
        <div>
          <Flex justify="space-between" align="center" className="header">
            <Typography style={{ color: "black" }}>Contact #{index + 1}</Typography>
            {contactPersons.length > 1 && (
              <IoMdClose onClick={() => removeContactPerson(index)} style={{ cursor: "pointer" }} size={14} />
            )}
          </Flex>
          <Row gutter={[4, 6]} align={"middle"}>
            <Col span={5}>
              <Typography className="title">Name</Typography>
            </Col>
            <Col span={19}>
              <Input
                placeholder="Enter Name"
                value={person?.name || ""}
                onChange={(e) => updateContactPerson(index, "name", e.target.value)}
              />
            </Col>
            <Col span={5}>
              <Typography className="title">Mobile</Typography>
            </Col>
            <Col span={19}>
              <Input
                placeholder="Enter Mobile Number"
                value={person.mobile || ""}
                onChange={(e) => updateContactPerson(index, "mobile", e.target.value)}
              />
            </Col>
            <Col span={5}>
              <Typography className="title">Email</Typography>
            </Col>
            <Col span={19}>
              <Input
                type="email"
                placeholder="Enter Email"
                value={person.email || ""}
                onChange={(e) => updateContactPerson(index, "email", e.target.value)}
              />
            </Col>
          </Row>
        </div>
      ))}
      <Flex justify="space-between" align="center">
        <Button onClick={addContactPerson} type="link" disabled={contactPersons?.length >= 3} className="addMorebtn">
          Add More
        </Button>
        <Button onClick={handleSave} className="savebtn">
          Save
        </Button>
      </Flex>
    </div>
  );

  return (
    <>
      <Popover
        content={content}
        trigger="click"
        onOpenChange={handleOpenChange}
        open={isPopoverOpen}
        placement={"right"}
        overlayStyle={{
          width: "300px",
          maxHeight: "60%",
          boxShadow: "0px 0px 8px #b9b9b9",
          borderRadius: "4px",
        }}
        overlayClassName={FormStyleCss.ContactPersonpopover}
      >
        {!contactPerson?.length && <Button style={{ color: "#30bfee", boxShadow: "none" }}>Add Contact</Button>}
      </Popover>

      <Flex gap={10} className={FormStyleCss.contactPersonCard}>
        {contactPerson?.map((person: any) => {
          return (
            <Card>
              <Flex gap={20}>
                <span>
                  <Typography className="name">{person.name}</Typography>
                  <Typography className="otherDetail">{person.mobile}</Typography>
                  <Typography className="otherDetail">{person.email}</Typography>
                </span>
                <IoMdClose
                  style={{ cursor: "pointer", color: "#ff4d4f", marginTop: "4px" }}
                  size={14}
                  onClick={() => handleRemoveContact(person?.id)}
                />
              </Flex>
            </Card>
          );
        })}
        {/* <CiEdit size={18} color={'#494949'} style={{marginTop:'6px'}}/> */}
        {!!contactPerson?.length && (
          <Typography className="edit" onClick={handleEditContact} style={{ cursor: "pointer" }}>
            Edit
          </Typography>
        )}
      </Flex>
    </>
  );
};

export default ContactPersonPopover;
