//  sideBar
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Flex, Image, Menu, MenuProps, Typography } from "antd";
import { RootState } from "../../../store";
import Sider from "antd/es/layout/Sider";
import logo from "../../../assets/logo.svg";
import dashboardSvg from "../../../assets/dashboard.svg";
import taskSvg from "../../../assets/Task.svg";
import chatBoxSvg from "../../../assets/Chatbox.svg";
import passengerSvg from "../../../assets/passenger.svg";
import upcomingTrpSvg from "../../../assets/uncoming trip.svg";
import account from "../../../assets/account.svg";
import noteSvg from "../../../assets/notes.svg";
import moreSvg from "../../../assets/more.svg";
import SiderCss from "./style.module.scss";

function SideBarLayout() {
  const { sidebarOpen } = useSelector((state: RootState) => state.layout);
  const location = useLocation();
  const [selectedMenuKey, setSelectedMenuKey] = useState<string[]>([]);
  // const [selectedMenuKey, setSelectedMenuKey] = useState(() => {
  //     return JSON.parse(localStorage.getItem("selectedMenuKey") || '["2"]');
  // });

  // useEffect(() => {
  //     localStorage.setItem("selectedMenuKey", JSON.stringify(selectedMenuKey));
  // }, [selectedMenuKey]);

  useEffect(() => {
    const pathname = location.pathname;
    setSelectedMenuKey([pathname]);
  }, [location]);

  const menuList: MenuProps["items"] = [
    {
      key: "1",
      icon: <img src={dashboardSvg} alt="Dashboard" />,
      label: "Dashboard",
    },
    {
      key: "/inquiry",
      icon: <img src={taskSvg} alt="Task" />,
      label: <Link to="/inquiry">Task</Link>,
    },
    {
      key: "3",
      icon: <img src={chatBoxSvg} alt="Chat box" />,
      label: "Chat box",
    },
    {
      key: "/contacts",
      icon: <img src={passengerSvg} alt="Passenger List" />,
      label: <Link to="/contacts">Passenger List</Link>,
    },
    {
      key: "/vendors",
      icon: <img src={passengerSvg} alt="Vendor List" />,
      label: <Link to="/vendors">Vendor List</Link>,
    },
    {
      key: "5",
      icon: <img src={upcomingTrpSvg} alt="Upcoming Trip" />,
      label: "Upcoming Trip",
    },
    {
      key: "6",
      icon: <img src={account} alt="Dashboard" />,
      label: "Account",
      children: [
        { key: "6-1", label: "Invoice" },
        { key: "6-2", label: "Purchase" },
        { key: "6-3", label: "Credit Note" },
        { key: "6-4", label: "Debit Note" },
        { key: "6-5", label: "JV" },
        { key: "6-6", label: "Payment" },
        { key: "6-7", label: "Recepit" },
        { key: "6-8", label: "Expense" },
        { key: "6-9", label: "Bank Account" },
        { key: "6-10", label: "Cash" },
        {
          key: "6-11",
          label: "Reports",
          children: [
            { key: "6-11-1", label: "Balance Sheet" },
            { key: "6-11-2", label: "Profit and loss" },
            { key: "6-11-3", label: "GstR 2A" },
            { key: "6-11-4", label: "GstR 3B" },
            { key: "6-11-5", label: "Creditor" },
            { key: "6-11-6", label: "Debitor" },
            {
              key: "6-11-7",
              label: "Outstanding report",
              children: [
                { key: "6-11-7-1", label: "Outstanding Debitor" },
                { key: "6-11-7-2", label: "Outstanding Creditor" },
              ],
            },
          ],
        },
      ],
    },
    {
      key: "/notes",
      icon: <img src={noteSvg} alt="Notes" />,
      label: <Link to="/notes">Notes</Link>,
    },
    {
      key: "9",
      icon: <img src={moreSvg} alt="More" />,
      label: "More",
    },
  ];

  return (
    <div className={SiderCss.sidebar}>
      <Sider style={{ background: "transparent" }} collapsed={sidebarOpen}>
        <Flex align="center" className={`${sidebarOpen ? "paddingCollapsed" : "paddingExpanded"}`}>
          <Image src={logo} preview={false} />
          <Typography.Text className={`logoText ${sidebarOpen ? "hide" : "show"}`}>ATULAM</Typography.Text>
        </Flex>
        <Menu
          mode="inline"
          defaultSelectedKeys={selectedMenuKey}
          selectedKeys={selectedMenuKey}
          style={{ borderRight: 0, backgroundColor: "transparent" }}
          items={menuList}
          className="sidebarMenu"
          onSelect={function ({ selectedKeys }) {
            setSelectedMenuKey(selectedKeys);
          }}
        />
      </Sider>
    </div>
  );
}

export default SideBarLayout;
