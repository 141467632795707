import { Col, Flex, Row, Typography } from "antd";
import { InputBox, InputSelect } from "../../FormInput";
import styleCss from "./style.module.scss";

interface ICalculatorProps {
  amount: number | null;
  setGstPercentage: React.Dispatch<React.SetStateAction<number>>;
  gstPercentage: number;
  setAmount: React.Dispatch<React.SetStateAction<number | null>>;
}

const GSTCalculator = ({ amount, setGstPercentage, gstPercentage, setAmount }: ICalculatorProps) => {
  const initialDocTypeList = [
    { label: "5%", value: "5", id: "1" },
    { label: "12%", value: "12", id: "2" },
    { label: "18%", value: "18", id: "3" },
    { label: "28%", value: "28", id: "4" },
  ];

  const handleAmountChange = (value: string | number | null) => {
    setAmount(value === "" ? null : parseFloat(String(value)));
  };

  const handleGSTChange = (value: number) => {
    setGstPercentage(value);
  };

  const gstValue = amount ? (amount * gstPercentage) / 100 : 0;
  const totalExclusive = amount ? amount + gstValue : 0;

  const actualAmountInclusive = amount ? amount / (1 + gstPercentage / 100) : 0;
  const gstValueInclusive = amount ? amount - actualAmountInclusive : 0;

  return (
    <>
      <div className={styleCss.calculator}>
        <div className="fields">
          <Row justify="center" gutter={20}>
            <Col span={12}>
              <Typography>Amount</Typography>
              <InputBox
                type="number"
                style={{ width: "100%" }}
                placeholder="Enter Amount"
                value={amount !== null ? amount : ""}
                onChange={(value: number) => handleAmountChange(value)}
              />
            </Col>
            <Col span={12}>
              <Typography>GST%</Typography>
              <InputSelect
                style={{ width: "100%", textAlign: "start" }}
                options={initialDocTypeList}
                defaultValue={[`${gstPercentage}%`]}
                onChangeHandle={handleGSTChange}
              />
            </Col>
          </Row>
        </div>
        <div className="calculation">
          <Flex justify="flex-end" gap={74} style={{ marginTop: "10px" }}>
            <Typography className="title">Actual Amount</Typography>
            <Typography className="title">GST Amount</Typography>
            <Typography className="title">Total Amount</Typography>
          </Flex>
        </div>
        <div className="calculation" style={{ marginTop: "10px" }}>
          <Flex justify="space-between" gap={20} align="center">
            <Typography className="title">Exclusive</Typography>
            <Typography className="amount">{amount ? amount : "0"}</Typography>
            <Typography className="operator">+</Typography>
            <Typography className="amount">{gstValue.toFixed(2)}</Typography>
            <Typography className="operator">=</Typography>
            <Typography className="amount">{totalExclusive.toFixed(2)}</Typography>
          </Flex>
        </div>
        <div className="calculation" style={{ marginTop: "15px" }}>
          <Flex justify="space-between" gap={20} align="center">
            <Typography className="title">Inclusive</Typography>
            <Typography className="amount">{actualAmountInclusive ? actualAmountInclusive.toFixed(2) : "0"}</Typography>
            <Typography className="operator">+</Typography>
            <Typography className="amount"> {gstValueInclusive.toFixed(2)}</Typography>
            <Typography className="operator">=</Typography>
            <Typography className="amount">{amount ? amount : "0.00"}</Typography>
          </Flex>
        </div>
      </div>
    </>
  );
};

export default GSTCalculator;
