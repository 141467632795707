import { useEffect, useState } from "react";
import { Alert, message, Button, Card, Drawer, Flex, Form, Spin, UploadFile, UploadProps, GetProp } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { getBase64 } from "../../../../utils/commonFunctions";
import { DRAWER_TYPE } from "../../../../utils/const";
import { setDrawerOpen } from "../../../../slices/layoutSlice";
import Assignee from "../../CommonComponent/Assignee";
import DueDate from "../../CommonComponent/DueDate";
import CommonForm from "../../CommonComponent/CommonForm";
import CreateDrawerHeader from "../../CommonComponent/CreateDrawerHeader";
import {
  AssigneeListParams,
  createTask,
  IAssignee,
  ICreateTask,
  subTaskDataListInterface,
} from "../../../../slices/taskSlice";
import { TeamMemberInterface } from "../../../../slices/commonDataSlice";
import TaskStyleCss from "../../createDrawer.module.scss";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

function CreateTask() {
  const { taskDrawerOpen } = useSelector((state: RootState) => state.layout);
  const { user } = useSelector((state: RootState) => state.user);
  const { assignList } = useSelector((state: RootState) => state.commonData);

  const dispatch = useDispatch<AppDispatch>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [isTitleEmpty, setIsTitleEmpty] = useState(false);
  const [isPrior, setIsPrior] = useState(false);
  const [taskForm] = Form.useForm();
  const [showUpload, setShowUpload] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [subTaskData, setSubTaskData] = useState<subTaskDataListInterface[]>([
    {
      name: "SubTask List",
      task: [],
    },
  ]);
  const [loadingState, setLoadingState] = useState("");
  const [selectedAssignee, setSelectedAssignee] = useState<IAssignee[]>([]);
  const [selectedContactNumber, setSelectedContactNumber] = useState<string | null>(null);

  useEffect(() => {
    taskForm.setFieldValue("dueDate", dayjs());
  }, [taskForm, user?._id, taskDrawerOpen]);

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const resetForm = () => {
    taskForm.resetFields();
    setFileList([]);
    setSubTaskData([{ name: "SubTask List", task: [] }]);
    setIsTitleEmpty(false);
    setIsPrior(false);
    setShowUpload([]);
    setSelectedAssignee([]);
    setLoadingState("");
    setSelectedContactNumber("");
  };

  const drawerCloseHandle = () => {
    resetForm();
    dispatch(setDrawerOpen(null));
  };

  const handleCreateTask = async (val: ICreateTask) => {
    const formdata = new FormData();
    formdata.append("type", JSON.stringify(val.type));
    formdata.append("title", val.title);
    if (val.clientId) formdata.append("passenger", val.clientId);
    formdata.append("dueDate", val.dueDate.format("DD-MM-YYYY HH:mm"));
    if (val.notes) formdata.append("notes", val.notes);
    formdata.append("is_prior", String(val.is_prior || false));

    if (selectedAssignee?.length > 0) {
      formdata.append("assignTo", JSON.stringify(selectedAssignee.map((asn: IAssignee) => asn._id)));
    }

    if (subTaskData?.length > 0) {
      formdata.append("subtask", JSON.stringify(subTaskData));
    }

    if (fileList?.length > 0) {
      fileList.forEach((file: any, i: number) => {
        formdata.append(`doc_${i + 1}`, file.originFileObj);
      });
    }

    let hasError = false;

    if (!val?.type?.length) {
      message.error("Task type is required");
      hasError = true;
    }

    if (!val?.title) {
      setIsTitleEmpty(true);
      hasError = true;
    }

    if (!hasError) {
      setLoadingState("addTask");

      try {
        const data: any = await dispatch(createTask(formdata));
        if (!data.error) {
          drawerCloseHandle();
        }
      } catch (e) {
        console.error("Error creating task:", e);
      } finally {
        setLoadingState("");
      }
    }
  };

  const handleFormSubmit = () => {
    taskForm
      .validateFields()
      .then((val) => handleCreateTask(val))
      .catch(() => {
        console.log("Form validation failed");
      });
  };

  const getAssigneeList = (val: AssigneeListParams) => {
    const dataList: IAssignee[] = assignList
      .filter((asl: TeamMemberInterface) => val.includes(asl._id))
      .map((asl: TeamMemberInterface) => ({ name: asl.full_name, _id: asl._id }));

    setSelectedAssignee(dataList);
  };

  const handleCloseTag = (id: string) => {
    setSelectedAssignee((prev) => prev.filter((dt: any) => dt._id !== id));
  };

  return (
    <>
      <Drawer
        onClose={drawerCloseHandle}
        open={taskDrawerOpen === DRAWER_TYPE.CREATE_TASK}
        width={"calc(100% - 233px)"}
        className={TaskStyleCss.taskDrawer}
        styles={{
          header: {
            display: "none",
          },
        }}
        footer={[
          <div className="CreateTaskFooter">
            <Flex gap={20}>
              <Button className="addTaskbtn" onClick={() => handleFormSubmit()} loading={loadingState === "addTask"}>
                ADD TASK
              </Button>
              <Button className="cancalbtn" onClick={drawerCloseHandle} disabled={loadingState !== ""}>
                Cancel
              </Button>
            </Flex>
          </div>,
        ]}
        style={{ boxShadow: "0px 0px 5px white" }}
      >
        <div className="close-bar" style={{ top: 18 }}>
          <Flex onClick={drawerCloseHandle} className="close-label" align="center">
            <CloseOutlined style={{ fontSize: "12px" }} />
            <span>TASK</span>
          </Flex>
        </div>
        <Spin spinning={loadingState === "addTask" || loadingState === "addAnotherTask"} className="task-spin">
          <Form
            name="task"
            layout="vertical"
            form={taskForm}
            initialValues={{
              dueDate: dayjs(),
            }}
          >
            {<CreateDrawerHeader form={taskForm} />}
            {isTitleEmpty && (
              <Alert type="error" message="The task name is not specified." banner style={{ marginBottom: "5px" }} />
            )}
            <Card className="createTaskWrapper">
              <CommonForm
                fileList={fileList}
                setFileList={setFileList}
                form={taskForm}
                handleChange={handleChange}
                handlePreview={handlePreview}
                isPrior={isPrior}
                previewImage={previewImage}
                previewOpen={previewOpen}
                setIsPrior={setIsPrior}
                setIsTitleEmpty={setIsTitleEmpty}
                setPreviewImage={setPreviewImage}
                setPreviewOpen={setPreviewOpen}
                setShowUpload={setShowUpload}
                setSelectedContactNumber={setSelectedContactNumber}
                selectedContactNumber={selectedContactNumber}
                showUpload={showUpload}
                setSubTaskData={setSubTaskData}
                subTaskData={subTaskData}
                type="TASK"
              />
              <div className="taskField">
                <DueDate form={taskForm} />
                {user?.role === "AGENT_ADMIN" && (
                  <Assignee
                    assignList={assignList}
                    getAssigneeList={getAssigneeList}
                    handleCloseTag={handleCloseTag}
                    selectedAssignee={selectedAssignee}
                  />
                )}
              </div>
            </Card>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
}

export default CreateTask;
