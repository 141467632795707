import dayjs from "dayjs";
import { INQUIRY_TYPE_STATUS } from "./const";
import _ from "lodash";

export const capitalizeFirstLetter = (string: any) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizeFirstLetterOfWord = (string: any) => {
  if (!string) return "";
  return string.replace(/(?:^|\s)\S/g, (char: any) => char.toUpperCase());
};

export const getInitials = (fullName: string | undefined): string => {
  if (!fullName) return "";
  const names = fullName.split(" ").slice(0, 1);
  return names.map((name) => name.charAt(0).toUpperCase()).join("");
};

export const formateDate = (val: any) => {
  return dayjs(val, "DD-MM-YYYY HH:mm").format("MMM D, YYYY");
};

export const statusClassName = (taskStatus: string) => {
  const { className } = INQUIRY_TYPE_STATUS[taskStatus];
  return className;
};

export const getBase64 = async (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

//  Get uniq notes ids
export const getUniqIdsOnly = (data: any[]) => {
  return _.uniq(_.map(data, "_id"));
};

export const getNoteIds = (notes: any[]) => {
  return _.uniq(_.map(notes, "note._id"));
};

export const calculateDaysRemaining = (dueDate: string) => {
  if (!dueDate) return null;

  const [datePart, timePart] = dueDate.split(" ");
  const [day, month, year] = datePart.split("-");
  const [hours, minutes] = timePart.split(":");
  const due = new Date(Number(year), Number(month) - 1, Number(day), Number(hours), Number(minutes));

  const today = new Date();
  const differenceInTime = due.getTime() - today.getTime();

  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  return differenceInDays;
};

export const calculateWinner = (squares: Array<string | null>): { winner: string | null; line: number[] | null } => {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];
  for (const [a, b, c] of lines) {
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      return { winner: squares[a], line: [a, b, c] };
    }
  }
  return { winner: null, line: null };
};

const minimax = (squares: Array<string | null>, isMaximizing: boolean): { index: number; score: number } => {
  const emptySquares = squares
    .map((value, index) => (value === null ? index : null))
    .filter((index) => index !== null) as number[];

  const winnerInfo = calculateWinner(squares);
  if (winnerInfo.winner === "O") return { index: -1, score: 1 };
  if (winnerInfo.winner === "X") return { index: -1, score: -1 };
  if (emptySquares.length === 0) return { index: -1, score: 0 };

  const moves: { index: number; score: number }[] = [];

  for (const index of emptySquares) {
    const squaresCopy = squares.slice();
    squaresCopy[index] = isMaximizing ? "O" : "X";

    const result = minimax(squaresCopy, !isMaximizing);
    moves.push({ index, score: result.score });

    squaresCopy[index] = null; // Undo move
  }

  if (isMaximizing) {
    return moves.reduce((best, move) => (move.score > best.score ? move : best), { index: -1, score: -Infinity });
  } else {
    return moves.reduce((best, move) => (move.score < best.score ? move : best), { index: -1, score: Infinity });
  }
};

export const getComputerMove = (squares: Array<string | null>, difficulty: string): number => {
  const emptySquares = squares
    .map((value, index) => (value === null ? index : null))
    .filter((index) => index !== null) as number[];

  if (difficulty === "easy") {
    return emptySquares[Math.floor(Math.random() * emptySquares.length)];
  }

  if (difficulty === "medium") {
    for (const move of emptySquares) {
      const squaresCopy = squares.slice();
      squaresCopy[move] = "O";
      if (calculateWinner(squaresCopy).winner === "O") return move;

      squaresCopy[move] = "X";
      if (calculateWinner(squaresCopy).winner === "X") return move;
    }
    return emptySquares[Math.floor(Math.random() * emptySquares.length)];
  }

  if (difficulty === "hard") {
    const bestMove = minimax(squares, true).index;
    return bestMove;
  }

  return emptySquares[Math.floor(Math.random() * emptySquares.length)];
};

export const formatDateTime = (timings: string) => {
  const time = dayjs(timings);
  if (time.isToday()) {
    return time.format("hh:mm A");
  } else {
    return time.format("MMM D");
  }
};
